import React, { useState } from 'react';
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import { deleteRestrictAirport, restrictAirportStatus } from '../../../api/admin-api';

const RestrictAirlineList = ({ list, airlineCode, updates, modalChangers, modalFails }) => {

    const airportCode = list.airportCode;

    // delete button loading
    const [buttonLoading, setButtonLoading] = useState(false);

    // delete Restrict api
    const deleteRestrict = (airportCode) => {
        setButtonLoading(true);
        deleteRestrictAirport(airlineCode, airportCode).then(res => {
            updates();
            modalChangers();
        })
            .catch(err => {
                modalFails();
            })
    }

    // active inactive restrict airports api 
    const [values, setValues] = useState(list.activeStatus);
    const restrictAirportState = () => {
        handleStatusPromoAirlineValues(values);
        handleStatusPromo();
    }
    const handleStatusPromoAirlineValues = (values) => {
        setValues(!values);
        handleStatusPromo();
    }
    const handleStatusPromo = () => {
        restrictAirportStatus(airlineCode, airportCode, !values).then(res => {
            updates();
            modalChangers();
        })
            .catch(error => {
                modalFails();
            })
    }

    return (
        <div className="list mb-3">
            <input name="setdate" className="mr-2" defaultChecked={list.activeStatus} type="checkbox" id="inlineCheckRActive" onClick={() => restrictAirportState(list.airportCode)} />
            {list.airportName + ` (` + (list.airportCode) + `)`}
            <div className="float-right">
                <LaddaButton
                    type="submit"
                    className="btn btn-sm btn-danger"
                    loading={buttonLoading}
                    data-style={SLIDE_UP}
                    onClick={() => deleteRestrict(list.airportCode)}
                >
                    <i className="fa fa-trash-o"
                        aria-hidden="true"></i>
                </LaddaButton>
            </div>
        </div>
    )
}

export default RestrictAirlineList
