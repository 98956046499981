import React from 'react';
import './admin-footer.scss';

const AdminFooter = () => {
    return (
        <footer className="admin-footer text-light bg-info">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-md-right text-center ">
                        <div className="m-1">© {(new Date().getFullYear())} Powered by <a href="https://www.oganro.com/" target="_blank" rel="noopener noreferrer" className="text-light"> Oganro Limited</a>. All Rights Reserved.</div>
                    </div>
                </div>

            </div>
        </footer>
    );
}

export default AdminFooter;