import moment from "moment";
import { SESSIONSTORE } from "./storage";

/**
 * @function formatCurrency
 * @description format curency from given value
 */
const formatCurrency = (
    amount,
    decimalCount = 2,
    decimal = ".",
    thousands = ","
) => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(
            (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
        ).toString();
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
            negativeSign +
            (j ? i.substr(0, j) + thousands : "") +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
            (decimalCount
                ? decimal +
                Math.abs(amount - i)
                    .toFixed(decimalCount)
                    .slice(2)
                : "")
        );
    } catch (e) {
        handleException(e, "Could not format currency");
    }
};

/**
 * @function generateQueryUrl
 * @description genrate query string url param string by given object : use this for search widget
 */
const generateQueryUrl = dataObject => {
    try {
        let urlString = "";
        const getArrayUrlStr = arr => {
            let arrUrlStr = "";
            arr.forEach((item, i) => {
                arrUrlStr += Object.keys(item)
                    .map(key => {
                        return (
                            "travelItineraries_" +
                            i +
                            "_" +
                            key +
                            "=" +
                            item[key]
                        );
                    })
                    .join("&");
                if (i + 1 !== arr.length) {
                    arrUrlStr += "&";
                }
            });
            return arrUrlStr;
        };
        urlString += Object.keys(dataObject)
            .map(valKey => {
                let value = dataObject[valKey];
                if (value instanceof Array) {
                    return getArrayUrlStr(value);
                } else {
                    return valKey + "=" + value;
                }
            })
            .join("&");
        return urlString;
    } catch (error) {
        handleException(error, "invalid search URL");
        return null;
    }
};

/**
 * @function extractQueryUrl
 * @description extract data object from query string url param string : use this for search result page
 */
const extractQueryUrl = urlString => {
    try {
        let dataObj = {};
        urlString.split("&").forEach(valueItem => {
            const key = valueItem.split("=")[0];
            let value;

            if (isNaN(valueItem.split("=")[1])) {
                value = valueItem.split("=")[1];
            } else {
                value = parseInt(valueItem.split("=")[1]);
            }

            if (key.includes("_")) {
                const keySections = key.split("_");
                const arrName = keySections[0];
                const arrIndex = Number(keySections[1]);
                const objectKey = keySections[2];

                if (dataObj.hasOwnProperty(arrName)) {
                    if (dataObj[arrName][arrIndex]) {
                        dataObj[arrName][arrIndex] = {
                            ...dataObj[arrName][arrIndex],
                            [objectKey]: value
                        };
                    } else {
                        dataObj[arrName][arrIndex] = { [objectKey]: value };
                    }
                } else {
                    dataObj[arrName] = [{ [objectKey]: value }];
                }
            } else {
                dataObj[key] = value;
            }
        });

        return dataObj;
    } catch (error) {
        handleException(error, "Could not extract url data");
        return null;
    }
};

/**
 * @function chunkArray
 * @description Splits an array into pieces
 */
const chunkArray = (array, size) => {
    const chunked_arr = [];
    let index = 0;
    while (index < array.length) {
        chunked_arr.push(array.slice(index, size + index));
        index += size;
    }
    return chunked_arr;
};

/**
 * @function timer
 * @description Set a timer
 */
const timer = (duration, ontimerCb, onFinishedCb) => {
    try {
        const durationInSec =
            Number(duration.split(":")[0]) * 60 +
            Number(duration.split(":")[1]);
        let timeDuration = moment.duration(durationInSec, "seconds");
        const clockLoop = setInterval(() => {
            timeDuration = moment.duration(timeDuration - 1000, "milliseconds");
            const timeVal =
                ("0" + timeDuration.minutes()).slice(-2) +
                ":" +
                ("0" + timeDuration.seconds()).slice(-2);

            if (timeVal === "00:00") {
                // timer finished
                clearInterval(clockLoop);
                if (typeof onFinishedCb === "function") {
                    onFinishedCb();
                }
            } else if (typeof ontimerCb === "function") {
                ontimerCb(timeVal);
            }
        }, 1000);
        return clockLoop;
    } catch (error) {
        handleException(error, "Could not initalize timer");
        return null;
    }
};

/**
 * @function handleException
 * @description Handle system exeptions
 * @param error Exception Error Object
 * @param msg Log message string
 */
const handleException = (error, msg = "") => {
    // if (process && process.env && (process.env.DEVELOPMENT)) {
    console.log(`[ERR] : [${msg}] `, error);
    // }
};

/**
 * @function urlExtraction
 * @description Extracting Host domain url from the site (dynamic)
 */
const urlExtractor = () => {
    const getUrl = window.location;
    const baseUrl = getUrl.protocol + "//" + getUrl.host + "/";

    return baseUrl;
};

/**
 * @function removeBookingSession
 * @description Remove booking related session values from session storage
 * @param keepKeysArray Optional [String] keys to ignore and keep without deleting
 */
const removeBookingSession = keepKeysArray => {
    const toRemoveKeys = ['ss','rd','ps','bt','cid','pf','pnrc','cfpd', 'confirmationSent']; // session store keys to be removed

    if (keepKeysArray) {
        toRemoveKeys.filter(rk => !keepKeysArray.includes(rk)).forEach(rk => {
            SESSIONSTORE.delete(rk);
        });
    } else {
        toRemoveKeys.forEach(rk => {
            SESSIONSTORE.delete(rk);
        });
    }
};

export {
    formatCurrency,
    generateQueryUrl,
    extractQueryUrl,
    chunkArray,
    timer,
    handleException,
    urlExtractor,
    removeBookingSession
};
