import React from 'react';
import BookingEngineHeader from '../../../components/booking-engine-header/booking-engine-header';
import BookingEngineFooter from '../../../components/booking-engine-footer/booking-engine-footer';
import BookingEngineView from '../../../components/booking-engine-view';
import { SESSIONSTORE } from '../../../../common/storage';
import * as moment from 'moment';
import ProcessPaymentResponse from '../../components/process-payment-response';
const airlineLogos = require.context('../../../../assets/images/airlines', true);


const BookingSuccess = () => {
    const resultData = SESSIONSTORE.get('rd');

    // ui result detail list
    const uiFlightTours = [];
    if (resultData) {
        for (const tripKey in resultData.flightSliceMap) {
            if (resultData.flightSliceMap.hasOwnProperty(tripKey)) {
                // const flightsInTrip = resultData.flightSliceMap[tripKey].flightItineraries;
                uiFlightTours.push(
                    <FlightTour
                        key={tripKey}
                        index={Number(tripKey)}
                        tourData={resultData.flightSliceMap[tripKey]}
                        baggageData={resultData.baggage}
                    />
                );
            }
        }
    }

    return (
        <>
            <BookingEngineHeader />
            <BookingEngineView>
                <div className='pt-5'><ProcessPaymentResponse/></div> 
                {
                    uiFlightTours.length > 0 && <div className="container my-5">
                        {uiFlightTours}
                    </div>
                }
            </BookingEngineView>
            <BookingEngineFooter footerStyle="compact" />
        </>
    );
}

const FlightTour = ({ index, tourData, baggageData }) => {
    const flightsInTrip = tourData.flightItineraries;
    const startingFlight = flightsInTrip[0];
    const lastFlight = [...flightsInTrip].pop();
    const tripDuration = tourData.sliceDurationTxt;

    return (
        <div className="card mb-2">
            <div className="card-header">
                <div className="row">
                    <div className="col-12">
                        <span className="font-weight-bold mr-2 text-primary">
                            {index % 2 !== 0 ? 'Outbound' : 'Inbound'}
                        </span>
                        <span className="small"><span className="font-weight-bold mr-2">{startingFlight.boardPoint} - {lastFlight.offPoint}</span>  {tripDuration}</span>
                    </div>
                </div>

            </div>
            <div className="card-body">

                <div className="container">

                    {
                        flightsInTrip.map((tripItem, index) => {
                            let airlineLogo;
                            try {
                                airlineLogo = airlineLogos(`./${tripItem.operatingCarrier}.png`);
                            } catch (error) {
                                airlineLogo = airlineLogos(`./default.png`);
                            }

                            return (
                                <div className={'container py-2 mb-2 ' + ((index + 1) !== flightsInTrip.length ? 'border-bottom' : '')} key={index}>
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <img className="w-25 d-none d-sm-inline-block" src={airlineLogo} alt="airline" />
                                            <span className="small font-weight-bold">{tripItem.operatingCarrierName}</span>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="text-primary">{tripItem.boardPoint}</div>
                                            <div className="small text-muted">{tripItem.boardPointTerminal}, {tripItem.boardPointName}</div>
                                            <div>
                                                <span className="mr-2">{moment(tripItem.datetimeOfDeparture).format('DD MMM YYYY')}</span>
                                                <span>{moment(tripItem.datetimeOfDeparture).format('LT')}</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 text-center">
                                            <div>{tripItem.sliceDurationTxt ? tripItem.sliceDurationTxt : null}</div>
                                            <div className="small text-muted">
                                                <i className="fa fa-chevron-right"></i>
                                                <br />
                                                <i className="fa fa-suitcase mr-1"></i> {baggageData.freeAllowance} {baggageData.unit} per person
                                            </div>
                                        </div>
                                        <div className="col-lg-3 text-lg-right">
                                            <div className="text-primary">{tripItem.offPoint}</div>
                                            <div className="small text-muted">{tripItem.offPointTerminal}, {tripItem.offPointName}</div>
                                            <div>
                                                <span className="mr-2">{moment(tripItem.datetimeOfArrival).format('DD MMM YYYY')}</span>
                                                <span>{moment(tripItem.datetimeOfArrival).format('LT')}</span>
                                            </div>
                                        </div>
                                    </div>

                                    {tripItem.technicalStop ? technicalStopsUi(tripItem.technicalStop) : null}

                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

const technicalStopsUi = technicalStopData => {
    return (
        <div className="row my-2">
            <div className="col-lg-11 col-12 offset-lg-1">
                <div className="small list-group-item flex-column align-items-start">
                    <div className="d-flex w-100 justify-content-between">
                        <h6 className="mb-1">Stops</h6>
                        <small className="text-muted">{technicalStopData.techStopNumber} stop(s)</small>
                    </div>
                    <div>
                        <ul className="list-group list-group-flush">
                            {
                                technicalStopData.stopDetails.map(stop => {
                                    const landingDate = moment(stop.datetimeOfLanding).format('YYYY-MM-DD');
                                    const takeOffDate = moment(stop.datetimeOfTakeOff).format('YYYY-MM-DD');
                                    const landingTime = moment(stop.datetimeOfLanding).format('HH:mm');
                                    const takeOffTime = moment(stop.datetimeOfTakeOff).format('HH:mm');
                                    return (
                                        <li className="list-group-item py-1 p-0 text-muted">
                                            Landing at
                                            <strong> {stop.stopLocationCode} ({stop.stopLocationName}) </strong> on
                                            <strong> {landingDate} {landingTime}h </strong> for
                                            <strong> {stop.stopDurationTxt} </strong>, And take off at
                                            <strong> {takeOffDate} {takeOffTime}h </strong>.
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default BookingSuccess;
