import React, { Fragment } from 'react';
import { Button, Modal, Form, Row, Col, Spinner } from 'react-bootstrap';
import './menu-delete-model.scss';
import { menuDelete } from '../../../api/cms.api';
const MenuDeleteModal = ({ data, show, onHide, refresh }) => {

    const [showResults, setShowResults] = React.useState(false);

    const onDelete = () => {
        setShowResults(true);
        const MenuDelete = {
            id: data.id
        }
        menuDelete(MenuDelete).then(() => {
            onHide();
            refresh();
            setShowResults(false);
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <Fragment>
            {
                <Modal size="sm" show={show} onHide={() => onHide()} centered>
                    <Modal.Header closeButton>
                        <Modal.Title >
                            <span>Menu Delete</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <Form>
                            <span>Are you sure want to delete it.?</span>
                            {showResults ?
                                <Form.Group as={Row}>
                                    <Col sm={6}>
                                    </Col>
                                    <Col sm={6}>
                                        <Button variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                variant="warning"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                animation="grow" />
                                            Loading...
                                        </Button>
                                    </Col>
                                </Form.Group>
                                :
                                <Form.Group as={Row} className="mt-3">
                                    <Col sm={5}>
                                    </Col>
                                    <Col sm={3}>
                                        <Button onClick={() => onHide()} className="btn-default">Cancel</Button>
                                    </Col>
                                    <Col sm={4}>
                                        <Button className='btn-danger cus-margin' onClick={() => onDelete()}>Delete</Button>
                                    </Col>
                                </Form.Group>
                            }

                        </Form>
                    </Modal.Body>
                </Modal>
            }
        </Fragment>
    );
}

export default MenuDeleteModal;