import React, { useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { updateFareType } from '../../../api/admin-api';

const ModalFareType = ({ setShow, handleClose, airlineName, category, categoryDisplay, airlineCode, data, updates, modalChangers, modalFails, hideMarkup }) => {

    const formRef = useRef();
    const handleSubmit = () => {
        let formdata = formRef.current.values;

        // setValidated(true);
        if (formdata) {
            if (!formdata.setdate) {
                if (formdata.discount !== undefined && (formdata.markup === undefined || formdata.markup === 0)) {
                    handleUpdateFareType(false, null, null, formdata.discount, 0, 0, 0);
                } else if ((formdata.discount === undefined || formdata.discount === 0) && formdata.markup !== undefined) {
                    handleUpdateFareType(false, null, null, 0, 0, formdata.markup, 0);
                }
            } else if (formdata.setdate) {
                if (formdata.dateFrom && formdata.dateTo) {
                    handleUpdateFareType(true, moment(formdata.dateFrom).format('YYYY-MM-DD'), moment(formdata.dateTo).format('YYYY-MM-DD'), formdata.discount, formdata.discountWhenPeriod, formdata.markup, formdata.markupWhenPeriod)
                }
            }
        }

        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    };

    const handleUpdateFareType = (setdate, dateFrom, dateTo, discount, discountWhenPeriod, markup, markupWhenPeriod) => {
        updateFareType(airlineCode, category, setdate, dateFrom, dateTo, discount, discountWhenPeriod, markup, markupWhenPeriod)
            .then(res => {
                updates();
                modalChangers();
            })
            .catch(err => {
                modalFails();
            })
    }

    const schema = Yup.object().shape({
        setdate: Yup.boolean(),
        discount: Yup.number()
            // .moreThan(0)
            .min(0)
            // .positive('should be positive')
            .lessThan(99, 'should be less than 99')
            .test('custom-val', 'Required', function (value) {
                const formValues = this.parent;
                if (!formValues.setdate) {
                    if (formValues.markup === undefined && value === undefined) {
                        return false
                    }
                }
                // if false then message
                return true;
            }),
        markup: Yup.number()
            // .moreThan(0)
            .min(0)
            // .positive('should be positive')
            .lessThan(99, 'should be less than 99')
            .test('custom-val', 'Required', function (value) {
                const formValues = this.parent;
                if (!formValues.setdate) {
                    if (formValues.discount === undefined && value === undefined) {
                        return false
                    }
                }
                // if false then message
                return true;
            }),
        dateFrom: Yup.date().nullable().default(null)
            .test('custom-val', 'Required', function (value) {
                const formValues = this.parent;
                if (formValues.setdate) {
                    if (!value) {
                        return false
                    }
                }
                // if false then message
                return true;
            }),
        dateTo: Yup.date().nullable().default(null)
            .test('custom-val', 'Required', function (value) {
                const formValues = this.parent;
                if (formValues.setdate) {
                    if (!value) {
                        return false
                    }
                }
                // if false then message
                return true;
            }),
        discountWhenPeriod: Yup.number()
            // .moreThan(0)
            .min(0)
            // .positive('should be positive')
            .lessThan(99, 'should be less than 99')
            .test('custom-val', 'Required', function (value) {
                const formValues = this.parent;
                if (formValues.setdate) {
                    if (!formValues.markupWhenPeriod && !value && !formValues.markup) {
                        return false
                    }
                }
                // if false then message
                return true;
            }),
        markupWhenPeriod: Yup.number()
            // .moreThan(0)
            .min(0)
            // .positive('should be positive')
            .lessThan(99, 'should be less than 99')
            .test('custom-val', 'Required', function (value) {
                const formValues = this.parent;
                if (formValues.setdate) {
                    if (!formValues.discountWhenPeriod && !value && !formValues.discount) {
                        return false
                    }
                }
                // if false then message
                return true;
            }),
    });

    return (
        <div>
            <Modal show={setShow} onHide={handleClose} >
                <div className="text-center mt-2 ">
                    <Modal.Title >{airlineName}</Modal.Title>
                </div>
                <Modal.Body>
                    <h4 className="text-center">{categoryDisplay}</h4>
                    <h5>General Discount {hideMarkup ? null : '/ Mark-Up'} </h5>

                    <Formik
                        innerRef={formRef}
                        enableReinitialize
                        initialValues={data.dateFrom ? {
                            discount: data.discount,
                            markup: data.markup,
                            discountWhenPeriod: data.discountPeriod,
                            markupWhenPeriod: data.markupPeriod,
                            dateFrom: (new Date(data.dateFrom)),
                            dateTo: (new Date(data.dateTo)),
                            setdate: data.activeStatus
                        } : {
                                discount: data.discount,
                                markup: data.markup,
                                discountWhenPeriod: data.discountPeriod,
                                markupWhenPeriod: data.markupPeriod,
                                dateFrom: null,
                                dateTo: null,
                                setdate: data.activeStatus
                            }}
                        validationSchema={schema}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                        }}
                    >
                        {({ errors, values, touched, isValidating, setFieldValue, setFieldTouched, handleChange }) => (
                            <Form  >
                                <div className="form-group row">
                                    <label htmlFor="discount" className="col-sm-4 col-form-label">Discount (%)</label>
                                    <div className="col-sm-8">
                                        <Field onChange={e => {
                                            handleChange(e)
                                            if (e.target.value > 0) {
                                                setFieldValue('markup', 0);
                                            }
                                        }} className={"form-control " + (errors.discount && touched.discount ? 'is-invalid' : '')} name="discount" type="number" placeholder="Enter Discount" maxLength={3} />
                                        {errors.discount && touched.discount ? (
                                            <small className="text-danger font-italic">{errors.discount}</small>
                                        ) : null}
                                    </div>
                                </div>
                                {
                                    hideMarkup ?
                                        null
                                        :
                                        <div className="form-group row">
                                            <label htmlFor="markup" className="col-sm-4 col-form-label">Mark-Up (%)</label>
                                            <div className="col-sm-8">
                                                <Field onChange={e => {
                                                    handleChange(e)
                                                    if (e.target.value > 0) {
                                                        setFieldValue('discount', 0);
                                                    }
                                                }} className={"form-control " + (errors.markup && touched.markup ? 'is-invalid' : '')} name="markup" type="number" placeholder="Enter Mark-Up" maxLength={3} />
                                                {errors.markup && touched.markup ?
                                                    (<small className="text-danger font-italic">{errors.markup}</small>)
                                                    : null}
                                            </div>
                                        </div>
                                }

                                <div className="row m-2">
                                    <div className="d-inline-block">
                                        <Field type="checkbox" name="setdate" checked={values.setdate} />
                                    </div>
                                    <div className="d-inline-block">
                                        <p className="ml-1">
                                            Discount {hideMarkup ? null : '/ Mark-Up'} for specified date period
                                        </p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="dateFrom" className="col-sm-4 col-form-label">From</label>
                                    <div className="col-sm-8">
                                        <DatePicker
                                            name="dateFrom"
                                            className={"form-control " + (errors.dateFrom && touched.dateFrom ? 'is-invalid' : '')}
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select Date"
                                            disabled={!values.setdate}
                                            minDate={new Date()}
                                            selected={values.dateFrom}
                                            onChange={date => setFieldValue('dateFrom', date)}
                                        />
                                        <i className="text-primary fa fa-calendar-o ml-2"></i>
                                        {errors.dateFrom && touched.dateFrom ? (
                                            <small className="text-danger font-italic">{errors.dateFrom}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="dateTo" className="col-sm-4 col-form-label">To</label>
                                    <div className="col-sm-8">
                                        <DatePicker
                                            name="dateTo"
                                            className={"form-control " + (errors.dateTo && touched.dateTo ? 'is-invalid' : '')}
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select Date"
                                            disabled={!values.setdate}
                                            minDate={new Date(values.dateFrom)}
                                            selected={values.dateTo}
                                            onChange={date => setFieldValue('dateTo', date)}
                                        />
                                        <i className="text-primary fa fa-calendar-o ml-2"></i>
                                        {errors.dateTo && touched.dateTo ? (
                                            <small className="text-danger font-italic">{errors.dateTo}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="discountWhenPeriod" className="col-sm-4 col-form-label">Discounts (%)</label>
                                    <div className="col-sm-8">
                                        <Field disabled={!values.setdate}
                                            onChange={e => {
                                                handleChange(e)
                                                if (e.target.value > 0) {
                                                    setFieldValue('markupWhenPeriod', 0);
                                                }
                                            }} className={"form-control " + (errors.discountWhenPeriod && touched.discountWhenPeriod ? 'is-invalid' : '')} name="discountWhenPeriod" type="number" placeholder="Enter Mark-Up" maxLength={3} />
                                        {errors.discountWhenPeriod && touched.discountWhenPeriod && <small className="text-danger font-italic">{errors.discountWhenPeriod}</small>}
                                    </div>
                                </div>

                                {
                                    hideMarkup ?
                                        null
                                        :
                                        <div className="form-group row">
                                            <label htmlFor="markupWhenPeriod" className="col-sm-4 col-form-label">Mark-Up (%) </label>
                                            <div className="col-sm-8">
                                                <Field disabled={!values.setdate} onChange={e => {
                                                    handleChange(e)
                                                    if (e.target.value > 0) {
                                                        setFieldValue('discountWhenPeriod', 0);
                                                    }
                                                }} className={"form-control " + (errors.markupWhenPeriod && touched.markupWhenPeriod ? 'is-invalid' : '')} name="markupWhenPeriod" type="number" placeholder="Enter Mark-Up" maxLength={3} />
                                                {errors.markupWhenPeriod && touched.markupWhenPeriod && <small className="text-danger font-italic">{errors.markupWhenPeriod}</small>}
                                            </div>
                                        </div>
                                }
                            </Form>
                        )}
                    </Formik>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                    <Button variant="primary" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default ModalFareType;
