import { api } from '../../api/api';

//menu create
export const menuCreate = (data) => {
    return api.post(`/backend/cms/menuitem`, data);
}

//menu update
export const menuDelete = (data) => {
    return api.delete(`/backend/cms/menuitem`, { data: data });
}

//menu get all
export const menuGet = () => {
    return api.get(`/backend/cms/menuitem/list`);
}

//section create
export const sectionCreate = (data) => {
    return api.post(`/backend/cms/travelpackage`, data);
}

//section get
export const sectionGet = () => {
    return api.get(`/backend/cms/travelpackage/list`);
}

//section delete
export const sectionDelete = (data) => {
    return api.delete(`/backend/cms/travelpackage/${data.id}`,);
}
