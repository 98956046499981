import React, { Fragment } from 'react';
import SectionTable from '../sections/section-table/section-table';
const PackageSections = () => {

    return (
        <Fragment>
            <SectionTable />
        </Fragment>
    );
}

export default PackageSections;