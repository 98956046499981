import { APP_AUTHENTICATING } from "../actions/app.actions";

const appState = {
    authenticating: false
};


export default function appReducer(state = appState, action) {
    switch (action.type) {
        case APP_AUTHENTICATING:
            return {
                ...state,
                authenticating: action.payload
            }
        default:
            return state;
    }
}