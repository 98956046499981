import React from "react";
import FlightSearchWidget from "../../flight-booking/components/flight-search-widget/flight-search-widget";
import { generateQueryUrl, urlExtractor } from "../../../common/util";
import "./flight-search.scss";

const FlightSearch = props => {
    const handleSearchSubmit = searchQuearyObject => {
        const url = generateQueryUrl(searchQuearyObject);
        const path = urlExtractor();
        window.open(`${path}booking-engine/flight/search?${url}`, "_blank");
    };
    return (
        <div>
            <FlightSearchWidget searchSubmit={handleSearchSubmit} />
        </div>
    );
};

export default FlightSearch;
