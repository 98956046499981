import * as awsCognito from '../../aws-cognito/api/aws-cognito.api';
import { LOCALSTORE } from '../../common/storage';
import { awsStorageKeyAccessToken } from '../../aws-cognito/aws-config';
import { getAccessToken } from '../../aws-cognito/aws-functions';

export const APP_AUTHENTICATING = 'APP_AUTHENTICATING';

export const changeAppAuthenticatingStatusAction = status => {
    return {
        type: APP_AUTHENTICATING,
        payload: status
    }
}

export const appAuthenticateAction = () => {
    return (dispatch) => {
        const accessToken = getAccessToken();
        if (!accessToken) {
            dispatch(changeAppAuthenticatingStatusAction(true));
            awsCognito.authenticateApp()
                .then((res) => {
                    // store token data
                    LOCALSTORE.save(awsStorageKeyAccessToken, res.data.access_token);
                    dispatch(changeAppAuthenticatingStatusAction(false));
                })
                .catch(() => {
                    dispatch(changeAppAuthenticatingStatusAction(false));
                });
        }
    };
}
