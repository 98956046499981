import React from 'react';
import { timer } from '../../../../common/util';
import { SESSIONSTORE } from '../../../../common/storage';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

class BookingTimer extends React.Component {

    timeInStorage = SESSIONSTORE.get('bt');
    searchQueryUrl = SESSIONSTORE.get('squ');
    timer = null;

    constructor(props) {
        super(props);
        this.state = {
            time: this.timeInStorage ? this.timeInStorage : null,
            showTimeOut: this.timeInStorage ? false : true
        }
    }

    componentDidMount = () => {
        // init timer
        if (this.state.time) {
            this.timer = timer(this.state.time, latestTime => {
                // updating time
                SESSIONSTORE.save('bt', latestTime);
                this.setState({ time: latestTime })
            }, () => {
                // when timer finished
                SESSIONSTORE.delete('bt');
                this.setState({ showTimeOut: true });
            });
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    searchAgainHandler = () => {
        this.props.history.push({
            pathname: '/booking-engine/flight/search',
            search: '?' + this.searchQueryUrl
        });
    }

    render() {
        return (
            <>
                {
                    this.state.time === '00:01' || !this.state.time ?
                        <h4 className="font-weight-bold text-danger text-center">SESSION TIME OUT</h4> :
                        <div className="card mb-3">
                            <div className="card-body text-center booking-timer">
                                <span className="h2 mr-2 text-primary font-weight-bold timeleft">{this.state.time}</span>
                                <span className="h5 text-muted font-weight-bold timelefttext">mins left</span>
                            </div>
                        </div>
                }

                <Modal show={this.state.showTimeOut} backdrop="static" backdropClassName="dark-backdrop">
                    <Modal.Body>
                        <div className="container text-center p-3">
                            <h3 className="text-primary font-weight-bold">Session Expired</h3>
                            <i className="fa fa-times-circle-o text-muted fa-5x my-3" aria-hidden="true"></i>
                            <p className="text-muted mb-3">
                                Your booking session has expired. Please search again to make sure what you see is available.
                            </p>
                            {this.searchQueryUrl ? <button className="btn btn-primary px-4" onClick={this.searchAgainHandler}>Search Again</button> : null}
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default withRouter(BookingTimer);