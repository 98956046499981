import React from 'react';
import logoImg from '../../../../assets/images/logo.png';
import ProcessPaymentResponse from '../../components/process-payment-response';

const BookingSuccessMobileApp = () => {

    return <div className="align-items-center d-flex h-100 justify-content-center p-4 position-fixed w-100">
        <div className="card">
            <div className="card-body text-center">
                <img src={logoImg} className="w-75 mb-1" alt="logo" />
                <ProcessPaymentResponse/>
            </div>
        </div>
    </div>
}

export default BookingSuccessMobileApp;
