import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import DataTable from 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';
import AgentDetailModal from '../agent-detail-modal/agent-detail-modal';

$.DataTable = DataTable;

const PendingAgentTable = ({ data, update, modalAgentDataChanged, modalAgentDeactivated, modalAgentActivated, modalAgentGrant, modalAgentRevoke, modalAgentFailed }) => {

    useEffect(() => {
        $('#pendingAgents').DataTable({
            responsive: true,
            scrollX: true,
            retrieve: true,
            columnDefs: [
                {
                    targets: [4],
                    searchable: false,
                    orderable: false
                }
            ],
        });

        // eslint-disable-next-line
    }, [])

    // modal show state
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [agentIndex, setAgentIndex] = useState(null);

    const showDetailModal = (index) => {
        setShow(true);
        setAgentIndex(index)
    }

    return (
        <div>
            <table id="pendingAgents" className="table display table-striped table-bordered w-100" >
                <thead>
                    <tr>
                        <th>Company Name</th>
                        <th>User Email</th>
                        <th>Address</th>
                        <th>Country</th>
                        <th>Telephone</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((agent, index) => (
                            <tr key={agent.b2bUID}>
                                <td>{agent.b2buCompanyName}</td>
                                <td>{agent.cognitoUserAttributes ? agent.cognitoUserAttributes.userAttributesMap.email : null}</td>
                                <td>{agent.b2buAddress}</td>
                                <td>{agent.b2buCountryName}</td>
                                <td>{agent.b2buTelephone}</td>
                                <td className="text-center">
                                    {
                                    agent.cognitoUserAttributes ? 
                                    <button type="button" onClick={() => showDetailModal(index)} className="btn btn-primary">Review </button> : null
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <th>Company Name</th>
                        <th>User Email</th>
                        <th>Address</th>
                        <th>Country</th>
                        <th>Telephone</th>
                        <th>Action</th>
                    </tr>
                </tfoot>
            </table>
            {
                show ?
                    <AgentDetailModal
                        show={show}
                        data={data[agentIndex]}
                        handleClose={handleClose}
                        dataFrom='pending'
                        update={update}
                        modalAgentDataChanged={modalAgentDataChanged}
                        modalAgentDeactivated={modalAgentDeactivated}
                        modalAgentActivated={modalAgentActivated}
                        modalAgentGrant={modalAgentGrant}
                        modalAgentRevoke={modalAgentRevoke}
                        modalAgentFailed={modalAgentFailed}
                    />
                    :
                    null
            }
        </div>
    )
}

export default PendingAgentTable
