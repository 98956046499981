import React from 'react';
import AdminFooter from '../../components/admin-footer/admin-footer';
import AdminHeader from '../../components/admin-header/admin-header';
import { NavLink } from 'react-router-dom';
import { getCurrentUser } from '../../../aws-cognito/aws-functions';
import moment from 'moment';
import './dashboard.scss';

const Dashboard = () => {
    const user = getCurrentUser();

    // time extractor
    let time = moment().get('hours');
    if (time > 0 && time < 12) {
        time = 'Morning';
    } else if (time >= 12 && time < 15) {
        time = 'Afternoon';
    } else if (time >= 15) {
        time = 'Evening';
    }

    return (
        <div>
            <AdminHeader />
            <div className="admin-home-banner" id="admin-dashboard">
                <div className="container" >
                    <div>
                        <div className="text-center pt-3 pt-lg-5">
                            <h3 className="display-5">Welcome to Buddha Travel Booking Engine Admin Panel</h3>
                            {/* <img src={logoImg} alt="" className="img-fluid" /> */}
                            <h3 className="display-4 head-color "> {typeof time === 'string' ? `Good ${time}` : 'Welcome'} {user.name}</h3>
                        </div>
                    </div>

                    <div className="col-12 p-0">
                        <div className="myheight text-center d-flex flex-column justify-content-center">
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-4 col-sm-12 hoverEffect " >
                                    <NavLink to="/admin/faremanage" className="nav-link text-light shadow p-3 mb-5 bg-white rounded">
                                        <h3 className="text-info">
                                            <i className="fa fa-paper-plane-o fa-4x mb-2" aria-hidden="true"></i><br />
                                            Fare Manage
                                        </h3>
                                    </NavLink>
                                </div>
                                <div className="col-md-4 col-sm-12 hoverEffect" >
                                    <NavLink to="/admin/bookingreport" className="nav-link text-light shadow p-3 mb-5 bg-white rounded">
                                        <h3 className="text-info">
                                            <i className="fa fa-book fa-4x mb-2" aria-hidden="true"></i> <br />
                                            Booking Report
                                        </h3>
                                    </NavLink>
                                </div>
                                <div className="col-md-4 col-sm-12 hoverEffect" >
                                    <NavLink to="/admin/agents" className="nav-link text-light shadow p-3 mb-5 bg-white rounded">
                                        <h3 className="text-info">
                                            <i className="fa fa-users fa-4x mb-2" aria-hidden="true"></i> <br />
                                            Agents
                                        </h3>
                                    </NavLink>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-left">
                                            <h3>Mobile App Settings</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-4 col-sm-12 hoverEffect " >
                                    <NavLink to="/admin/menu-items" className="nav-link text-light shadow p-3 mb-5 bg-white rounded">
                                        <h3 className="text-info">
                                            <i className="fa fa-bars fa-4x mb-2" aria-hidden="true"></i><br />
                                            Menu Items
                                        </h3>
                                    </NavLink>
                                </div>
                                <div className="col-md-4 col-sm-12 hoverEffect" >
                                    <NavLink to="/admin/sections" className="nav-link text-light shadow p-3 mb-5 bg-white rounded">
                                        <h3 className="text-info">
                                            <i className="fa fa-puzzle-piece fa-4x mb-2" aria-hidden="true"></i> <br />
                                            Sections
                                        </h3>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AdminFooter />
        </div >
    );
};

export default Dashboard;
