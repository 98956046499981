import { api } from '../../api/api';

// airline list search
export const airlineSearch = (code) => {
    return api.get(`/list/airline`, {
        params: {
            searchType: 'TERM',
            term: code
        }
    });
}

/** 
 * airport list search
*/
export const airportSearch = term => {
    return api.get('/list/airport', {
        params: {
            searchType: 'TERM',
            term
        }
    });
}

// add to fare manage list (airline)
export const fareManageAddList = (airlineCode) => {
    const body = [{
        activeStatus: true,
        restrictAllAirport: false,
        airlineCode: airlineCode,
        intFareDetails: null,
        domFareDetails: null,
        secFareDetails: null,
        rvsFareDetails: null,
        yqrFareDetails: null
    }];

    return api.post('/backend/faremanage/addlist', body);
}

// get fare manage list (airline list)
export const fareManageList = () => {
    return api.get('/backend/faremanage/list');
}

/**
 * 
 * @param {airline code} airlineCode 
 * @param {airline code category. eg: International} category 
 * @param {setdate boolean, check wheather the discount or periodic values are depends on duration} activeStatus 
 * @param {periodic start date} dateFrom 
 * @param {periodic end date} dateTo 
 * @param {discount amount} discount 
 * @param {discount amount when periodic in active} discountPeriod 
 * @param {markup amount} markup 
 * @param {markup amount when periodic in active} markupPeriod 
 */
export const updateFareType = (airlineCode, category, activeStatus, dateFrom, dateTo, discount, discountPeriod, markup, markupPeriod) => {
    const body = {
        airlineCode: airlineCode,
        [category]: {
            activeStatus,
            dateFrom,
            dateTo,
            discount,
            discountPeriod,
            markup,
            markupPeriod
        }
    };

    return api.patch('/backend/faremanage/faretype', body)
}

// validate entered airline
export const validateAirline = (airlineCode) => {
    return api.get(`/backend/faremanage/validate/airline/${airlineCode}`)
}

// delete airline
export const deleteAirline = (airlineCode) => {
    return api.delete(`/backend/faremanage/delete/airline/${airlineCode}`);
}

// active inactive airline (status)
export const airlineStatus = (airlineCode, status) => {
    return api.post(`/backend/faremanage/${airlineCode}/active/${status}`)
}

// add promotional airport list
export const promoAirport = (airlineCode, activeStatus, dateFrom, dateTo, airportCode) => {
    const body = {
        airlineCode: airlineCode,
        fareManagePushAirportDTOs: [
            {
                activeStatus: activeStatus,
                datePushFrom: dateFrom,
                datePushTo: dateTo,
                airportCode: airportCode
            }
        ]
    }
    return api.patch('/backend/faremanage/push-airport/addlist', body);
}

// validate promotional airport list
export const validatePromoAirport = (airlineCode, airportCode) => {
    return api.get(`/backend/faremanage/validate/${airlineCode}/push-airport/${airportCode}`);
}

// delete promotional airport list
export const deletePromoAirport = (airlineCode, airportCode) => {
    return api.delete(`/backend/faremanage/delete/${airlineCode}/push-airport/${airportCode}`);
}

// active inactive (status) of airport promo
export const promoAirportStatus = (airlineCode, airportCode, status) => {
    return api.post(`/backend/faremanage/${airlineCode}/push-airport/${airportCode}/active/${status}`);
}

// add restrict airport list
export const restrictAirport = (airlineCode, airportCode, status) => {
    const body = {
        airlineCode: airlineCode,
        fareManageRestrictAirportDTOs: [
            {
                activeStatus: status,
                airportCode: airportCode
            }
        ]
    }
    return api.patch('/backend/faremanage/restrict-airport/addlist', body);
}

// validate restricted airport list
export const validateRestrictAirport = (airlineCode, airportCode) => {
    return api.get(`/backend/faremanage/validate/${airlineCode}/restrict-airport/${airportCode}`);
}

// delete restrict airport list
export const deleteRestrictAirport = (airlineCode, airportCode) => {
    return api.delete(`/backend/faremanage/delete/${airlineCode}/restrict-airport/${airportCode}`);
}

// active inactive (status) of airport restrict
export const restrictAirportStatus = (airlineCode, airportCode, status) => {
    return api.post(`/backend/faremanage/${airlineCode}/restrict-airport/${airportCode}/active/${status}`);
}

// restrict all airport at once
export const restrcitAllAirport = (airlineCode, status) => {
    return api.post(`/backend/faremanage/${airlineCode}/restrict-all-airport/${status}`);
}

// get all agents
export const getAllAgents = () => {
    return api.post(`/backend/b2b/userlist`, {});
}

// get all attributes of the agents
export const getAgentAttributes = () => {
    return api.post(`/backend/b2b/getuserattributes`);
}

// update agent details by the administrator
export const updateAgentDetails = (data) => {
    return api.patch(`/backend/b2b/adminupdateb2buser`, data);
}

// disable agent 
export const disableAgent = (b2bUID, userIdentityServiceId) => {
    return api.post(`/backend/b2b/admindisableuser`, {
        b2bUID,
        userIdentityServiceId
    });
}

// enable agent 
export const enableAgent = (b2bUID, userIdentityServiceId) => {
    return api.post(`/backend/b2b/adminenableuser`, {
        b2bUID,
        userIdentityServiceId
    });
}

// activate agent 
export const activateAgent = (b2bUID, userIdentityServiceId) => {
    return api.post(`/backend/b2b/adminaddusertogroup`, {
        b2bUID,
        userIdentityServiceId,
        b2buCompanyTelephone : '0719639259' // temporary hardcoded must be removed when backend updated
    });
}

// deactivate agent 
export const deactivateAgent = (b2bUID, userIdentityServiceId) => {
    return api.post(`backend/b2b/adminremoveuserfromgroup`, {
        b2bUID,
        userIdentityServiceId
    });
}

// booking report
export const bookingReport = (data) => {
    return api.post(`backend/report/admin`, data);
}

// send email when successfuly grant as agent
export const agentGrantEmailSuccess = (id) => {
    return api.post(`/sendemail/b2b/activate/${id}`);
}

/** 
 * @function sendConfirmEmail
 * @description Final Request to send mails after booking
 * @param confirmId : booking confirm id (cid)
*/
export const sendConfirmEmail = (confirmId) => {
    return api.post(`/sendemail/flight/confirmbooking/${confirmId}`);
}

/** 
 * @function paymentReport
 * @description Final Request to send mails after booking
 * @param confirmId : booking confirm id (cid)
*/
export const paymentReport = (confirmId) => {
    return api.post(`/report/payment/${confirmId}`, {});
}
