import React, { useRef, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import AsyncSelect from "react-select/async";
import { restrictAirport, validateRestrictAirport, airportSearch, restrcitAllAirport } from '../../../api/admin-api';
import './modal-restrict.scss';
import * as Yup from 'yup';
import RestrictAirlineList from './restrict-airline-list';
import LaddaButton, { SLIDE_UP } from 'react-ladda';

const ModalRestrict = ({ setShow, handleClose, airlineCode, airlineName, restrictList, data, updates, modalChangers, modalFails, modalNoChange }) => {
    const formRef = useRef();

    // save button loading
    const [buttonLoading, setButtonLoading] = useState(false);

    const handleSubmit = () => {
        const airportCode = formRef.current.values.restrictAirport;
        if (airportCode) {
            handleRestrictAirport(airportCode);
        }

        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    };

    // add to Restrict list api
    const handleRestrictAirport = (airportCode) => {
        setButtonLoading(true);
        validateRestrictAirport(airlineCode, airportCode).then(res => {
            if (res.status === 200) {
                modalNoChange();
            } else {
                modalFails();
            }
        }).catch(error => {
            if (error.response.status === 404) {
                const status = true;
                restrictAirport(airlineCode, airportCode, status).then(res => {
                    updates();
                    modalChangers();
                })
                    .catch(err => {
                        modalFails();
                    })
            }
            else {
                modalFails();
            }
        })
    }

    const schema = Yup.object().shape({
        restrictAirport: Yup.string()
            .required('Required')
    });

    /* Ui for the drop down of the airport select */
    const formatAirportSelect = (selectedObj, options) => {
        if (options.context === "menu") {
            // option ui
            return (
                <div className="airport-option">
                    <div className="text-1">
                        {selectedObj.apIataCode3} :{" "}
                        <span className="small">{selectedObj.apName}</span>
                    </div>
                    <div className="text-2">{selectedObj.apCityName} </div>
                </div>
            );
        } else {
            // selected value ui
            return <div>{selectedObj.apIataCode3}</div>;
        }
    };

    /* Search airport list promise */
    const airportListLoadPromise = (inputValue) => {
        return new Promise((resolve, reject) => {
            if (inputValue.length >= 3) {
                // airport search api
                airportSearch(inputValue).then(res => {
                    resolve(res.data);
                })
            } else {
                reject();
            }
        });
    };

    // restrict all airports api call
    const [status, setStatus] = useState(data.restrictAllAirport);
    const handleRestrictAllAirportstatus = () => {
        setStatus(!status);
        handleRestrictAllFunc();
    }
    const handleRestrictAllFunc = () => {
        setButtonLoading(true);
        restrcitAllAirport(airlineCode, !status).then(res => {
            updates();
            modalChangers();
        })
            .catch(error => {
                modalFails();
            })
    }

    return (
        <div>
            <Modal show={setShow} onHide={handleClose} >
                <div className="text-center mt-2">
                    <Modal.Title >{airlineName}</Modal.Title>
                </div>
                <Modal.Body>
                    <h5 className="text-center">Restrict</h5>

                    <div className="container">
                        <Formik
                            innerRef={formRef}
                            validationSchema={schema}
                            initialValues={{
                                restrictAirport: ""
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(false);
                            }}
                        >
                            {({ handleSubmit, values, errors, touched, isSubmitting, isValidating, setFieldValue, handleChange }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className="form-group row">
                                        <label htmlFor="airport" className="col-sm-4 col-form-label">Destination</label>
                                        <div className="col-sm-8">
                                            <AsyncSelect
                                                name="restrictAirport"
                                                cacheOptions
                                                defaultOptions
                                                loadOptions={value =>
                                                    airportListLoadPromise(value)
                                                }
                                                placeholder="Select Airport"
                                                formatOptionLabel={formatAirportSelect}
                                                getOptionValue={obj => obj.apIataCode3}
                                                selected={values.restrictAirport}
                                                onChange={(value) => {
                                                    setFieldValue('restrictAirport', value.apIataCode3)
                                                }}
                                            />
                                            {errors.restrictAirport && touched.restrictAirport ? (
                                                <small className="text-danger font-italic">{errors.restrictAirport}</small>
                                            ) : null}
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>

                        <hr />

                        <div className="mb-2">
                            <input type="checkbox" className="mr-2" defaultChecked={status} onClick={handleRestrictAllAirportstatus} />
                        Restrict All Airport
                        </div>

                        {status ? null :
                            <div className="">
                                {
                                    restrictList ?
                                        restrictList.map((list) => {
                                            return (
                                                <div key={list.id}>
                                                    <RestrictAirlineList list={list} airlineCode={airlineCode} updates={updates} modalChangers={modalChangers} modalFails={modalFails} />
                                                </div>
                                            )
                                        })
                                        : null
                                }
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>

                    <LaddaButton
                        type="submit"
                        className="btn btn-primary ladda-button"
                        loading={buttonLoading}
                        data-style={SLIDE_UP}
                        onClick={handleSubmit}
                    >
                        Add
                    </LaddaButton>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default ModalRestrict;
