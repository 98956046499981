import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import { disableAgent, enableAgent, updateAgentDetails, activateAgent, deactivateAgent, agentGrantEmailSuccess } from '../../../api/admin-api';
import { getCountryCodeByCountry } from '../../../../api/flight-booking.api';
import NotifyModal from '../../../../common/components/notify-modal';
import ReactGoogleMapsLoader from 'react-google-maps-loader';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';
import { googleMap } from '../../../../booking-engine/booking-enigne-config';
import './agent-detail-modal.scss';
import { deleteAgentUserIdentificationDoc, getAgentUserIdentificationDoc, uploadAgentUserIdentificationDoc } from '../../../../api/storage.api';

const AgentDetailModal = ({ show, data, handleClose, dataFrom, update, modalAgentDataChanged, modalAgentDeactivated, modalAgentGrant, modalAgentRevoke, modalAgentActivated, modalAgentFailed }) => {
    const formRef = useRef();

    // confirm modal show
    const [showConfirm, setShowConfirm] = useState(false);
    const handleCloseConfirm = () => setShowConfirm(false);

    // to identify the agent is to activate or already activated
    const [showActiveAgent, setShowActiveAgent] = useState(false);

    // to identify the agent information is going to save
    const [saveAgentInformation, setSaveAgentInformation] = useState(false);

    // to identify the agent information is going to Dissable
    const [dissableAgentInformation, setDissableAgentInformation] = useState(false);

    // delete button loading
    const [buttonLoading, setButtonLoading] = useState(false);

    // notification error modal
    const [notifyError, setNotifyError] = useState(null);

    const [search, setSearch] = useState('');
    const [mapData, setMapData] = useState(null);
    const [mapDataCountry, setMapDataCountry] = useState([]);

    // document url
    const [documentUrl, setDocumentUrl] = useState(null);

    // const schema = Yup.object().shape({
    //     agentStatus: Yup.boolean(),
    //     b2buDiscount: Yup.number()
    //         .min(0, 'Can not be negative')
    //         .lessThan(99, 'can not be exceed 99')
    //         .required('Required'),
    //     b2buEmail: Yup.string()
    //         .required('Required')
    // });
    const schema = Yup.object().shape({
        b2buTelephone: Yup.string()
            .min(8, 'Telephone must contain at least 8 digits')
            .max(13, "Telephone can't exceed 13 digits")
            .required('Telephone is required'),
        b2buGender: Yup.string(),
        b2buCity: Yup.string()
            .required('City is required'),
        b2buCopyResEmailsTo: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        b2buIdentyficationDoc : Yup.object(),
        b2buCompanyName: Yup.string()
            .required('Company Name is required'),
        b2buCompanyTradingName: Yup.string()
            .required('Trading Name is required'),
        b2buABNACN: Yup.string(),
        b2buAddress: Yup.string()
            .required('Address is required'),
        b2buCompanyEmail: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        b2buCompanyTelephone: Yup.string()
            .min(8, 'Telephone must contain at least 8 digits')
            .max(13, "Telephone can't exceed 13 digits")
            .required('Telephone is required'),
        b2buDiscount: Yup.number()
            .min(0, 'Can not be negative')
            .lessThan(99, 'can not be exceed 99')
            .required('Required'),
        agentStatus: Yup.boolean()
        // cognito_phone_number : "",
    });

    const initialValues = {
        // b2buCompanyName: data.b2buCompanyName ? data.b2buCompanyName : null,
        // b2buAddress: data.b2buAddress ? data.b2buAddress : null,
        // b2buCity: data.b2buCity ? data.b2buCity : null,
        // b2buCountryName: data.b2buCountryName ? data.b2buCountryName : null,
        // b2buCountryCode: data.b2buCountryCode ? data.b2buCountryCode : null,
        // b2buTelephone: data.b2buTelephone ? data.b2buTelephone : null,
        // b2buCopyResEmailsTo: data.b2buCopyResEmailsTo ? data.b2buCopyResEmailsTo : null,
        // b2buPaymentMode: data.b2buPaymentMode ? data.b2buPaymentMode : null,
        // b2buDiscount: data.b2buDiscount ? data.b2buDiscount : 0,
        // agentStatus: data.cognitoUserAttributes.enabled ? data.cognitoUserAttributes.enabled : false,
        // userEmail: data.cognitoUserAttributes.userAttributesMap.email ? data.cognitoUserAttributes.userAttributesMap.email : null,
        // userName: data.cognitoUserAttributes.userAttributesMap.given_name ? data.cognitoUserAttributes.userAttributesMap.given_name : null,
        // userPhone: data.cognitoUserAttributes.userAttributesMap.phone_number ? data.cognitoUserAttributes.userAttributesMap.phone_number : null,

        b2buTelephone: data.b2buTelephone ? data.b2buTelephone : "",
        b2buGender: data.b2buGender ? data.b2buGender : "Male",
        b2buCity: data.b2buCity ? data.b2buCity : "",
        b2buCopyResEmailsTo: data.b2buCopyResEmailsTo ? data.b2buCopyResEmailsTo : "",
        b2buIdentyficationDoc : null,
        b2buDiscount : data.b2buDiscount ? data.b2buDiscount : 0,
        // company
        b2buCompanyName: data.b2buCompanyName ? data.b2buCompanyName : "",
        b2buCompanyTradingName: data.b2buCompanyTradingName ? data.b2buCompanyTradingName : "",
        b2buABNACN: data.b2buABNACN ? data.b2buABNACN : "",
        b2buAddress:  data.b2buAddress ? data.b2buAddress : "",
        b2buCompanyEmail: data.b2buCompanyEmail ? data.b2buCompanyEmail : "",
        b2buCompanyTelephone: data.b2buCompanyTelephone ? data.b2buCompanyTelephone : "",
        agentStatus: data.cognitoUserAttributes.enabled ? data.cognitoUserAttributes.enabled : false
    };

    // get user document
    useEffect(() => {
        getAgentUserIdentificationDoc(data.cognitoUserAttributes.username)
        .then(res => {
            setDocumentUrl(res.data);
        }).catch( err => console.log(err))
    },[data]);

    const handleAccepted = () => {
        setShowConfirm(true);
        setSaveAgentInformation(false)
        setDissableAgentInformation(false)
        setShowActiveAgent(true);
    }

    const handleAcceptedConfirm = () => {
        setButtonLoading(true);
        deactivateAgent(data.b2bUID, data.userIdentityServiceId).then((res) => {
            console.log(res);
            update();
            modalAgentRevoke(data.b2buCompanyName.split(' ')[0]);
        })
            .catch((err) => {
                setButtonLoading(false);
                console.log(err);
                modalAgentFailed()

            })
    }


    const handleSave = () => {
        setShowConfirm(true);
        setSaveAgentInformation(true)
        setDissableAgentInformation(false)
        setShowActiveAgent(false)
    }

    const handleSaveConfirm = () => {
        setButtonLoading(true);

        let formdata = formRef.current.values;

        if (formdata) {
            // const dataFromUser = {
            //     b2buLocationId: data.b2buLocationId,
            //     b2bUID: data.b2bUID,
            //     userIdentityServiceId: data.userIdentityServiceId,
            // }
            const dataFromUser = {
                b2bUID: data.b2bUID,
                userIdentityServiceId: data.userIdentityServiceId,
                b2buLocationId: mapData ? mapData.place_id : data.b2buLocationId,
                b2buCountryCode: mapData ? mapDataCountry.short_name : data.b2buCountryCode,
                b2buCountryName: mapData? mapDataCountry.long_name : data.b2buCountryName
            };

            const dataToPass = { ...formdata, ...dataFromUser };

            updateAgentDetails(dataToPass).then((res) => {
                // update id doc if available after agent updated
                if (dataToPass.b2buIdentyficationDoc) {
                    // delete previous file available
                    if (documentUrl) {
                        deleteAgentUserIdentificationDoc(data.userIdentityServiceId)
                        .then(() => {
                            // upload new file
                            uploadAgentUserIdentificationDoc(data.userIdentityServiceId, dataToPass.b2buIdentyficationDoc)
                            .then(() => {
                                modalAgentDataChanged(data.b2buCompanyName.split(' ')[0]);
                                update();
                            })
                            .catch(() => {
                                setNotifyError({
                                    title: "Oops! ",
                                    subTitle: "Could not update your ID document",
                                    body: "Please contact the system support."
                                })
                            })
                        })
                        .catch(() => {
                            console.log('could not delete previouse uploaded file');
                        })
                    } else {
                        // upload new file
                        uploadAgentUserIdentificationDoc(data.userIdentityServiceId, dataToPass.b2buIdentyficationDoc)
                        .then(() => {
                            modalAgentDataChanged(data.b2buCompanyName.split(' ')[0]);
                            update();
                        })
                        .catch(() => {
                            setNotifyError({
                                title: "Oops! ",
                                subTitle: "Could not update your ID document",
                                body: "Please contact the system support."
                            })
                        })
                        
                    }
                    
                } else {
                    modalAgentDataChanged(data.b2buCompanyName.split(' ')[0]);
                    update();
                }
            })
            .catch((err) => {
                setButtonLoading(false);
                modalAgentFailed()
            });
        } else {
            // failed
            modalAgentFailed();
            setButtonLoading(false);
        }
    }

    // initialize agent accept
    const handleAccept = () => {
        setShowConfirm(true);
        setSaveAgentInformation(false)
        setDissableAgentInformation(false)
        setShowActiveAgent(true);
    }

    // agent accept api call
    const handleAcceptConfirm = () => {
        setButtonLoading(true);

        // save the edited details of the agent before accepting
        handleSaveConfirm();

        activateAgent(data.b2bUID, data.userIdentityServiceId).then(() => {
            modalAgentGrant(data.b2buCompanyName.split(' ')[0]);

            agentGrantEmailSuccess(data.userIdentityServiceId).then(() => {
                update();
                setButtonLoading(false);
            })
                .catch(() => {
                    setButtonLoading(false);
                    setShowConfirm(false);
                    setNotifyError({
                        title: "Oops! ",
                        subTitle: `Error occuered when sending the success notification to the agent ${data.b2buCompanyName} .`,
                        body: `Please send the email manually to the email address ${data.b2buEmail} for notify the agent.`
                    })
                })
        })
            .catch((err) => {
                console.log(err);
                setButtonLoading(false);
                modalAgentFailed()
            })
    }

    const handleDissable = () => {
        setShowConfirm(true);
        setDissableAgentInformation(true)
        setSaveAgentInformation(false)
        setShowActiveAgent(false);
    }

    const handleDissableConfirm = () => {
        if (dataFrom === 'disable') {
            enableAgent(data.b2bUID, data.userIdentityServiceId).then((res) => {
                update();
                modalAgentActivated(data.b2buCompanyName.split(' ')[0]);
            }).catch((err) => {
                console.log(err);
                modalAgentFailed()
            });
        } else {
            disableAgent(data.b2bUID, data.userIdentityServiceId).then((res) => {
                console.log(res);
                update();
                modalAgentDeactivated(data.b2buCompanyName.split(' ')[0]);
            })
                .catch((err) => {
                    console.log(err);
                    modalAgentFailed()
                });
        }
    }

    const countryDetails = (data) => data.address_components.filter(list => {
        if (list.types.includes('country')) {
            setMapDataCountry(list)

            console.log(mapDataCountry);
            getCountryCodeByCountry(mapDataCountry.long_name).then((res) => {
                console.log(res.data);
            })
                .catch((err) => {
                    console.log(err);
                })
        }
        return list
    });

    return (
        <Fragment>
            {
                showConfirm ?
                    <Modal size="sm" show={showConfirm} onHide={handleCloseConfirm} className={showActiveAgent ? "" : "modal-agent-delete-confirm"} centered>
                        <Modal.Header closeButton>
                            <Modal.Title className={"text-uppercase " + (setShowActiveAgent ? "active-agent" : "")}>
                                {
                                    saveAgentInformation ?
                                        <span> Edit {data.b2buCompanyName.split(' ')[0]} </span>
                                        :
                                        null
                                }
                                {
                                    showActiveAgent && dataFrom === 'pending' ?
                                        <span>Grant B2B access for :  {data.b2buCompanyName.split(' ')[0]}</span>
                                        :
                                        null
                                }
                                {
                                    showActiveAgent && dataFrom === 'active' ?
                                        <span>Revoke B2B access for : {data.b2buCompanyName.split(' ')[0]}</span>
                                        :
                                        null
                                }
                                {
                                    dissableAgentInformation ?
                                        <span>{dataFrom === 'disable' ? 'Enable' : 'Dissable'}  {data.b2buCompanyName.split(' ')[0]}</span>
                                        :
                                        null
                                }

                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="text-center text-uppercase">
                            Are you sure ?
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-around confirm-modal-agent">
                            {
                                dataFrom === 'active' && !saveAgentInformation && !dissableAgentInformation
                                    ?
                                    <LaddaButton
                                        type="submit"
                                        className="btn btn-primary"
                                        loading={buttonLoading}
                                        data-style={SLIDE_UP}
                                        onClick={handleAcceptedConfirm}
                                    >
                                        Accept
                                    </LaddaButton>
                                    :
                                    null
                            }
                            {
                                dataFrom === 'pending' && !saveAgentInformation && !dissableAgentInformation
                                    ?
                                    <LaddaButton
                                        type="submit"
                                        className="btn btn-primary"
                                        loading={buttonLoading}
                                        data-style={SLIDE_UP}
                                        onClick={handleAcceptConfirm}
                                    >
                                        Accept
                                    </LaddaButton>
                                    :
                                    null
                            }
                            {
                                saveAgentInformation ?
                                    <LaddaButton
                                        type="submit"
                                        className="btn btn-primary"
                                        loading={buttonLoading}
                                        data-style={SLIDE_UP}
                                        onClick={handleSaveConfirm}
                                    >
                                        Save
                                    </LaddaButton>
                                    :
                                    null
                            }
                            {
                                dissableAgentInformation ?
                                    <LaddaButton
                                        type="submit"
                                        className="btn btn-primary"
                                        loading={buttonLoading}
                                        data-style={SLIDE_UP}
                                        onClick={handleDissableConfirm}
                                    >
                                        {dataFrom === 'disable' ? 'Enable' : 'Dissable'}
                                    </LaddaButton>
                                    :
                                    null
                            }
                            <button className="btn" onClick={handleCloseConfirm} >Cancel</button>
                        </Modal.Footer>
                    </Modal>
                    :
                    null
            }

            <Modal size="lg" show={show} onHide={handleClose} className={showConfirm ? "modal-agent-details" : ""} dialogClassName="modal-70w">
                <Modal.Header closeButton>
                    <Modal.Title>Agent Information</Modal.Title>
                </Modal.Header>

                <Formik
                    innerRef={formRef}
                    enableReinitialize
                    initialErrors ={initialValues}
                    initialValues={initialValues}
                    validationSchema={schema}

                // onSubmit={(values, { setSubmitting }) => {
                //     console.log(values);
                //     setSubmitting(false);
                // }}
                >
                    {({ values, errors, touched, setFieldValue, handleChange, isSubmitting }) => (
                        <Form >
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <div className="mb-3 h5">
                                            Agent information
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label"> First Name</label>
                                            <div className="col-sm-8">
                                                <div className="form-control-plaintext">{data.cognitoUserAttributes.userAttributesMap.given_name}</div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label"> Last Name</label>
                                            <div className="col-sm-8">
                                                <div className="form-control-plaintext">{data.cognitoUserAttributes.userAttributesMap.family_name}</div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Email</label>
                                            <div className="col-sm-8">
                                                <div className="form-control-plaintext">{data.cognitoUserAttributes.userAttributesMap.email}</div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Payment Mode</label>
                                            <div className="col-sm-8">
                                                <div className="form-control-plaintext">{data.b2buPaymentMode}</div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="b2buGender" className="col-sm-4 col-form-label">Gender</label>
                                            <div className="col-sm-8">

                                                <div role="group" aria-labelledby="my-radio-group">
                                                    <label className="mr-4">
                                                        <Field type="radio" name="b2buGender" className="mr-2" value="male" defaultChecked />
                                                        Male
                                                    </label>
                                                    <label className="mr-4">
                                                        <Field type="radio" name="b2buGender" className="mr-2" value="femail" />
                                                        Femail
                                                    </label>
                                                    <label className="">
                                                        <Field type="radio" name="b2buGender" className="mr-2" value="other" />
                                                        Other
                                                    </label>
                                                </div>

                                                {/* <Field className={'form-control' + (errors.b2buGender && touched.b2buGender ? ' is-invalid' : '')} name="b2buGender" type="number" /> */}
                                            </div>
                                        </div>

                                        <div className="form-group row" >
                                            <label htmlFor="b2buCity" className="col-sm-4 col-form-label">City</label>
                                            <div className="col-sm-8">
                                                <ReactGoogleMapsLoader
                                                    params={{
                                                        key: googleMap,
                                                        libraries: "places,geocode",
                                                    }}
                                                    render={googleMaps =>
                                                        googleMaps && (
                                                            <ReactGooglePlacesSuggest
                                                                googleMaps={googleMaps}
                                                                autocompletionRequest={{
                                                                    input: search
                                                                }}
                                                                onSelectSuggest={suggest => {
                                                                    setMapData(suggest);
                                                                    setFieldValue('b2buCity', suggest.formatted_address)
                                                                    setSearch("");
                                                                    countryDetails(suggest);
                                                                }}
                                                                customRender={prediction => (
                                                                    <div className="customWrapper">
                                                                        {prediction
                                                                            ? prediction.description
                                                                            : "no results found"}
                                                                    </div>
                                                                )}
                                                            >
                                                                <Field
                                                                    onChange={e => {
                                                                        setSearch(e.target.value);
                                                                        handleChange(e);
                                                                    }}
                                                                    className={'form-control' + (errors.b2buCity && touched.b2buCity ? ' is-invalid' : '')}
                                                                    name="b2buCity" type="text" placeholder="City" />
                                                                <ErrorMessage name="b2buCity" component="div" className="invalid-feedback" />
                                                            </ReactGooglePlacesSuggest>
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="b2buTelephone" className="col-sm-4 col-form-label">Telephone</label>
                                            <div className="col-sm-8">
                                                <Field className={'form-control' + (errors.b2buTelephone && touched.b2buTelephone ? ' is-invalid' : '')} name="b2buTelephone" type="text" placeholder="Telephone" />
                                                <ErrorMessage name="b2buTelephone" component="div" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="b2buIdentyficationDoc" className="col-sm-4 col-form-label">Identyfication Doc</label>
                                            <div className="col-sm-4">
                                                <input id="b2buIdentyficationDoc" name="b2buIdentyficationDoc" type="file" onChange={(event) => {
                                                    setFieldValue("b2buIdentyficationDoc", event.currentTarget.files[0]);
                                                }} />
                                            </div>
                                            <div className="col-sm-4 text-sm-right">
                                                {documentUrl ? <a href={documentUrl} className="btn btn-outline-primary btn-sm" download><i className="fa fa-download mr-2"></i> Download</a> : null}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="b2buCopyResEmailsTo" className="col-sm-4 col-form-label">Copy Reservation Emails To</label>
                                            <div className="col-sm-8">
                                                <Field className={'form-control' + (errors.b2buCopyResEmailsTo && touched.b2buCopyResEmailsTo ? ' is-invalid' : '')} name="b2buCopyResEmailsTo" type="text" placeholder="Telephone" />
                                                <ErrorMessage name="b2buCopyResEmailsTo" component="div" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        {
                                            dataFrom === 'active' || dataFrom === 'pending' ?
                                                <div className="form-group row">
                                                    <label htmlFor="b2buDiscount" className="col-sm-4 col-form-label">Discount (as %)</label>
                                                    <div className="col-sm-8">
                                                        <Field className={'form-control' + (errors.b2buDiscount && touched.b2buDiscount ? ' is-invalid' : '')} name="b2buDiscount" type="number" />
                                                        <ErrorMessage name="b2buDiscount" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                        <div className="form-group row">
                                            <label htmlFor="agentStatus" className="col-sm-4 col-form-label">Disabled
                                            </label>
                                            <div className="col-sm-8 agentStatus">
                                                <Field type="checkbox" name="agentStatus" onClick={() => handleDissable()} checked={!values.agentStatus} className={'form-control' + (errors.agentStatus && touched.agentStatus ? ' is-invalid' : '')} />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-12 col-lg-6">
                                        <div className="mb-3 h5">
                                            Company information
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="b2buCompanyName" className="col-sm-4 col-form-label">Company Register Name</label>
                                            <div className="col-sm-8">
                                                <Field className={'form-control' + (errors.b2buCompanyName && touched.b2buCompanyName ? ' is-invalid' : '')} name="b2buCompanyName" type="text" placeholder="Company Name" />
                                                <ErrorMessage name="b2buCompanyName" component="div" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="b2buCompanyTradingName" className="col-sm-4 col-form-label">Company Trading Name</label>
                                            <div className="col-sm-8">
                                                <Field className={'form-control' + (errors.b2buCompanyTradingName && touched.b2buCompanyTradingName ? ' is-invalid' : '')} name="b2buCompanyTradingName" type="text" placeholder="Company Trading Name" />
                                                <ErrorMessage name="b2buCompanyTradingName" component="div" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="b2buABNACN" className="col-sm-4 col-form-label">ABN/ACN</label>
                                            <div className="col-sm-8">
                                                <Field className={'form-control' + (errors.b2buABNACN && touched.b2buABNACN ? ' is-invalid' : '')} name="b2buABNACN" type="text" placeholder="ABN/ACN" />
                                                <ErrorMessage name="b2buABNACN" component="div" className="invalid-feedback" /> 
                                            </div>
                                        </div>
                                        <div className="form-group row mt-2 mt-lg-4">
                                            <label htmlFor="b2buAddress" className="col-sm-4 col-form-label">Address</label>
                                            <div className="col-sm-8">
                                                <Field className={'form-control' + (errors.b2buAddress && touched.b2buAddress ? ' is-invalid' : '')} name="b2buAddress" type="text" placeholder="Enter Company Address" />
                                                <ErrorMessage name="b2buAddress" component="div" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className="form-group row mt-2 mt-lg-4">
                                            <label htmlFor="b2buCompanyEmail" className="col-sm-4 col-form-label">Email</label>
                                            <div className="col-sm-8">
                                                <Field className={'form-control' + (errors.b2buCompanyEmail && touched.b2buCompanyEmail ? ' is-invalid' : '')} name="b2buCompanyEmail" type="email" placeholder="Enter Company Email" />
                                                <ErrorMessage name="b2buCompanyEmail" component="div" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className="form-group row mt-2 mt-lg-4">
                                            <label htmlFor="b2buCompanyTelephone" className="col-sm-4 col-form-label">Phone no</label>
                                            <div className="col-sm-8">
                                                <Field className={'form-control' + (errors.b2buCompanyTelephone && touched.b2buCompanyTelephone ? ' is-invalid' : '')} name="b2buCompanyTelephone" type="text" placeholder="Enter Copmany Phone no" />
                                                <ErrorMessage name="b2buCompanyTelephone" component="div" className="invalid-feedback" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Modal.Body>

                            <Modal.Footer>
                                <Button type="button" variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button type="Button" variant="primary" onClick={handleSave} >
                                    Save
                                </Button>
                                {
                                    dataFrom === 'pending' ?
                                        <Button type="button" variant="primary" onClick={handleAccept}>
                                            Grant B2B Access
                                        </Button>
                                        :
                                        null
                                }
                                {
                                    dataFrom === 'active' ?
                                        <Button type="button" variant="primary" onClick={handleAccepted}>
                                            Revoke B2B Access
                                     </Button>
                                        :
                                        null
                                }
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>

            {
                notifyError ?
                    <NotifyModal
                        type="danger"
                        title={notifyError.title}
                        subTitle={notifyError.subTitle}
                        body={notifyError.body}

                    />
                    :
                    null
            }
        </Fragment>
    )
}

export default AgentDetailModal
