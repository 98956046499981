import React from "react";
import { connect } from "react-redux";
import { appAuthenticateAction } from "../../store/actions/app.actions";
import UiLoader from "../components/ui-loader/ui-loader";

class AuthenticatedApp extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        // authenticate app
        this.props.authenticateApp();
    }

    render() {
        return this.props.appAuthenticating ? (
            <UiLoader fullscreen={true} />
        ) : (
            this.props.children
        );
        //return <UiLoader/>;
    }
}

const mapStateToProps = state => {
    return {
        appAuthenticating: state.app.authenticating
    };
};

const mapDispatchToProps = dispatch => {
    return {
        authenticateApp: () => dispatch(appAuthenticateAction())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedApp);
