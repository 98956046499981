import React, { Component } from 'react';
import $ from 'jquery';
import DataTable from 'datatables.net-bs4';
import * as moment from 'moment';
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';
import { Accordion,Card, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import { sendConfirmEmail, paymentReport } from '../../api/admin-api';
import UiLoader from '../../../common/components/ui-loader/ui-loader';
import './bookings-report-component.scss';

$.DataTable = DataTable;

export default class BookingsReportComponent extends Component {

    constructor(props) {
        super(props);
        this.tableAPI = null;
        this.state = {
            show: false,
            dataToModal: null,
            buttonLoading: false,
            paymentReportDetails: null,
            paymentReportDetailsLog: null,
            paymentLoading: false,
            abc: null,
        }
    }

    componentDidMount() {
        this.tableAPI = $('#bookingsReports').DataTable({
            order: [3, 'desc']
        });
    }

    handleClose = () => {
        this.setState({
            show: false
        })
    };

    handleShow = (data, type) => {
        this.setState({
            show: true,
            dataToModal: { ...data, type }
        })

        if (type === "paymentDetails") {
            this.setState({
                paymentLoading: true,
            })
            this.reportPayment(data.confirmId)
        }
    };

    // today = moment().format("YYYY M D")

    sendEmail = (id) => {
        this.setState({
            buttonLoading: true
        })

        sendConfirmEmail(id).then(() => {
            this.props.handleEmailSuccess()
            this.setState({
                show: false,
                buttonLoading: false
            })
        })
            .catch(() => {
                this.props.handleFailed()
                this.setState({
                    show: false,
                    buttonLoading: false
                })
            })
    }

    reportPayment = (id) => {
        paymentReport(id).then((res) => {
            var toJson = null;
            var toJsonTo = null;

            if (res.data.ipgName === 'POLI') {
                toJson = JSON.parse(res.data.responseObj);
            } else {
                toJsonTo = JSON.parse(res.data.responseObj);

                var abc = []
                const func = (data) => {
                    for (var k in data) {
                        if (data[k] instanceof Object) {
                            func(data[k]);
                        } else {
                            abc.push({
                                key: k,
                                value: data[k]
                            })
                        };
                    }
                }

                func(toJsonTo)

                toJson = abc;
            }

            this.setState({
                paymentReportDetails: res.data,
                paymentReportDetailsLog: toJson,
                paymentLoading: false,
            })

        })
            .catch(() => {
                this.props.handleFailed()
            })
    }


    getFormattedReservationDate(timeStamp) {
        try {
            return moment(timeStamp).format('DD-MM-YYYY')+' at '+moment(timeStamp).format('LT');
        } catch (error) {
            return '';
        }
    }


    render() {
        return (
            <div className="booking-report">

                {
                    this.state.show ?
                        <Modal show={this.state.show} onHide={this.handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {this.state.dataToModal.type === 'pessenger' ? `Passenger Details of ${this.state.dataToModal.bookerName}` : null}
                                    {this.state.dataToModal.type === 'trip' ? `Trip Itineraries of ${this.state.dataToModal.bookerName}` : null}
                                    {this.state.dataToModal.type === 'email' ? 'Send Confirm Email Manually' : null}
                                    {this.state.dataToModal.type === 'paymentDetails' ? `Payment Details of ${this.state.dataToModal.bookerName}` : null}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    this.state.dataToModal.type === 'pessenger' ?
                                        this.state.dataToModal.passengerItineraries.map(passenger => (
                                            <ol key={passenger.passengerId}>
                                                <li>{passenger.title}. {passenger.firstName} {passenger.lastName} </li>
                                            </ol>
                                        ))
                                        :
                                        null
                                }
                                {
                                    this.state.dataToModal.type === 'trip' ?
                                        this.state.dataToModal.tripItineraries.map((trip, index) => (
                                            <div key={trip.tripId}>
                                                <p>Trip Itinerary : #{index + 1}</p>
                                                <ul>
                                                    <li>Boardpoint : {trip.boardPoint} </li>
                                                    <li>Offpoint : {trip.offPoint} </li>
                                                    <li>Flight Number : {trip.flightNumber} </li>
                                                    <li>Marketing Carrier : {trip.marketingCarrier} </li>
                                                </ul>
                                            </div>
                                        ))
                                        :
                                        null
                                }
                                {
                                    this.state.dataToModal.type === 'email' ?
                                        <div>
                                            <div>Are you sure to send the confirmation email to the user <b> {this.state.dataToModal.bookerName} </b> at <b> {this.state.dataToModal.bookerEmail} </b> </div>
                                            <div className="row justify-content-center mt-4">
                                                <div className="col-12 col-sm-4">
                                                    <LaddaButton
                                                        type="submit"
                                                        className="btn btn-primary btn-block"
                                                        loading={this.state.buttonLoading}
                                                        data-style={SLIDE_UP}
                                                        onClick={() => this.sendEmail(this.state.dataToModal.confirmId)}
                                                    >
                                                        Yes
                                                    </LaddaButton>
                                                </div>
                                                {
                                                    !this.state.buttonLoading ? 
                                                    <div className="col-12 col-sm-4">
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary btn-block"
                                                            onClick={this.handleClose}
                                                        >
                                                            No
                                                        </button>
                                                    </div> : null
                                                }
                                                
                                            </div>
                                        </div>
                                        :
                                        null
                                }

                                {
                                    this.state.paymentLoading ?
                                        <UiLoader />
                                        :
                                        this.state.dataToModal.type === 'paymentDetails'
                                            ?
                                            <div>
                                                <div>
                                                    Gateway : {this.state.paymentReportDetails.ipgName ? this.state.paymentReportDetails.ipgName : '-'}
                                                </div>
                                                <div>
                                                    Status : {this.state.paymentReportDetails.ipgStatus ? this.state.paymentReportDetails.ipgStatus : '-'}
                                                </div>
                                                <div>
                                                    Transaction Reference : {this.state.paymentReportDetails.transactionRef ? this.state.paymentReportDetails.transactionRef : '-'}
                                                </div>
                                                <div>
                                                    <Accordion >
                                                        <Card>
                                                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                                                Log informations {this.state.paymentReportDetailsLog ? <i className="fa fa-hand-o-down ml-2" aria-hidden="true"></i> : ' : empty '}
                                                            </Accordion.Toggle>
                                                            {this.state.paymentReportDetailsLog
                                                                ?
                                                                <Accordion.Collapse eventKey="0">
                                                                    <Card.Body>
                                                                        {
                                                                            this.state.paymentReportDetailsLog ?
                                                                                this.state.paymentReportDetails.ipgName === 'STRIPE' ?
                                                                                    this.state.paymentReportDetailsLog.map((item, index) => (
                                                                                        <ul key={index}>
                                                                                            {
                                                                                                item.value ?
                                                                                                    <li>{item.key} : {item.value}</li>
                                                                                                    :
                                                                                                    null
                                                                                            }

                                                                                        </ul>
                                                                                    ))
                                                                                    :
                                                                                    Object.keys(this.state.paymentReportDetailsLog).map((key, index) => (
                                                                                        <ul key={index} >
                                                                                            <li> {key} : {this.state.paymentReportDetailsLog[key]} </li>
                                                                                        </ul>
                                                                                    ))
                                                                                :
                                                                                null
                                                                        }
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                                :
                                                                null}
                                                        </Card>
                                                    </Accordion>
                                                </div>
                                            </div>
                                            :
                                            null
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-outline-primary" onClick={this.handleClose}> Close </button>
                            </Modal.Footer>
                        </Modal>
                        :
                        null
                }

                <table id="bookingsReports" className="table display table-striped table-bordered w-100" >
                    <thead>
                        <tr>
                            <th>PNR</th>
                            <th>Reservation Date</th>
                            {/* <th>Reservation ID</th> */}
                            <th>Payment Reference</th>
                            <th>Passenger Details</th>
                            <th>Trip Itineraries</th>
                            <th style={{minWidth : '160px'}}>Booker Name</th>
                            <th style={{minWidth : '50px'}}>Agent %</th>
                            <th>Booker Email</th>
                            <th style={{minWidth : '90px'}}>Sub Total (AUD)</th>
                            <th>Warning Message</th>
                            <th style={{minWidth : '100px'}}>Payment Details</th>
                            <th>Send Email</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            this.props.data.map(book => (
                                <tr key={book.confirmId} className="text-center" >
                                    <td>{book.pnrNumber ? book.pnrNumber : '-'}</td>
                                    <td> {book.reservationDatetime ?  this.getFormattedReservationDate(book.reservationDatetime) : '-'} </td>
                                    {/* <td> {book.reservationId ? book.reservationId : '-'} </td> */}
                                    <td> {book.confirmId ? book.confirmId : '-'} </td>
                                    <td> {book.passengerItineraries ? <button onClick={() => this.handleShow(book, 'pessenger')} className="btn btn-primary">view</button> : '-'} </td>
                                    <td> {book.tripItineraries ? <button onClick={() => this.handleShow(book, 'trip')} className="btn btn-primary">view</button> : '-'} </td>
                                    <td> {book.bookerName} </td>
                                    <td className="text-right"> {book.b2cFinalIpgFeeAddedFare && book.finalIpgFeeAddedFare ? (book.b2cFinalIpgFeeAddedFare - book.finalIpgFeeAddedFare).toFixed(2) : ''} </td>
                                    <td> {book.bookerEmail} </td>
                                    <td className="text-right">  {book.finalIpgFeeAddedFare ? book.finalIpgFeeAddedFare : '-'} </td>
                                    <td>
                                        {
                                            book.warningMessage ?
                                                <OverlayTrigger
                                                    placement={'right'}
                                                    overlay={
                                                        <Tooltip>
                                                            {book.warningMessage}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <i className="fa fa-exclamation-triangle text-secondary" aria-hidden="true"></i>
                                                </OverlayTrigger>
                                                :
                                                '-'
                                        }
                                    </td>
                                    <td className="font-weight-bold" >
                                        {book.paymentStatus
                                            ?
                                            <button type="button" className="btn btn-outline-success" onClick={() => this.handleShow(book, 'paymentDetails')}>  <i className="fa fa-hand-o-right mr-1" aria-hidden="true"></i>More</button>
                                            :
                                            <button type="button" className="btn btn-outline-secondary" onClick={() => this.handleShow(book, 'paymentDetails')}>  <i className="fa fa-hand-o-right mr-1" aria-hidden="true"></i>More</button>
                                        }
                                    </td>
                                    <td>  <button className="btn btn-primary" onClick={() => this.handleShow(book, 'email')} > Send </button> </td>
                                    {/* {moment(book.tripItineraries[0].datetimeOfArrival).format("YYYY M D").to(today)} */}
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        );
    }

}
