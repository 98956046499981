import React from 'react';
import FlightIcon from '../../../assets/images/flight-icon.png';
import { SESSIONSTORE } from '../../../common/storage';
import * as moment from 'moment';

const FlightSummaryBox = () => {
    const searchData = SESSIONSTORE.get('sqo');
    const resultData = SESSIONSTORE.get('rd');

    if (searchData && resultData) {
        // buisiness class text
        let bookingClass = '';
        if (searchData.bookingClass === 'Y') {
            bookingClass = 'Economic';
        } else if (searchData.bookingClass === 'W') {
            bookingClass = 'Economic Premium';
        } else {
            bookingClass = 'Business';
        }

        // trip type text
        let tripType = '';
        if (searchData.tripType === 'oneway') {
            tripType = 'One Way Trip';
        } else if (searchData.tripType === 'return') {
            tripType = 'Return Trip';
        } else {
            tripType = 'Multicity Trip';
        }

        // ui flight items list
        const flightItems = [];
        for (const tripKey in resultData.flightSliceMap) {
            if (resultData.flightSliceMap.hasOwnProperty(tripKey)) {
                const flightsInTrip = resultData.flightSliceMap[tripKey].flightItineraries;
                const tripDuration = resultData.flightSliceMap[tripKey].sliceDurationTxt;
                const startingFlight = flightsInTrip[0];
                const lastFlight = [...flightsInTrip].pop();

                const startingDate = moment(startingFlight.datetimeOfDeparture).format('DD MMM YYYY');
                const startingTime = moment(startingFlight.datetimeOfDeparture).format('LT');

                const endDate = moment(lastFlight.datetimeOfArrival).format('DD MMM YYYY');
                const endingTime = moment(lastFlight.datetimeOfArrival).format('LT');

                flightItems.push(<div className="row my-3" key={tripKey}>
                    <div className="col-12">
                        <div className="font-weight-bold flight-name"><span className="">{startingFlight.operatingCarrierName} : </span> {startingFlight.flightOrtrainNumber} </div>
                        <div><span className="font-weight-bold">- {startingFlight.boardPoint}</span> <span className="small">(<i className="fa fa-calendar mr-1"></i>{startingDate}, {startingTime}</span>)</div>
                        <div><span className="font-weight-bold">- {lastFlight.offPoint}</span> <span className="small">(<i className="fa fa-calendar mr-1"></i>{endDate}, {endingTime}</span>)</div>
                        <div> <i className="fa fa-clock-o mr-1 mt-2"></i> {tripDuration} </div>
                    </div>
                </div>);
            }
        }

        return (
            <div className="container py-3">
                <div className="row">
                    <div className="col-2 col-lg-3">
                        <img width="45" height="45" className="flight-ico" src={FlightIcon} alt="Flight" />
                    </div>
                    <div className="col-9 col-lg-9 p-0">
                        <div className="font-weight-bold h5 mb-0 text-primary sub-heading-1">Flight Summary</div>
                        <div className="text-primary">{bookingClass}, {tripType}</div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        {searchData.adults} Adult(s), {searchData.children} Child(ren), {searchData.infants} Infant(s)
                    </div>
                </div>
                {flightItems}
            </div>
        );
    } else {
        return null;
    }
};

export default FlightSummaryBox;