// React
import React from 'react';

// Routing
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// redux
import { Provider } from 'react-redux';
import store from './store';

import BookingEngine from './booking-engine/booking-engine';
import NotFound from './common/views/not-found';
// import ProtectedRoute from './common/hoc/protected-route';
import Admin from './admin/admin';
import AuthenticatedApp from './common/hoc/authenticated-app';
import ScrollToTop from './common/hoc/scroll-to-top';
import AwsCognito from './aws-cognito/aws-cognito';

import AwsCognitoRoutes from './aws-cognito/components/aws-cognito-routes';
import Unauthorized from './common/views/unauthorized';

import AgentRoute from './booking-engine/views/agent/agent-route';
import User from './booking-engine/views/user/user';

/** 
 * @component App
 * @description Root App Component
*/
function App() {
  return (
    <Provider store={store}>
      <AuthenticatedApp>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={BookingEngine} />
            <Route path="/booking-engine" component={BookingEngine} />
            <Route path="/aws-cognito" component={AwsCognito} />
            <Route path="/agent" component={AgentRoute} />
            <Route path="/user" component={User} />
            <AwsCognitoRoutes path="/admin" component={Admin} group="administrator" inttt="false" />
            <Route path="/unauthorized" component={Unauthorized} />
            <Route component={NotFound} />

          </Switch>
        </Router>
      </AuthenticatedApp>
    </Provider>
  );
}
export default App;
