import React from 'react'
import { Route } from "react-router-dom";
import Signin from './components/signin';
import Signout from './components/signout';
import SignUp from './components/signup';

const AwsCognito = ({ match }) => {
    return (
        <div>
            <Route
                exact
                path={`${match.url}/signin`}
                component={Signin}
            />
            <Route
                exact
                path={`${match.url}/signout`}
                component={Signout}
            />
            <Route
                exact
                path={`${match.url}/signup`}
                component={SignUp}
            />
        </div>
    )
}

export default AwsCognito;
