import React, { Fragment } from 'react'
import BookingEngineFooter from '../../components/booking-engine-footer/booking-engine-footer'
import BookingEngineHeader from '../../components/booking-engine-header/booking-engine-header'
import BookingEngineView from '../../components/booking-engine-view'
import { getCurrentUser } from '../../../aws-cognito/aws-functions';
import Unauthorized from '../../../common/views/unauthorized';
import UserReport from './user-report';

const UserInfo = () => {

    const currentUser = getCurrentUser();
    if (currentUser) {
        return (
            <Fragment>

                <BookingEngineHeader />
                <BookingEngineView>
                    <div className="container">
                        <div className="mt-5">
                            <UserReport />
                        </div>
                    </div>
                </BookingEngineView>
                <BookingEngineFooter />

            </Fragment>
        )
    } else {
        return (
            <Unauthorized />
        )
    }

}

export default UserInfo
