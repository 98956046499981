import {api} from './api';

/** 
 * @function getCountryList
 * @description get all country list
*/
export const getCountryList = () => {
    return api.get('/list/country');
}

