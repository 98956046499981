import * as flightBookingApi from "../../api/flight-booking.api";
import { generateQueryUrl, removeBookingSession } from "../../common/util";
import { SESSIONSTORE } from "../../common/storage";
export const SEARCHING_FLIGHTS = 'SEARCHING_FLIGHTS';
export const INITIALIZING_FLIGHT_BOOKING = 'INITIALIZING_FLIGHT_BOOKING';
export const INITIALIZE_FLIGHT_BOOKING = 'INITIALIZE_FLIGHT_BOOKING';
export const SET_BOOKING_TIMER = 'SET_BOOKING_TIMER';

const changeSearchingFlightStatus = status => {
    return {
        type: SEARCHING_FLIGHTS,
        payload: status
    }
}

const changeFlightBookingInitializeStatus = status => {
    return {
        type: INITIALIZING_FLIGHT_BOOKING,
        payload: status
    }
}

export const initializeFlightBookingAction = (selectedResultData, sellFormItineraries, cb, fcb) => {
    return (dispatch) => {
        dispatch(changeFlightBookingInitializeStatus(true));
        flightBookingApi.sellFromRecomondation({ sellFormItineraries })
            .then((res) => {
                // store initial timer
                SESSIONSTORE.save('bt', '10:00');
                // save result data in storage
                SESSIONSTORE.save('rd', selectedResultData);
                // save sSession in storage
                SESSIONSTORE.save('ss', res.data.sSession);
                // save confirm id
                SESSIONSTORE.save('cid', res.data.confirmId);

                dispatch(changeFlightBookingInitializeStatus(false));
                cb(res.data);
            })
            .catch((err) => {
                dispatch(changeFlightBookingInitializeStatus(false));

                fcb(err.response);

            });
    };
}

export const flightSearchAction = (searchQuearyObj, cb) => {
    return (dispatch) => {
        dispatch(changeSearchingFlightStatus(true));
        SESSIONSTORE.save('squ', generateQueryUrl(searchQuearyObj));
        SESSIONSTORE.save('sqo', searchQuearyObj);
        removeBookingSession();
        // if search is for return type add return flight item for search
        const searchRequestParams = { ...searchQuearyObj, travelItineraries: [...searchQuearyObj.travelItineraries] };

        if (searchRequestParams.tripType === 'return' && searchRequestParams.travelItineraries.length === 1) {
            searchRequestParams.travelItineraries.push({
                boardPoint: searchRequestParams.travelItineraries[0].offPoint,
                departureDate: searchRequestParams.travelItineraries[0].arrivalDate,
                offPoint: searchRequestParams.travelItineraries[0].boardPoint
            });
        }

        // search
        flightBookingApi.flightSearch(searchRequestParams)
            .then((res) => {
                dispatch(changeSearchingFlightStatus(false));
                cb(res.data);
            })
            .catch(() => {
                dispatch(changeSearchingFlightStatus(false));
            });
    };
}
