import axios from 'axios';
import { cookieDomain } from './aws-config';

import {
    awsStorageKeyAccessToken,
    awsStorageKeyIdToken,
    userGroupConfig,
    awsStorageKeyRefreshToken,
    awsClientEndClientID,
    awsClientEndClientSecret,
    awsBaseURL,
    awsRefreshTokenExpireTime
} from './aws-config';
import { authenticateApp } from './api/aws-cognito.api';

const parseJwt = (token) => {
    if (token !== null) {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload)
    }
    else {
        return null
    }
}

function setCookie(name, value, milliseconds) {

    // console.log('cookieDomain', cookieDomain);
    let expires = "";
    if (milliseconds) {
        let date = new Date();

        date.setTime(date.getTime() + (milliseconds));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + ";domain=" + cookieDomain + "; path=/;";
}

function getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}

function eraseCookie(name) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=" + cookieDomain + "; path=/;";
}

/**
 * Get logged in user
 */
function getCurrentUser() {
    const decodedIdToken = parseJwt(getCookie(awsStorageKeyIdToken));
    const decodedAccessToken = parseJwt(getCookie(awsStorageKeyAccessToken));
    const refreshToken = getCookie(awsStorageKeyRefreshToken);

    try {
        if (decodedIdToken && decodedAccessToken && refreshToken) {
            // set group config
            const groupConfig = userGroupConfig.filter(confGroup => (
                decodedIdToken['cognito:groups'].length === confGroup.groups.length &&
                decodedIdToken['cognito:groups'].every(userGroup => (confGroup.groups.includes(userGroup)))
            )).pop();

            const name = decodedIdToken.given_name;

            return {
                name,
                token: {
                    idToken: awsStorageKeyIdToken,
                    accessToken: getCookie(awsStorageKeyAccessToken),
                    refreshToken
                },
                tokenDecoded: {
                    idToken: decodedIdToken,
                    accessToken: decodedAccessToken
                },
                groupConfig
            }
        }
        return null;

    } catch (error) {
        console.log('COGNITO ERR: ', error);
        return null;
    }
}

/**
 * Save access token in storage
 * @param token Access Token 
 */
function setAccessToken(access_token, expires_in) {
    setCookie(awsStorageKeyAccessToken, access_token, expires_in);
}

/**
 * Get access token from storage 
 */
function getAccessToken() {
    return getCookie(awsStorageKeyAccessToken);
}

/**
 * erase access token from storage 
 */
function eraseAccessToken(awsStorageKeyAccessToken) {
    eraseCookie(awsStorageKeyAccessToken);
}

/**
 * Save Refresh token in storage
 * @param token Refresh Token 
 */
function setRefreshToken(refresh_token, expires_in) {
    setCookie(awsStorageKeyRefreshToken, refresh_token, expires_in);
}

/**
 * Get Refresh token from storage 
 */
function getRefreshToken() {
    return getCookie(awsStorageKeyRefreshToken);
}

/**
 * erase Refresh token from storage 
 */
function eraseRefreshToken(awsStorageKeyRefreshToken) {
    eraseCookie(awsStorageKeyRefreshToken);
}

/**
 * Save id token in storage
 * @param token id Token 
 */
function setIdToken(id_token, expires_in) {
    setCookie(awsStorageKeyIdToken, id_token, expires_in);
}

/**
 * Get id token from storage 
 */
function getIdToken() {
    return getCookie(awsStorageKeyIdToken);
}

/**
 * erase id token from storage 
 */
function eraseIdToken(awsStorageKeyIdToken) {
    eraseCookie(awsStorageKeyIdToken);
}

/** 
* renew auth token
*/
function authErrorHandler(api, error) {
    const initialReq = error.config;

    if (!initialReq._retry) {
        initialReq._retry = true;

        // check if the user logged in by using the 'user cookie'
        if (getRefreshToken()) {
            const authorization = btoa(`${awsClientEndClientID}:${awsClientEndClientSecret}`);

            const params = new URLSearchParams();
            params.append('grant_type', 'refresh_token');
            params.append('client_id', awsClientEndClientID);
            params.append('refresh_token', getRefreshToken());
            return axios.post(`${awsBaseURL}/oauth2/token`, params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Basic ' + authorization,
                }
            }).then(res => {
                if (res.status === 200) {
                    setAccessToken(res.data.access_token, awsRefreshTokenExpireTime);
                    setIdToken(res.data.id_token, awsRefreshTokenExpireTime);

                    api.defaults.headers.common['Authorization'] = `Bearer ${getAccessToken(awsStorageKeyAccessToken)}`;
                    return api(initialReq);
                } else {
                    console.log(error);
                }
            })
                .catch(err => {
                    console.log(err);
                })
        } else {
            return authenticateApp().then(res => {
                setAccessToken(res.data.access_token, res.data.expires_in * 60);
                return api(initialReq);
            })
                .catch(err => {
                    console.log(err);
                });
        }
    } else {
        console.log('error');
    }
}

export {
    getCurrentUser,
    setAccessToken,
    getAccessToken,
    eraseAccessToken,
    setIdToken,
    getIdToken,
    eraseIdToken,
    setRefreshToken,
    getRefreshToken,
    eraseRefreshToken,
    authErrorHandler
};
