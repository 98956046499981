import React from 'react';
import BookingEngineHeader from '../../../components/booking-engine-header/booking-engine-header';
import BookingEngineFooter from '../../../components/booking-engine-footer/booking-engine-footer';
import BookingEngineView from '../../../components/booking-engine-view';
import FlightSummaryBox from '../../components/flight-summary-box';
import PassengerDetailsForm from '../../components/passenger-details-form';
import { SESSIONSTORE } from '../../../../common/storage';
import BookingPersonDetailsForm from '../../components/booking-person-details-form';
import { formatCurrency, handleException } from '../../../../common/util';
import { passengerDetails, pricingSolution } from '../../../../api/flight-booking.api';
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import BookingTimer from '../../components/booking-timer/booking-timer';
import { getCurrentUser } from '../../../../aws-cognito/aws-functions'
import { awsSignInUrl, awsSignUpUrl } from '../../../../aws-cognito/aws-config';
import FareRulesModal from '../../components/fare-rules/fare-rules-modal';
class PassengerDetails extends React.Component {

    sSession = SESSIONSTORE.get('ss');
    searchQueryObject = SESSIONSTORE.get('sqo');
    selectedResultData = SESSIONSTORE.get('rd');
    confirmId = SESSIONSTORE.get('cid');
    passengersForm = null;
    bookerForm = null;
    

    constructor(props) {
        super(props);
        this.state = {
            termsAgreed: false,
            proceedLoading: false,
            showFareRules: false
        };
    }

    proceedBookingHandler = (e) => {

        if (this.passengersForm && this.bookerForm) {

            // validate and show errors
            if (!this.passengersForm.isValid && !this.bookerForm.isValid) {
                this.passengersForm.submitForm();
                this.bookerForm.submitForm();
            } else if (!this.passengersForm.isValid) {
                this.passengersForm.submitForm();
            } else if (!this.bookerForm.isValid) {
                this.bookerForm.submitForm();
            } else {
                // valid forms then proceed
                const passengerData = {
                    ...this.bookerForm.values,
                    adults: this.passengersForm.values.passengers.filter(passenger => {
                        return passenger.type === 'Adult'
                    }),
                    children: this.passengersForm.values.passengers.filter(passenger => {
                        return passenger.type === 'Child'
                    }),
                    infants: this.passengersForm.values.passengers.filter(passenger => {
                        return passenger.type === 'Infant'
                    })
                }

                this.setState({ proceedLoading: true });
                // send passenger data
                passengerDetails({
                    confirmId: this.confirmId,
                    travelAgentPhone: process.env.REACT_APP_AGENT_PHONE,
                    travelAgentWebSite: process.env.REACT_APP_AGENT_DOMAIN,
                    bookedUserName: null,
                    ...passengerData,
                    sSession: this.sSession
                })
                    .then(res => {

                        // if spmeone went back from payment page again to this
                        SESSIONSTORE.delete('pnrc');

                        // update sSession
                        SESSIONSTORE.save('ss', res.data.sSession);

                        // update confirm id
                        SESSIONSTORE.save('cid', res.data.confirmId);

                        // get pricing solutions
                        pricingSolution(SESSIONSTORE.get('ss'), SESSIONSTORE.get('cid'))
                            .then(res => {
                                this.setState({ proceedLoading: false });

                                // update sSession
                                SESSIONSTORE.save('ss', res.data.sSession);

                                // update confirm id
                                SESSIONSTORE.save('cid', res.data.confirmId);

                                // save pricing data to session
                                SESSIONSTORE.save('ps', res.data);

                                // redirect to booking payment page
                                this.props.history.push('/booking-engine/flight/booking-payment');
                            })
                            .catch(err => {
                                this.setState({ proceedLoading: false });
                                handleException(err, 'Could not get pricing soltion');
                            });


                    })
                    .catch(err => {
                        handleException(err, 'Could not send passenger deta');
                    });
            }

        }

    }

    render() {

        return (
            <>
                <BookingEngineHeader />
                <BookingEngineView>

                    <div className="container mt-3">
                        <div className="row">

                            <div className="col-12 col-lg-3">
                                <FlightSummaryBox />
                                <BookingTimer />
                            </div>

                            <div className="col-12 col-lg-9">
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="container mb-1">
                                            <div className="row">
                                                <div className="col-12 col-lg-8 sm-text-center">
                                                    <h4 className="card-title col-12 col-lg-8 float-lg-left font-weight-bold px-0"> <i className="col-12 col-lg-1 sm-text-center fa fa-user-o text-primary font-weight-bold px-0"></i> <span className="col-12 col-lg-10 sm-text-center text-primary px-0">Passenger Details</span></h4>
                                                </div>
                                                <div className="col-12 col-lg-4">
                                                    <h6 className="float-lg-right text-muted font-italic text-center">
                                                        {this.searchQueryObject.adults + this.searchQueryObject.children + this.searchQueryObject.infants}
                                            &nbsp; Passenger(s) {this.state.passengersFormIsValid}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container p-0">
                                            <PassengerDetailsForm
                                                adults={this.searchQueryObject.adults}
                                                children={this.searchQueryObject.children}
                                                infants={this.searchQueryObject.infants}
                                                formRendered={formikForm => this.passengersForm = formikForm}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-body">

                                        {getCurrentUser() ?
                                            null
                                            :
                                            <div className="container mb-1">
                                                <div className="row">
                                                    <div className="col-12 col-lg-8 sm-text-center">
                                                        <h4 className="card-title col-12 col-lg-8 float-lg-left font-weight-bold px-0">
                                                            <i className="col-12 col-lg-1 sm-text-center fa fa-phone text-primary font-weight-bold px-0"></i> <span className="col-12 col-lg-10 sm-text-center text-primary px-0">Contact Person Details</span>
                                                        </h4>
                                                    </div>
                                                    <div className="col-12 col-lg-4 sm-text-center mb-3">
                                                        <div className="btn-group-sm float-lg-right">
                                                            <a type="button" className="btn btn-primary px-3" href={awsSignInUrl}>Login</a>
                                                            <a type="button" className="ml-3 btn btn-outline-primary" href={awsSignUpUrl} >Create Account</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className="container">
                                            <BookingPersonDetailsForm adults={this.searchQueryObject.adults} formRendered={formikForm => this.bookerForm = formikForm} />
                                        </div>
                                    </div>
                                </div>

                                <div className="container mt-3">
                                    <div className="row">
                                        <div className="col-12 text-right h2 text-primary sm-text-center">
                                            {this.selectedResultData.currencySupplier} {formatCurrency(this.selectedResultData.totalMarkupFare)}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-9">
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    defaultChecked={this.state.termsAgreed} type="checkbox" className="custom-control-input" id="termscheck"
                                                    onChange={() => this.setState({ termsAgreed: !this.state.termsAgreed })}
                                                />
                                                <label className="custom-control-label" htmlFor="termscheck">
                                                    I have read and agree to the
                                                    <a href="/" className="text-primary" onClick={e => {
                                                        e.preventDefault();
                                                        this.setState({showFareRules : true});
                                                    }}> Fare rules </a>, 
                                                    <a href="https://buddhatravel.com.au/useful-pages/privacy-policy" className="text-primary" target="_blank" rel="noopener noreferrer"> Privacy Policy </a>
                                                    and
                                                    <a href="https://buddhatravel.com.au/useful-pages/terms-and-conditions" className="text-primary" target="_blank" rel="noopener noreferrer"> Terms and Conditions.</a>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-3">
                                            {/* <button disabled={!this.state.termsAgreed} className="btn btn-block btn-primary" onClick={this.proceedBookingHandler}>PROCEED <i className="fa fa-caret-right ml-2"></i></button> */}
                                            <LaddaButton
                                                disabled={!this.state.termsAgreed}
                                                onClick={this.proceedBookingHandler}
                                                type="button"
                                                className="btn btn-primary btn-block"
                                                loading={this.state.proceedLoading}
                                                data-style={SLIDE_UP}
                                            >
                                                PROCEED <i className="fa fa-caret-right ml-2"></i>
                                            </LaddaButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <FareRulesModal
                    show={this.state.showFareRules}
                    handleClose={() => this.setState({showFareRules : false})} />

                </BookingEngineView>
                <BookingEngineFooter footerStyle="compact"/>
            </>
        );
    }
};

export default PassengerDetails;