import React from 'react';
import { SESSIONSTORE } from '../../../../common/storage';
import FlightIcon from '../../../../assets/images/flight-icon.png';
import moment from 'moment';
import './flight-summary-bar.scss';

const FlightSummaryBar = ({ searchData }) => {

    // get airport details from sessionStorage
    const airportNameBroadPoint = SESSIONSTORE.get('anbp');
    const airportNameOffPoint = SESSIONSTORE.get('anop');

    // ui airport trip list
    const uiAirportTripList = searchData.travelItineraries.map((trip, index) => (
        <div key={index} className="align-self-center col-8 col-lg-4 col-auto text-center py-1 px-4">
            <div className="row">
                <div className="p-0 col h5 font-weight-bold">{airportNameBroadPoint ? airportNameBroadPoint.apCityName : trip.boardPoint}</div>
                <div className="p-0 col text-primary"><i className="fa fa-caret-right"></i></div>
                <div className="p-0 col h5 font-weight-bold">{airportNameOffPoint ? airportNameOffPoint.apCityName : trip.offPoint}</div>
            </div>
            <div className="row">
                <div className="mt-2 col-12 text-muted text-left sm-text-center">{moment(trip.departureDate).format("ddd DD MMM")}</div>
            </div>
        </div>
    ));

    // buisiness class text
    let bookingClass = '';
    if (searchData.bookingClass === 'Y') {
        bookingClass = 'Economic';
    } else if (searchData.bookingClass === 'W') {
        bookingClass = 'Economic Premium';
    } else {
        bookingClass = 'Business';
    }

    // trip type text
    let tripType = '';
    if (searchData.tripType === 'oneway') {
        tripType = 'One Way Trip';
    } else if (searchData.tripType === 'return') {
        tripType = 'Return Trip';

        // add return trip to display
        if (searchData.travelItineraries.length === 1) {
            uiAirportTripList.push(
                <div key={1} className="align-self-center col-8 col-lg-4 col-auto text-center py-1 px-4 border-left">
                    <div className="row">
                        <div className="p-0 col h5 font-weight-bold">{airportNameOffPoint ? airportNameOffPoint.apCityName : searchData.travelItineraries[0].offPoint}</div>
                        <div className="p-0 col text-primary"><i className="fa fa-caret-right"></i></div>
                        <div className="p-0 col h5 font-weight-bold">{airportNameBroadPoint ? airportNameBroadPoint.apCityName : searchData.travelItineraries[0].boardPoint}</div>
                    </div>
                    <div className="row">
                        <div className="mt-2 col-12 text-muted text-left sm-text-center">{moment(searchData.travelItineraries[0].arrivalDate).format("ddd DD MMM")}</div>
                    </div>
                </div>
            );
        }

    } else {
        tripType = 'Multicity Trip';
    }

    return (
        <div className="flight-summary-bar">
            <div className="container border-bottom pb-3 pt-2">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-8">
                        <div className="row justify-content-center-m">
                            <div className="col-auto d-none d-lg-block p-0 pr-2">
                                <img style={{ width: '50px', height: '50px' }} className="flight-ico" src={FlightIcon} alt="Flight" />
                            </div>
                            {uiAirportTripList}
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-12 mt-2 mt-lg-0 text-center text-lg-right">
                        <div className="h5 mb-0 font-weight-bold"><span className="">{bookingClass}</span>, {tripType}</div>
                        <div className="h5">{searchData.adults} Adult(s) {searchData.children ? `, ` + searchData.children + `Child(ren)` : null} {searchData.infants ? `, ` + searchData.infants + `Infant(s)` : null} </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlightSummaryBar;