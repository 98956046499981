
import React, { useState, useEffect } from 'react';
import LaddaButton, { SLIDE_UP } from "react-ladda";
import { initPayment } from './anz-api';
import NotifyModal from '../../../common/components/notify-modal';
import anzLogo from "../../../assets/images/anz_pay_logo.png";
import visaMasterLogo from "../../../assets/images/visa-master.png";
import amexLogo from "../../../assets/images/amex.png";

const AnzComponent = (props) => {

    const confirmId = props.confirmId;
    const returnURL = process.env.REACT_APP_BASE + "/booking-engine/flight/booking-success?ipg=anz&confirm_id=" + confirmId;

    const [payLoading, setPayLoading] = useState(false);
    const [notifyError, setNotifyError] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [paymentData, setPaymentData] = useState(null);

    useEffect(() => {
        initPayment(confirmId, returnURL)
            .then((res) => {
                setPaymentData(res.data);
            })
            .catch((err) => {
                setNotifyError(true)
            })

    }, [confirmId, returnURL])

    const paymentConfirmed = () => {
        setShowConfirmation(false);
        paymentProcess();
    };

    // payment process function
    const paymentProcess = () => {
        if (payLoading) return;
        setPayLoading(true);
        setTimeout(() => {
            window.location.href = paymentData.redirectUrl;
        }, 1500);
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 col-sm-8">
                    <div className='text-muted'>Powered by</div>
                    <img className='mt-2' style={{ width: '300px' }} src={anzLogo} alt="Poli logo" />
                </div>
                <div className="col-12 col-sm-4">
                    <div className='text-right'>
                        <h6 className="mt-1 ml-3">We accept</h6>
                        <div className="ml-3">
                            <img
                                src={visaMasterLogo}
                                alt="visa master"
                            />
                            <span className="ml-1">
                                <img
                                    src={amexLogo}
                                    alt="amex"
                                />
                            </span>
                        </div>
                        <LaddaButton
                            type="submit"
                            className="btn btn-primary mt-4 w-100"
                            loading={payLoading}
                            data-style={SLIDE_UP}
                            onClick={() => setShowConfirmation(true)}
                        >
                            PAY
                            <i className="fa fa-caret-right ml-2"></i>
                        </LaddaButton>
                    </div>
                </div>
            </div>

            {
                notifyError ?
                    <NotifyModal
                        type="danger"
                        title="Oops"
                        subTitle="Something Happened"
                        body="failled to connect with the ANZ Worldline"
                    />
                    : null
            }

            {
                paymentData && <NotifyModal
                    type="primary"
                    title="Payment confimation"
                    modalShow={showConfirmation}
                    closeAction={() => setShowConfirmation(false)}
                    body={(
                        <div>
                            <div className="row mb-2">
                                <div className="col-12 text-center">Additional {paymentData.ipgFeeAsPercentage}% Service Fee will be added for the ANZ Worldline payment</div>
                            </div>
                            <div className="row">
                                <div className="col-6 font-weight-bold text-left">Total booking fee <span className="float-right">:</span></div>
                                <div className="col-6 text-right">{(paymentData.finalIpgFeeAddedFare - paymentData.ipgFee).toFixed(2)}</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-6 font-weight-bold text-left">ANZ Worldline service fee <span className="float-right">:</span></div>
                                <div className="col-6 text-right">{(paymentData.ipgFee).toFixed(2)}</div>
                            </div>
                            <div className="row h4">
                                <div className="col-6 font-weight-bold text-left">Total payment <span className="float-right">:</span></div>
                                <div className="col-6 text-right">{(paymentData.finalIpgFeeAddedFare).toFixed(2)}</div>
                            </div>
                        </div>
                    )}
                    confirmAction={paymentConfirmed}
                />
            }
        </div>
    );
}

export default AnzComponent
