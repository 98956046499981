import React from 'react';
import BookingEngineHeader from '../../components/booking-engine-header/booking-engine-header';
import BookingEngineFooter from '../../components/booking-engine-footer/booking-engine-footer';
import BookingEngineView from '../../components/booking-engine-view';

class Register extends React.Component {

    render() {
        return (
            <>
                <BookingEngineHeader/>
                <BookingEngineView>
                    <div className="container mt-3">
                        <div className="row">
                            <div className="col-12 col-sm-5">
                                <div className="card">
                                    <div className="card-body">
                                        <h1 className="text-primary">Register</h1>
                                        <p className="small text-muted">Please fill out all the fields</p>
                                        
                                        Register User

                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6">
                                some content with images goes here
                            </div>
                        </div>
                    </div>
                </BookingEngineView>
                <BookingEngineFooter/>
            </>
        );
    }

};

export default Register;