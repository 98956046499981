import React from "react";
import './filter-airline-stops.scss'

const FilterAirlineStops = ({ filterData, filterChanged }) => {

    const { data, value } = filterData;

    const filterKey = "airlineStops";
    const optionsChecked = value ? value : [];
    const changeEvent = (event) => {

        const checkedArray = optionsChecked;
        const selectedValue = event.target.value;

        if (event.target.checked) {
            checkedArray.push(selectedValue);
        } else {
            let valueIndex = checkedArray.indexOf(selectedValue);
            checkedArray.splice(valueIndex, 1);
        }

        if (checkedArray.length > 0) {
            filterChanged(
                filterKey,
                checkedArray,
                null
            )
        } else {
            filterChanged(
                filterKey,
                null,
                null
            )
        }
    }

    const labels = {
        D: "Direct",
        S1: "1 stop",
        S2: "1+ stop"
    };

    if (data) {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="airlineStops col-12">
                            <div className="font-weight-bold">
                                Filter result by flight type
                            </div>

                            {data.map((type, index) => (
                                <div key={index}>
                                    <div className="custom-checkbox">
                                        <label htmlFor={type} className="ml-2">
                                            <input id={type} type="checkbox"
                                                value={type}
                                                onChange={changeEvent}
                                                checked={optionsChecked.indexOf(type) !== -1 ? true : false}
                                            />
                                            <span></span>
                                            {labels[type] ? labels[type] : type}
                                        </label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default FilterAirlineStops;
