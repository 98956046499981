import React, { Fragment, useState } from 'react';
import Switch from "react-switch";
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import { ToastContainer, toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { deleteAirline, airlineStatus } from '../../../api/admin-api';
import ModalFareType from '../modal-faretype/modal-faretype';
import ModalPromo from '../modal-promo/modal-promo';
import ModalRestrict from '../modal-restrict/modal-restrict';
import 'react-toastify/dist/ReactToastify.css';
import './fare-item.scss';

const airlineLogos = require.context('../../../../assets/images/airlines', true);

const FareItem = ({ fare, updates, modalChangers, modalFails, modalNoChange }) => {

    // notification when successfull attempt of delete a airline
    const notifyAirlineDeleteSuccess = (airlineCode) => toast.warn(`successfully deleted the ${airlineCode} airline!`, {
        containerId: 'notifyAirlineDeleteSuccess',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
    });

    // notification when fail attempt of deleting a airline
    const notifyAirlineDeleteFailed = () => toast.error('Oops....Something went wrong. Try again!', {
        containerId: 'notifyAirlineDeleteFailed',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
    });

    // status of switch button
    const handleSwitch = (switchChecked) => {
        handleAirlineStatus(fare.airlineCode, switchChecked);
    };

    // notification when fail attempt of deleting a airline
    const notifyAirlineDeactivateSuccess = (airlineCode, status) => toast.warn(`successfully ${status ? 'reactivated' : 'deactivated'} the ${airlineCode} airline!`, {
        containerId: 'notifyAirlineDeactivateSuccess',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
    });

    // active inactive sirline status api call
    const [airlineState, setAirlineState] = useState(fare.activeStatus);
    const handleAirlineStatus = (code, status) => {
        airlineStatus(code, status).then(res => {
            setAirlineState(status);
            notifyAirlineDeactivateSuccess(code, status);
        }).catch(error => {
            console.log(error);
            notifyAirlineDeleteFailed();
        })
    };

    // delete button loading
    const [buttonLoading, setButtonLoading] = useState(false);

    // intiialize delete airline api call
    const handleDelete = () => {

        setShowConfirm(true)
    };

    // delete airline api call
    const handleDeleteConfirm = () => {
        setButtonLoading(true);
        deleteAirline(fare.airlineCode).then(res => {
            setShowConfirm(false)
            updates();
            notifyAirlineDeleteSuccess(fare.airlineCode);
        })
            .catch(error => {
                notifyAirlineDeleteFailed();
            })
    }

    // promotion modal
    const [showPromo, setShowPromo] = useState(false);
    const handleShowPromo = () => {
        setShowPromo(true);
    };

    // restrict modal
    const [showRestrict, setShowRestrict] = useState(false);
    const handleShowRestrict = () => {
        setShowRestrict(true);
    };

    // confirm modal show
    const [showConfirm, setShowConfirm] = useState(false);
    const handleCloseConfirm = () => setShowConfirm(false);

    // faretype modal
    const [showFareType, setShowFareType] = useState(false);
    const [category, setCategory] = useState('');
    const [categoryDisplay, setCategoryDisplay] = useState('');
    const [hideMarkup, setHideMarkup] = useState(false);
    const handleShowFareType = (displayName, categoryName, hideMarkup) => {
        setShowFareType(true);
        setCategoryDisplay(displayName);
        setCategory(categoryName);
        setHideMarkup(hideMarkup);
    };

    // airline logos
    let airlineLogo;
    try {
        airlineLogo = airlineLogos(`./${fare.airlineCode}.png`).default;
    } catch (error) {
        airlineLogo = airlineLogos(`./default.png`).default;
    }

    if (fare === []) {
        return (
            // when data deleted 
            <div>No Data Available</div>
        )
    } else {
        return (
            <Fragment>
                {
                    showConfirm ?
                        <Modal size="sm" className="fare-item-delete-confirm-modal" show={showConfirm} onHide={handleCloseConfirm} centered>
                            <Modal.Header closeButton>
                                <Modal.Title className="title text-uppercase">Delete <span>{fare.airlineName}</span> </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="text-center text-uppercase">
                                Are you sure ?
                            </Modal.Body>
                            <Modal.Footer className="d-flex justify-content-around confirm-modal-agent">
                                <LaddaButton
                                    type="submit"
                                    className="btn btn-secondary"
                                    loading={buttonLoading}
                                    data-style={SLIDE_UP}
                                    onClick={() => { handleDeleteConfirm() }}
                                >
                                    Delete
                                </LaddaButton>
                                <button className="btn" onClick={handleCloseConfirm} >Cancel</button>
                            </Modal.Footer>
                        </Modal>
                        :
                        null
                }

                <div className="card mt-3 mb-5" id="card-body" key={fare.airlineCode}>
                    <div className="card-body">
                        <div className="row" id={fare.airlineCode}>

                            <div className={"col-lg-2 col-sm-12 text-center border-right " + (airlineState ? "" : "switch-off")}>
                                <h5 className="mt-5 text-center">{fare.airlineName} </h5>
                                <h4>( {fare.airlineCode} ) </h4>
                                <img src={airlineLogo} alt="logo" style={{ width: '50%' }} className="img-fluid mt-3" />
                            </div>

                            <div className="col-lg-10">
                                <div className="row mr-auto">
                                    <div className="col-12">
                                        <div className="row ">
                                            <div className="w-100 ">
                                                <div className={"d-inline-block " + (airlineState ? "" : "switch-off")}>
                                                    <h6 className="m-2 pl-3 pl-lg-0 text-sm-right">Last modified on : {fare.datetimeLastModified.replace('T', ', at ')} </h6>
                                                </div>

                                                <div className="d-inline-block float-right mr-5">
                                                    <div className="row">
                                                        <Switch
                                                            checked={airlineState}
                                                            onChange={handleSwitch}
                                                            onColor="#86d3ff"
                                                            onHandleColor="#2693e6"
                                                            handleDiameter={25}
                                                            uncheckedIcon={false}
                                                            checkedIcon={false}
                                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                            height={12}
                                                            width={40}
                                                            className="react-switch mr-3 mt-1"
                                                            id="material-switch"
                                                        />
                                                        <LaddaButton
                                                            type="submit"
                                                            className="btn btn-sm btn-danger mb-1 mt-1"
                                                            loading={buttonLoading}
                                                            data-style={SLIDE_UP}
                                                            onClick={handleDelete}
                                                        >
                                                            <i className="fa fa-trash-o"
                                                                aria-hidden="true"></i>
                                                        </LaddaButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-lg-12 col-sm-12 text-center " + (airlineState ? "" : "switch-off")}>
                                        <div className="row border-top">
                                            <div className="resultContainer col-lg-2 col-sm-12 border-right">
                                                <div className="mt-2 mb-2 border-bottom">
                                                    <h6>International</h6>
                                                </div>

                                                <div className=" d-flex justify-content-center">
                                                    {
                                                        fare.intFareDetails ?
                                                            <div>
                                                                <div>
                                                                    <h6>General</h6>
                                                                    <p>Discount : {fare.intFareDetails.discount}</p>
                                                                    <p>Mark-Up : {fare.intFareDetails.markup}</p>
                                                                </div>
                                                                <div>
                                                                    <h6>Periodical</h6>
                                                                    {
                                                                        fare.intFareDetails.activeStatus ?
                                                                            <div>
                                                                                <p>Discount : {fare.intFareDetails.discountPeriod}</p>
                                                                                <p>Mark-Up : {fare.intFareDetails.markupPeriod}</p>
                                                                            </div>
                                                                            :
                                                                            <p>Not Available</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            <p>Not Available</p>
                                                    }
                                                    <div className="viewThisResult w-100">
                                                        <LaddaButton
                                                            type="submit"
                                                            className="btn btn-primary btn-block"
                                                            data-style={SLIDE_UP}
                                                            onClick={() => handleShowFareType('International', 'intFareDetails', false)}
                                                        >
                                                            Edit <i className="fa fa-caret-right ml-2"></i>
                                                        </LaddaButton>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="resultContainer col-lg-2 col-sm-12 border-right">
                                                <div className="mt-2 mb-2 border-bottom">
                                                    <h6>Domestic</h6>
                                                </div>

                                                <div className=" d-flex justify-content-center">
                                                    {
                                                        fare.domFareDetails ?
                                                            <div>
                                                                <div>
                                                                    <h6>General</h6>
                                                                    <p>Discount : {fare.domFareDetails.discount}</p>
                                                                    <p>Mark-Up : {fare.domFareDetails.markup}</p>
                                                                </div>
                                                                <div>
                                                                    <h6>Periodical</h6>
                                                                    {
                                                                        fare.domFareDetails.activeStatus ?
                                                                            <div>
                                                                                <p>Discount : {fare.domFareDetails.discountPeriod}</p>
                                                                                <p>Mark-Up : {fare.domFareDetails.markupPeriod}</p>
                                                                            </div>
                                                                            :
                                                                            <p>Not Available</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            <p>Not Available</p>
                                                    }
                                                    <div className="viewThisResult w-100">
                                                        <LaddaButton
                                                            type="submit"
                                                            className="btn btn-primary btn-block"
                                                            data-style={SLIDE_UP}
                                                            onClick={() => handleShowFareType('Domestic', 'domFareDetails', false)}
                                                        >
                                                            Edit <i className="fa fa-caret-right ml-2"></i>
                                                        </LaddaButton>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="resultContainer col-lg-2 col-sm-12 border-right">
                                                <div className="mt-2 mb-2 border-bottom">
                                                    <h6>Sector</h6>
                                                </div>

                                                <div className=" d-flex justify-content-center">
                                                    {
                                                        fare.secFareDetails ?
                                                            <div>
                                                                <div>
                                                                    <h6>General</h6>
                                                                    <p>Discount : {fare.secFareDetails.discount}</p>
                                                                    <p>Mark-Up : {fare.secFareDetails.markup}</p>
                                                                </div>
                                                                <div>
                                                                    <h6>Periodical</h6>
                                                                    {
                                                                        fare.secFareDetails.activeStatus ?
                                                                            <div>
                                                                                <p>Discount : {fare.secFareDetails.discountPeriod}</p>
                                                                                <p>Mark-Up : {fare.secFareDetails.markupPeriod}</p>
                                                                            </div>
                                                                            :
                                                                            <p>Not Available</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            <p>Not Available</p>
                                                    }
                                                    <div className="viewThisResult w-100">
                                                        <LaddaButton
                                                            type="submit"
                                                            className="btn btn-primary btn-block"
                                                            data-style={SLIDE_UP}
                                                            onClick={() => handleShowFareType('Sector', 'secFareDetails', false)}
                                                        >
                                                            Edit <i className="fa fa-caret-right ml-2"></i>
                                                        </LaddaButton>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="resultContainer col-lg-1 col-sm-12 border-right">
                                                <div className="mt-2 mb-2 border-bottom">
                                                    <h6>Reverse</h6>
                                                </div>

                                                <div className="d-flex justify-content-center">
                                                    {
                                                        fare.rvsFareDetails ?
                                                            <div>
                                                                <div>
                                                                    <h6>General</h6>
                                                                    <p>Discount : {fare.rvsFareDetails.discount}</p>
                                                                    <p>Mark-Up : {fare.rvsFareDetails.markup}</p>
                                                                </div>
                                                                <div>
                                                                    <h6>Periodical</h6>
                                                                    {
                                                                        fare.rvsFareDetails.activeStatus ?
                                                                            <div>
                                                                                <p>Discount : {fare.rvsFareDetails.discountPeriod}</p>
                                                                                <p>Mark-Up : {fare.rvsFareDetails.markupPeriod}</p>
                                                                            </div>
                                                                            :
                                                                            <p>Not Available</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            <p>Not Available</p>
                                                    }
                                                    <div className="viewThisResult w-100">
                                                        <LaddaButton
                                                            type="submit"
                                                            className="btn btn-primary btn-block"
                                                            data-style={SLIDE_UP}
                                                            onClick={() => handleShowFareType('Reverse', 'rvsFareDetails', false)}
                                                        >
                                                            Edit <i className="fa fa-caret-right ml-2"></i>
                                                        </LaddaButton>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="resultContainer col-lg-1 col-sm-12 border-right">
                                                <div className="mt-2 mb-2 border-bottom">
                                                    <h6>YQ Tax</h6>
                                                </div>

                                                <div className="d-flex justify-content-center">
                                                    {
                                                        fare.yqrFareDetails ?
                                                            <div>
                                                                <div>
                                                                    <h6>General</h6>
                                                                    <p>Discount : {fare.yqrFareDetails.discount}</p>
                                                                </div>
                                                                <div>
                                                                    <h6>Periodical</h6>
                                                                    {
                                                                        fare.yqrFareDetails.activeStatus ?
                                                                            <div>
                                                                                <p>Discount : {fare.yqrFareDetails.discountPeriod}</p>
                                                                            </div>
                                                                            :
                                                                            <p>Not Available</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            <p>Not Available</p>
                                                    }
                                                    <div className="viewThisResult w-100">
                                                        <LaddaButton
                                                            type="submit"
                                                            className="btn btn-primary btn-block"
                                                            data-style={SLIDE_UP}
                                                            onClick={() => handleShowFareType('YQ Tax', 'yqrFareDetails', true)}
                                                        >
                                                            Edit <i className="fa fa-caret-right ml-2"></i>
                                                        </LaddaButton>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="resultContainer col-lg-2 col-sm-12 border-right">
                                                <div className="mt-2 mb-2 border-bottom">
                                                    <h6>Restrict</h6>
                                                </div>

                                                <div className="d-flex justify-content-center">

                                                    {fare.restrictAllAirport ?
                                                        <div className="mt-0 mt-lg-5">Restrict All Airports</div>
                                                        :
                                                        fare.fareManageRestrictAirportDTOs.length > 0 ?
                                                            <div>
                                                                {
                                                                    fare.fareManageRestrictAirportDTOs.map(promo => {
                                                                        return (
                                                                            <p key={promo.id}>{promo.airportName}</p>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            :
                                                            <h6 className="mt-0 mt-lg-5">Not Available</h6>
                                                    }
                                                    <div className="viewThisResult w-100">
                                                        <LaddaButton
                                                            type="submit"
                                                            className="btn btn-primary btn-block"
                                                            data-style={SLIDE_UP}
                                                            onClick={handleShowRestrict}
                                                        >
                                                            Edit <i className="fa fa-caret-right ml-2"></i>
                                                        </LaddaButton>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="resultContainer col-lg-2 col-sm-12">
                                                <div className="mt-2 mb-2 border-bottom ">
                                                    <h6>Promote</h6>
                                                </div>

                                                <div className="d-flex justify-content-center">
                                                    {
                                                        fare.fareManagePushAirportDTOs.length > 0 ?
                                                            <div>
                                                                {fare.fareManagePushAirportDTOs.map(promo => {
                                                                    return <p key={promo.id}>{promo.airportName}</p>
                                                                })}
                                                            </div>
                                                            :
                                                            <h6 className="mt-0 mt-lg-5">Not Available</h6>
                                                    }
                                                    <div className="viewThisResult w-100" >
                                                        <LaddaButton
                                                            type="submit"
                                                            className="btn btn-primary btn-block"
                                                            data-style={SLIDE_UP}
                                                            onClick={handleShowPromo}
                                                        >
                                                            Edit <i className="fa fa-caret-right ml-2"></i>
                                                        </LaddaButton>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* modal for International, Domestic, Sector, Reverse , YQ Tax */}
                    {category ?
                        <ModalFareType
                            setShow={showFareType}
                            handleClose={() => setShowFareType(false)}
                            airlineName={fare.airlineName}
                            category={category}
                            categoryDisplay={categoryDisplay}
                            airlineCode={fare.airlineCode}
                            data={fare[category]}
                            updates={updates}
                            modalChangers={modalChangers}
                            modalFails={modalFails}
                            modalNoChange={modalNoChange}
                            hideMarkup={hideMarkup}
                        />
                        : null}

                    {showRestrict ?
                        <ModalRestrict
                            setShow={showRestrict}
                            handleClose={() => setShowRestrict(false)}
                            airlineCode={fare.airlineCode}
                            airlineName={fare.airlineName}
                            restrictList={fare.fareManageRestrictAirportDTOs}
                            data={fare}
                            updates={updates}
                            modalChangers={modalChangers}
                            modalFails={modalFails}
                            modalNoChange={modalNoChange}
                        />
                        : null}

                    {showPromo ?
                        <ModalPromo
                            setShow={showPromo}
                            handleClose={() => setShowPromo(false)}
                            airlineName={fare.airlineName}
                            airlineCode={fare.airlineCode}
                            promoList={fare.fareManagePushAirportDTOs}
                            updates={updates}
                            modalChangers={modalChangers}
                            modalFails={modalFails}
                            modalNoChange={modalNoChange}
                        />
                        : null}

                    <ToastContainer enableMultiContainer containerId={'notifyAirlineDeleteSuccess'} position={toast.POSITION.BOTTOM_RIGHT} />
                    <ToastContainer enableMultiContainer containerId={'notifyAirlineDeleteFailed'} position={toast.POSITION.BOTTOM_RIGHT} />
                    <ToastContainer enableMultiContainer containerId={'notifyAirlineDeactivateSuccess'} position={toast.POSITION.BOTTOM_RIGHT} />
                </div>
            </Fragment>

        )
    }
}

export default FareItem;
