import React from 'react';
import './search-result-item.scss';
import imgAirplaneFlight from '../../../../assets/images/airplane-flight.png';
import imgSplitArrow from '../../../../assets/images/split-arrow.png';
import { formatCurrency } from '../../../../common/util';
import FareRulesModal from '../fare-rules/fare-rules-modal';
import FlightDetailsModal from './flight-details-modal';
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import * as moment from 'moment';
import { SESSIONSTORE } from '../../../../common/storage';
const airlineLogos = require.context('../../../../assets/images/airlines', true);

const SearchResultItem = ({ searchQuery, resultData, onClickBookNow, disableBookingButton, notifyError }) => {

    const [showFareRules, setShowFareRules] = React.useState(false);
    const [showFlightDetails, setFlightDetails] = React.useState(false);
    const [buttonLoading, setButtonLoading] = React.useState(false);

    // create data object to request pricing details to view fare rules
    let flightPricingDetails = {
        adults: searchQuery.adults,
        children: searchQuery.children,
        infants: searchQuery.infants,
        totalBaseFare: resultData.totalBaseFare,
        currencySupplier: resultData.currencySupplier,
        segmentInformations: []
    };

    const handleShowFareRules = () => {
        setShowFareRules(true);
    }

    const handleShowFlightDetails = () => {
        setFlightDetails(true);
    }

    const handleBookNow = () => {
        //  store pricing details cache to use later in fare rules requests
        SESSIONSTORE.save('cfpd', flightPricingDetails);
        setButtonLoading(true);
        setFlightDetails(false);
        onClickBookNow(resultData);
    }

    // ui for flight trip list in result
    const uiFlightTripList = [];
    let flightItemNo = 0;
    for (const tripKey in resultData.flightSliceMap) {

        if (resultData.flightSliceMap.hasOwnProperty(tripKey)) {
            const flightsInTrip = resultData.flightSliceMap[tripKey].flightItineraries;
            const tripDuration = resultData.flightSliceMap[tripKey].sliceDurationTxt;
            const startingFlight = flightsInTrip[0];
            const lastFlight = [...flightsInTrip].pop();

            const startingDate = moment(startingFlight.datetimeOfDeparture).format('YYYY-MM-DD');
            const startingTime = moment(startingFlight.datetimeOfDeparture).format('HH:mm');

            const endDate = moment(lastFlight.datetimeOfArrival).format('YYYY-MM-DD');
            const endingTime = moment(lastFlight.datetimeOfArrival).format('HH:mm');

            const tripStopsCount = flightsInTrip.reduce((totalCount, trip) => {
                return trip.technicalStop ? totalCount += trip.technicalStop.stopDetails.length : totalCount;
            }, flightsInTrip.length - 1); // - 1 for ignore last landing airport stop

            uiFlightTripList.push(<FlightTrip key={tripKey}
                startAirport={startingFlight.boardPoint}
                startDate={startingDate}
                startTime={startingTime}
                endAirport={lastFlight.offPoint}
                endDate={endDate}
                endTime={endingTime}
                duration={tripDuration}
                stopsCount={tripStopsCount}
                airline={startingFlight.marketingCarrier}
                airlineName={startingFlight.marketingCarrierName}
                flightNo={startingFlight.flightOrtrainNumber}
            />);

            // set flight data to view fare rules
            flightPricingDetails.segmentInformations = flightPricingDetails.segmentInformations.concat(
                // eslint-disable-next-line
                flightsInTrip.map(tripItem => {
                    flightItemNo++;
                    return {
                        datetimeOfDeparture: tripItem.datetimeOfDeparture,
                        boardPoint: tripItem.boardPoint,
                        offPoint: tripItem.offPoint,
                        marketingCarrier: tripItem.marketingCarrier,
                        operatingCarrier: tripItem.operatingCarrier,
                        flightOrtrainNumber: tripItem.flightOrtrainNumber,
                        rbd: tripItem.rbd,
                        flightIndicator: tripKey,
                        itemNumber: flightItemNo
                    };
                })
            );

        }
    }

    return (
        <div className="flight-search-result-item">
            <div className="card mb-3 ">
                <div className="card-body">

                    {uiFlightTripList}

                    <div className="row">
                        <div className="col-12 col-lg-4 pt-2 text-lg-left text-right btn-group" id="view-details">
                            <button onClick={handleShowFlightDetails} className="btn btn-link btn-sm font-weight-bold"><i className="fa fa-info-circle fa-lg primary mr-2"></i> View Details</button>
                            <button onClick={handleShowFareRules} className="btn btn-link btn-sm font-weight-bold"><i className="fa fa-info-circle fa-lg primary mr-2"></i> View Fare Rules</button>
                        </div>
                        <div className="col-12 col-lg-5 sm-text-center text-right text-info h3 font-weight-bold">
                            {resultData.currencySupplier} {formatCurrency(resultData.totalMarkupFare)}
                        </div>
                        <div className="col-12 col-lg-3">
                            <LaddaButton
                                type="submit"
                                className="btn btn-info btn-block font-weight-bold"
                                loading={buttonLoading && !notifyError}
                                data-style={SLIDE_UP}
                                onClick={handleBookNow}
                                disabled={disableBookingButton}
                            >
                                BOOK NOW <i className="fa fa-caret-right ml-2"></i>
                            </LaddaButton>
                        </div>
                    </div>

                </div>

                <FareRulesModal
                    show={showFareRules}
                    flightPricingDetails={flightPricingDetails}
                    handleClose={() => setShowFareRules(false)} />

                <FlightDetailsModal
                    show={showFlightDetails}
                    resultData={resultData}
                    handleBookNow={handleBookNow}
                    handleClose={() => setFlightDetails(false)} />

            </div>
        </div>
    );
};

const FlightTrip = ({
    startAirport,
    startDate,
    startTime,
    endAirport,
    endDate,
    endTime,
    duration,
    stopsCount,
    airline,
    airlineName,
    flightNo
}) => {
    let airlineLogo;
    try {
        airlineLogo = airlineLogos(`./${airline}.png`);
    } catch (error) {
        airlineLogo = airlineLogos(`./default.png`);
    }

    return (
        <div className="row mb-3">
            <div className="col-12 col-lg-2 col-sm-2 sm-text-center">
                <img className="airline-logo" src={airlineLogo} alt="Flight" />
                <div className="col-12 px-0 d-inline d-sm-none ml-3"><span className="text-uppercase font-weight-bold mr-2">{airlineName}</span> <span className="font-italic">: {flightNo}</span> </div>
            </div>
            <div className="col-12 col-lg-10 col-sm-10 sm-text-center">
                <div className="row"> <div className="col-12 px-0 d-none d-sm-block"><span className="text-uppercase font-weight-bold mr-2">{airlineName}</span> <span className="font-italic">: {flightNo}</span> </div></div>
                <div className="row mt-2">
                    <div className="col-12 col-lg-4 col-sm-3">
                        <div className="row">
                            <div className="col-lg-6 p-0 pr-lg-1 text-right sm-text-center flight-time font-weight-bold">
                                <img className="flight-icon d-lg-inline-block float-left d-none flight-icon " src={imgAirplaneFlight} alt="flight-icon" />
                                <div className="d-none d-sm-block">{moment(startTime, 'hh:mm').format('LT') }</div>
                            </div>
                            <div className="col-lg-6 sm-text-center">
                                <div className="font-weight-bold d-inline d-sm-block">{startAirport}</div>
                                <div className="d-inline d-sm-none flight-time font-weight-bold mx-3">{moment(startTime, 'hh:mm').format('LT') }</div>
                                <div className="text-muted d-inline d-sm-block">{moment(startDate).format("DD MMM YYYY")}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-sm-6 small sm-text-center text-center time-range mt-3">
                        <div className="d-inline d-sm-block">{duration}</div> <div className="d-inline d-sm-none ml-3">{stopsCount > 0 ? stopsCount + ' stop(s)' : 'Non-stop'}</div>
                        <img className="w-100" src={imgSplitArrow} alt="split" />
                        <div className="d-none d-sm-block">{stopsCount > 0 ? stopsCount + ' stop(s)' : 'Non-stop'}</div>
                    </div>
                    <div className="col-12 col-lg-4 col-sm-3">
                        <div className="row">
                            <div className="col-lg-5 p-0 pr-lg-1 text-right sm-text-center flight-time font-weight-bold">
                            <div className="d-none d-sm-block">{moment(endTime, 'hh:mm').format('LT') }</div>
                            </div>
                            <div className="col-lg-7 sm-text-center">
                                <div className="font-weight-bold d-inline d-sm-block">{endAirport}</div>
                                <div className="d-inline d-sm-none flight-time font-weight-bold mx-3">{moment(endTime, 'hh:mm').format('LT') }</div>
                                <div className="text-muted d-inline d-sm-block">{moment(endDate).format("DD MMM YYYY")}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchResultItem;