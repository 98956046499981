import React from 'react';
import { awsStorageKeyIdToken, awsStorageKeyAccessToken, awsStorageKeyRefreshToken, appRootURL } from '../aws-config';
import { eraseAccessToken, eraseIdToken, eraseRefreshToken } from '../aws-functions';
import UiLoader from './ui-loader/ui-loader';

const Signout = (props) => {

    eraseIdToken(awsStorageKeyIdToken);
    eraseAccessToken(awsStorageKeyAccessToken);
    eraseRefreshToken(awsStorageKeyRefreshToken);

    // props.history.push({
    //     pathname: '/'
    // })
    window.location.href = appRootURL

    return (
        <div>
            <UiLoader />
        </div>
    )
}

export default Signout
