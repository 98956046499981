import React from 'react'

const FareRuleMsg = (props) => {
    if (props.type === 'refund') {
        return (
            <ul>
                {
                    Object.keys(props.data).length > 0
                        ?
                        (props.data.BDA && props.data.ADA && props.data.BNA) || props.data.ANA
                            ?
                            <li>
                                Refund
                                {props.data.BDA === '1' ? ' before' : null}
                                {props.data.BDA === '1' && props.data.ADA === '1' ? '/' : null}
                                {props.data.ADA === '1' ? 'after departure ' : null}
                                {props.data.BDA === '1' || props.data.ADA === '1' ? <span>including failure to show at</span> : null}
                                {props.data.BNA === '1' ? ' first' : null}
                                {props.data.BNA === '1' && props.data.ANA === '1' ? '/' : null}
                                {props.data.ANA === '1' ? 'subsequence flight(s)' : null}
                            : <b> Allowed with restrictions </b>
                                <ul>
                                    <li>Prior to Departure of journey, penalty fee reissue:
                                        <b>{props.data.BDT ? <span> {props.data.BDT === 'AUD 0.00' ? 'Free of charge' : props.data.BDT} </span> : <span> Not allowed </span>}</b>
                                    </li>
                                    <li>Failure to show at first flight, penalty fee reissue:
                                        <b>{props.data.BNT ? <span> {props.data.BNT === 'AUD 0.00' ? 'Free of charge' : props.data.BNT} </span> : <span> Not allowed </span>}</b>
                                    </li>
                                    <li>After Departure of journey, penalty fee reissue:
                                        <b> {props.data.ADT ? <span> {props.data.ADT === 'AUD 0.00' ? 'Free of charge' : props.data.ADT} </span> : <span> Not allowed </span>} </b>
                                    </li>
                                    <li> Failure to show subsequence flight(s), penalty fee reissue:
                                         <b> {props.data.ANT ? <span> {props.data.ANT === 'AUD 0.00' ? 'Free of charge' : props.data.ANT} </span> : <span> Not allowed </span>} </b>
                                    </li>
                                </ul>
                            </li>
                            :
                            <li>
                                Refund before/after departure, including failure to show at first/subsequence flight(s): <b> Not allowed </b>
                            </li>
                        :
                        <li>
                            Refund before/after departure, including failure to show at first/subsequence flight(s): <b> Not allowed </b>
                        </li>
                }

            </ul>
        )
    } else if (props.type === 'reissue') {
        return (
            <ul>
                {
                    Object.keys(props.data).length > 0
                        ?
                        (props.data.BDA && props.data.ADA && props.data.BNA) || props.data.ANA
                            ?
                            <li>
                                Reissue
                            {props.data.BDA === '1' ? ' before' : null}
                                {props.data.BDA === '1' && props.data.ADA === '1' ? '/' : null}
                                {props.data.ADA === '1' ? 'after departure ' : null}
                                {props.data.BDA === '1' || props.data.ADA === '1' ? <span>including failure to show at</span> : null}
                                {props.data.BNA === '1' ? ' first' : null}
                                {props.data.BNA === '1' && props.data.ANA === '1' ? '/' : null}
                                {props.data.ANA === '1' ? 'subsequence flight(s)' : null}
                            : <b> Allowed with restrictions</b>
                                <ul>
                                    <li>Prior to Departure of journey, penalty fee reissue:
                                        <b>{props.data.BDT ? <span> {props.data.BDT === 'AUD 0.00' ? 'Free of charge' : props.data.BDT} </span> : <span> Not allowed </span>}</b>
                                    </li>
                                    <li>Failure to show at first flight, penalty fee reissue:
                                        <b>{props.data.BNT ? <span> {props.data.BNT === 'AUD 0.00' ? 'Free of charge' : props.data.BNT} </span> : <span> Not allowed </span>}</b>
                                    </li>
                                    <li>After Departure of journey, penalty fee reissue:
                                        <b> {props.data.ADT ? <span> {props.data.ADT === 'AUD 0.00' ? 'Free of charge' : props.data.ADT} </span> : <span> Not allowed </span>} </b>
                                    </li>
                                    <li> Failure to show subsequence flight(s), penalty fee reissue:
                                        <b> {props.data.ANT ? <span> {props.data.ANT === 'AUD 0.00' ? 'Free of charge' : props.data.ANT} </span> : <span> Not allowed </span>} </b>
                                    </li>
                                </ul>
                            </li>
                            :
                            <li>
                                Refund before/after departure, including failure to show at first/subsequence flight(s): <b> Not allowed </b>
                            </li>
                        :
                        <li>
                            Reissue before/after departure, including failure to show at first/subsequence flight(s): <b> Not allowed </b>
                        </li>
                }

            </ul>
        )
    } else {
        return null
    }
}

export default FareRuleMsg
