import React from "react";
import BookingEngineHeader from "../../../components/booking-engine-header/booking-engine-header";
import BookingEngineFooter from "../../../components/booking-engine-footer/booking-engine-footer";
import BookingEngineView from "../../../components/booking-engine-view";
import FlightSummaryBar from "../../components/flight-summary-bar/flight-summary-bar";
import SearchResultItem from "../../components/search-result-item/search-result-item";
import { Dropdown, Modal } from "react-bootstrap";
import {
    extractQueryUrl,
    generateQueryUrl,
    chunkArray,
    handleException
} from "../../../../common/util";
import FlightSearchWidgetSub from "../../components/flight-search-widget-sub/flight-search-widget-sub";
import { connect } from "react-redux";
import {
    flightSearchAction,
    initializeFlightBookingAction
} from "../../../../store/actions/flight-booking.actions";
import Pagination from "react-js-pagination";
import FilterAirlineCodes from "../../components/filter-airline-codes/filter-airline-codes";
import FilterAirlineStops from "../../components/filter-airline-stops/filter-airline-stops";
import NotifyModal from '../../../../common/components/notify-modal';
import SearchLoader from '../../components/search-loader/search-loader';
import './search-result.scss';

class SearchResult extends React.Component {
    // searchQuery;

    constructor(props) {
        super(props);
        this.state = {
            searchResult: [],
            activePage: 1,
            activePageSearchResult: [],
            resultsPerPage: 10,
            showSearchWidget: false,
            searchQuery: extractQueryUrl(
                this.props.location.search.replace("?", "")
            ),
            searchFilters: {
                airlineStops: {
                    data: null,
                    value: null,
                    valueData: null
                },
                airlineCodes: {
                    data: null,
                    value: null, // ["code", "code"]
                    valueData: null // [{id,name,}, {...}]
                }
            },
            notifyError: null,
            sortBy: 'Price Low To High'
        };
    }
    componentDidMount() {
        this.flightSearch();
    }

    /**
     * @function handleSearchSumbit
     * @description Handle search widget submition
     */
    handleSearchSumbit = searchQuaryObject => {
        // this.state.searchQuery = searchQuaryObject;
        // this.setState({searchQuery : searchQuaryObject});
        this.handleSearchWidgetClose();
        this.setState(
            {
                searchQuery: {
                    adults: searchQuaryObject.adults,
                    bookingClass: searchQuaryObject.bookingClass,
                    children: searchQuaryObject.children,
                    flightType: searchQuaryObject.flightType,
                    infants: searchQuaryObject.infants,
                    tripType: searchQuaryObject.tripType,
                    travelItineraries: searchQuaryObject.travelItineraries
                }
            },
            () => {
                this.props.history.push({
                    pathname: "/booking-engine/flight/search",
                    search: "?" + generateQueryUrl(searchQuaryObject)
                });

                this.flightSearch();
            }
        );
    };


    /**
     * @function flightSearch
     * @description Search flight availability
     */
    flightSearch = () => {
        if (!this.props.flightSearchLoading && !this.props.appAuthenticating) {

            // set filter values for searchk
            const flightFilter = {
                airlineCodes: this.state.searchFilters.airlineCodes.value,
                airlineStops: this.state.searchFilters.airlineStops.value
            };

            // search
            this.props.flightSearchAction(
                { ...this.state.searchQuery, flightFilter },
                res => {
                    this.setState({
                        // set search result data
                        activePage: 1,
                        searchResult: res.flightRecommendations,
                        activePageSearchResult:
                            res.flightRecommendations.length > 0
                                ? chunkArray(
                                    res.flightRecommendations,
                                    this.state.resultsPerPage
                                )[0]
                                : [],
                        // set filter data
                        searchFilters: {
                            ...this.state.searchFilters,
                            airlineStops: {
                                ...this.state.searchFilters.airlineStops,
                                data: res.flightFilterSource.airlineStops
                            },
                            airlineCodes: {
                                ...this.state.searchFilters.airlineCodes,
                                data: res.flightFilterSource.airlineDTOs
                            }
                        }
                    });
                }
            );
        }

    };

    /**
     * @function handleSearchFilterChange
     * @description Handle search filter result
     */
    handleSearchFilterChange = (filterKey, value, valueData) => {
        this.setState(
            {
                searchFilters: {
                    ...this.state.searchFilters,
                    [filterKey]: {
                        ...this.state.searchFilters[filterKey],
                        value,
                        valueData
                    }
                },
            },
            () => {
                this.flightSearch();
            }
        );
    };

    /**
     * @function handlePagination
     * @description Handle search result pagination
     */
    handlePagination = pageNumber => {
        this.setState({
            activePage: pageNumber,
            activePageSearchResult: chunkArray(
                this.state.searchResult,
                this.state.resultsPerPage
            )[pageNumber - 1]
        });
    };

    /**
     * @function showSearchWidget
     * @description Show search widget modal
     */
    showSearchWidget = () => {
        this.setState({ showSearchWidget: true });
    };

    /**
     * @function handleSearchWidgetClose
     * @description Handle search widget close/hide event
     */
    handleSearchWidgetClose = () => {
        this.setState({ showSearchWidget: false });
    };

    /**
     * @function handleResultBookNow
     * @description Handle Book now button click in search result item
     */
    handleResultBookNow = resultData => {
        this.setState({
            notifyError: null
        })

        // sell from recomondation data to init booking
        try {
            let sellFormItineraries = [];
            const searchRequestParams = {
                ...this.state.searchQuery,
                travelItineraries: [...this.state.searchQuery.travelItineraries]
            };

            // if search is for return type add return flight item for search
            if (
                searchRequestParams.tripType === "return" &&
                searchRequestParams.travelItineraries.length === 1
            ) {
                searchRequestParams.travelItineraries.push({
                    boardPoint:
                        searchRequestParams.travelItineraries[0].offPoint,
                    departureDate:
                        searchRequestParams.travelItineraries[0].arrivalDate,
                    offPoint:
                        searchRequestParams.travelItineraries[0].boardPoint
                });
            }
            sellFormItineraries = Object.keys(resultData.flightSliceMap).map(
                key => {
                    const tripItem = resultData.flightSliceMap[key];
                    return {
                        adults: searchRequestParams.adults,
                        children: searchRequestParams.children,
                        infants: searchRequestParams.infants,
                        orginPoint:
                            searchRequestParams.travelItineraries[0].boardPoint,
                        destinationPoint:
                            searchRequestParams.travelItineraries[
                                searchRequestParams.travelItineraries.length - 1
                            ].offPoint,
                        sellFormSegments: tripItem.flightItineraries.map(
                            flight => ({
                                datetimeOfDeparture: flight.datetimeOfDeparture,
                                boardPoint: flight.boardPoint,
                                offPoint: flight.offPoint,
                                marketingCarrier: flight.marketingCarrier,
                                flightNumber: flight.flightOrtrainNumber,
                                rbd: flight.rbd
                            })
                        )
                    };
                }
            );

            // initialize booking
            this.props.initializeFlightBookingAction(
                resultData,
                sellFormItineraries,
                () => {
                    // redirect to passenger details page
                    this.props.history.push(
                        "/booking-engine/flight/passenger-details"
                    );
                },
                () => {
                    this.setState({
                        notifyError: {
                            title: "Something went wrong",
                            subTitle: "Error",
                            body: "Please select a different flight. Thank You."
                        }
                    })
                }
            );

        } catch (error) {
            handleException(error, "Could not initialize booking");
        }
    };

    sortChanged(id) {
        this.setState({
            sortBy: id === 1 ? 'Price High To Low' : 'Price Low To High'
        })

        const sorted = this.state.searchResult.sort((a, b) => (
            id === 1 ? b.totalMarkupFare - a.totalMarkupFare : a.totalMarkupFare - b.totalMarkupFare
        ));

        this.setState({
            // set search result data
            activePage: 1,
            searchResult: sorted,
            activePageSearchResult:
                sorted.length > 0
                    ? chunkArray(
                        sorted,
                        this.state.resultsPerPage
                    )[0]
                    : []
        });
    }

    render() {

        // ui for search result list
        const uiSearchResultList = this.state.activePageSearchResult.map(
            (result, i) => (
                <SearchResultItem
                    onClickBookNow={this.handleResultBookNow}
                    key={i}
                    searchQuery={this.state.searchQuery}
                    resultData={result}
                    disableBookingButton={this.props.initializingBookingLoading}
                    notifyError={this.state.notifyError ? true : false}
                />
            )
        );

        // flight search widget ui
        const uiFlightSearchWidget = (
            <Modal
                show={this.state.showSearchWidget}
                onHide={this.handleSearchWidgetClose}
                dialogClassName="modal-dialog modal-lg"
            >
                <Modal.Body>
                    {this.state.searchQuery ? (
                        <FlightSearchWidgetSub
                            adults={this.state.searchQuery.adults}
                            children={this.state.searchQuery.children}
                            infants={this.state.searchQuery.infants}
                            bookingClass={this.state.searchQuery.bookingClass}
                            tripType={this.state.searchQuery.tripType}
                            travelItineraries={
                                this.state.searchQuery.travelItineraries
                            }
                            searchSubmit={this.handleSearchSumbit}
                        />
                    ) : (
                            <FlightSearchWidgetSub
                                searchSubmit={this.handleSearchSumbit}
                            />
                        )}
                </Modal.Body>
            </Modal>
        );

        // ui to load after search result available
        const uiAfterSearchResultAvailable = (
            <div className="mt-3 search-result">
                <FlightSummaryBar searchData={this.state.searchQuery} />

                <div className="container mt-3">
                    <div className="row">
                        <div className="col-12 col-lg-2">
                            <div className="row">
                                <div className="col-12 col-lg-12 h5 text-center text-primary ">
                                    <span className="h2 text-primary font-weight-bold">
                                        {this.state.searchResult.length}
                                    </span>
                                    {" "}Results found
                                </div>
                                <div className="col-12 col-lg-12 text-center">
                                    <button
                                        onClick={this.showSearchWidget}
                                        className="btn btn-warning btn-block font-weight-bold"
                                    >
                                        Change Search
                                    </button>
                                    {uiFlightSearchWidget}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6 col-lg-12 p-0 mt-3">
                                    <FilterAirlineCodes
                                        filterData={this.state.searchFilters.airlineCodes}
                                        filterChanged={this.handleSearchFilterChange}
                                    />
                                </div>
                                <div className="col-6 col-lg-12 p-0 mt-3">
                                    <FilterAirlineStops
                                        filterData={this.state.searchFilters.airlineStops}
                                        filterChanged={this.handleSearchFilterChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-10">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-lg-4 sort-order">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="outline-info w-100 mt-lg-0 mt-2 mb-lg-0 mb-2">
                                                <h5 className="font-weight-bold">
                                                    <i className="fa fa-sort-amount-asc mr-2"></i>
                                                    {this.state.sortBy}
                                                    <i className="fa fa-caret-down ml-1" aria-hidden="true"></i>
                                                </h5>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="full-width">
                                                <Dropdown.Item className="text-center" onClick={() => this.sortChanged(1)} >
                                                    Price High To Low
                                                </Dropdown.Item>
                                                <Dropdown.Item className="text-center" onClick={() => this.sortChanged(2)} >
                                                    Price Low To High
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>

                                    <div className="col-12 col-lg-8 paginationClass">
                                        <Pagination
                                            innerClass="pagination float-lg-right d-flex justify-content-center"
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={
                                                this.state.resultsPerPage
                                            }
                                            totalItemsCount={
                                                this.state.searchResult.length
                                            }
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePagination.bind(
                                                this
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="container">
                                {uiSearchResultList}
                            </div>

                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <Pagination
                                            innerClass="pagination float-lg-right d-flex justify-content-center"
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={
                                                this.state.resultsPerPage
                                            }
                                            totalItemsCount={
                                                this.state.searchResult.length
                                            }
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePagination.bind(
                                                this
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        // ui to load when no result available
        const uiNoResult = (
            <div className="container mt-3">
                <div className="row mt-5">
                    <div className="col-12 text-center">
                        <i className="fa fa-5x fa-search mb-3 text-primary"></i>
                        <h3>No flights available for your search.</h3>
                        <p className="text-muted">
                            Change search and try again.
                        </p>
                    </div>
                </div>

                {this.state.searchQuery ? (
                    <FlightSearchWidgetSub
                        adults={this.state.searchQuery.adults}
                        children={this.state.searchQuery.children}
                        infants={this.state.searchQuery.infants}
                        bookingClass={this.state.searchQuery.bookingClass}
                        tripType={this.state.searchQuery.tripType}
                        travelItineraries={
                            this.state.searchQuery.travelItineraries
                        }
                        searchSubmit={this.handleSearchSumbit}
                    />
                ) : (
                        <FlightSearchWidgetSub
                            searchSubmit={this.handleSearchSumbit}
                        />
                    )}
            </div>
        );

        // render page
        return (
            <>
                <BookingEngineHeader />
                <BookingEngineView>
                    {this.props.flightSearchLoading
                        ? <SearchLoader/>
                        : !this.props.flightSearchLoading &&
                            this.state.searchResult.length === 0
                            ? uiNoResult
                            : uiAfterSearchResultAvailable}
                </BookingEngineView>
                <BookingEngineFooter />

                {/* notify modal show */}
                {
                    this.state.notifyError !== null ? <NotifyModal
                        type="danger"
                        title={this.state.notifyError.title}
                        subTitle={this.state.notifyError.subTitle}
                        body={this.state.notifyError.body}
                    /> : null
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        appAuthenticating: state.app.authenticating,
        flightSearchLoading: state.flightBooking.searchingFlights,
        initializingBookingLoading:
            state.flightBooking.initializingFlightBooking
    };
};

const mapDispatchToProps = dispatch => {
    return {
        flightSearchAction: (searchQuaryObjectData, cb, fcb) =>
            dispatch(flightSearchAction(searchQuaryObjectData, cb, fcb)),
        initializeFlightBookingAction: (
            resultData,
            sellFormItinerariesData,
            cb,
            fcb
        ) =>
            dispatch(
                initializeFlightBookingAction(
                    resultData,
                    sellFormItinerariesData,
                    cb,
                    fcb
                )
            )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
