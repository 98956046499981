import React, { Fragment } from 'react'
import { getCurrentUser } from '../../../aws-cognito/aws-functions';
import BookingEngineHeader from '../../components/booking-engine-header/booking-engine-header'
import BookingEngineView from '../../components/booking-engine-view'
import Unauthorized from '../../../common/views/unauthorized';

const AgentPending = () => {

    const currentUser = getCurrentUser();

    if (currentUser) {
        return (
            <Fragment>
                <BookingEngineHeader />
                <BookingEngineView>
                    <div className="pending-agent-message">
                        <div className="container">
                            <div className="mt-5 text-center">
                                <h3 className="text-primary text-uppercase ">Congratulations.</h3>
                                <h4 className="text-success">
                                    You have successfully submitted the agent form.
                                    <i className="fa fa-check-circle-o ml-2" aria-hidden="true"></i>
                                </h4>
                                <h5>Dear valuable agent <i> (pending) </i>, <b> {currentUser.name} </b>  </h5>
                                <p>It'll take few days to approve this after the verfication.</p>
                            </div>
                        </div>
                    </div>

                </BookingEngineView>
            </Fragment>
        )
    } else {
        return (
            <Unauthorized />
        )
    }


}

export default AgentPending
