import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { SESSIONSTORE } from '../storage';
import { withRouter } from 'react-router-dom';

const NotifyModal = (props) => {
    
    const searchQueryUrl = SESSIONSTORE.get('squ');
    const { 
        modalShow = true,
        type,
        title,
        subTitle,
        body,
        showSearchButton = false,
        disableClose = false,
        disableBackdrop = false,
        confirmAction,
        closeAction,
        confirmActionButtonText = 'Confirm'
    } = props;

    const [show, setShow] = useState(modalShow);
    
    useEffect(() => {
        setShow(modalShow);
    }, [modalShow]);
    
    const handleClose = () => {
        setShow(false);
        if (closeAction) {
            closeAction();
        }
    };

    const searchAgainHandler = () => {
        props.history.push({
            pathname: '/booking-engine/flight/search',
            search: '?' + searchQueryUrl
        });
    }

    let backdropClassName = "";

    if (disableBackdrop === true) {
        backdropClassName = ""
    } else {
        backdropClassName = "dark-backdrop"
    }

    return (
        <div>
            <Modal show={show} onHide={handleClose} backdrop={disableBackdrop ? 'static' : true} backdropClassName={backdropClassName}  >
                <Modal.Body>
                    <div className="container text-center">
                        <h3 className={`text-${type}`}>{title}</h3>
                        {subTitle ? <h5 className="text-dark">{subTitle} </h5> : null}

                        <div className="text-muted"> {body} </div>
                        <div>
                            {showSearchButton && searchQueryUrl ? <button className="btn btn-primary" onClick={searchAgainHandler}>Search Again</button> : null}
                        </div>

                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <div >
                        {disableClose ? null : <button className="btn btn-default " onClick={handleClose} >Close</button>}
                        {confirmAction && (typeof confirmAction === 'function') ? <button className="btn btn-primary" onClick={confirmAction}>{confirmActionButtonText}</button> : null}
                    </div>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default withRouter(NotifyModal);
