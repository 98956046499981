import React, { useState } from 'react';
import LaddaButton, { SLIDE_UP } from 'react-ladda';
import { promoAirportStatus, deletePromoAirport } from '../../../api/admin-api';

const PromoAirlineList = ({ list, airlineCode, updates, modalChangers, modalFails }) => {

    // delete button loading
    const [buttonLoading, setButtonLoading] = useState(false);

    // status promotional 
    const [values, setValues] = useState(list.activeStatus);
    const handleStatusPromoAirline = () => {
        handleStatusPromoAirlineValues(values);
        handleStatusPromo();
    }
    const handleStatusPromoAirlineValues = (values) => {
        setValues(!values);
        handleStatusPromo();
    }
    const handleStatusPromo = () => {
        promoAirportStatus(airlineCode, list.airportCode, !values).then(res => {
            updates();
            modalChangers();
        })
            .catch(error => {
                modalFails();
            })
    }

    // delete promotion
    const deletePromo = (airportCode) => {
        setButtonLoading(true);
        deletePromoAirport(airlineCode, airportCode).then(res => {
            updates();
            modalChangers();
        })
            .catch(err => {
                modalFails();
            })
    }

    return (
        <div className="list mb-3">
            <input type="checkbox" className="mr-1" defaultChecked={values} onClick={handleStatusPromoAirline} />
            {list.airportName + ` (` + (list.airportCode) + `)`}
            <div className="float-right">
                <LaddaButton
                    type="submit"
                    className="btn btn-sm btn-danger mb-1 mt-1"
                    loading={buttonLoading}
                    data-style={SLIDE_UP}
                    onClick={() => deletePromo(list.airportCode)}
                >
                    <i className="fa fa-trash-o"
                        aria-hidden="true"></i>
                </LaddaButton>
            </div>
        </div>
    )
}

export default PromoAirlineList
