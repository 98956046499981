import {createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk';

// if (process && process.env && (process.env.DEVELOPMENT)) {
//     // middleware = applyMiddleware(thunk, promise, logger);
//     middleware = applyMiddleware(logger);
// } else {
//     // middleware = applyMiddleware(thunk, promise);
// }

const logger = store => {
    return next => {
        return action => {
            // console.log('[LOGGER] : dispaching ', action);
            const result = next(action);
            // console.log('[LOGGER] : next state ', store.getState());
            return result;
        }
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(rootReducer,composeEnhancers(applyMiddleware(logger, thunk)));