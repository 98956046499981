
import cookie from 'react-cookies';

/**
 * LOCAL APP STORAGE : Global storage
 * @description : Manage application clientside storage using cookies. 
 * @storagekeymap : Storage key map discription
 * at   : AWS auth token
 *  
*///-------------------------------------------
const cookieGlobalConfig = {
    path: '/',
    domain: process.env.REACT_APP_COOKIE_DOMAIN
};

export const LOCALSTORE = {
    /**
     * Save value in storage
     * @description Remove data from storage
     * @param key : key of store value
     * @param value : store value
     */
    save: (key, value) => {
        const valueType = typeof value;
        if (valueType === 'object') {
            return cookie.save(key, JSON.stringify(value), cookieGlobalConfig)
        } else {
            return cookie.save(key, value, cookieGlobalConfig);
        }
    },
    /**
     * Get value from storage
     * @description Get data from storage
     * @param key : key of store value
     */
    get: key => {
        try {
            const data = cookie.load(key);
            try {
                return JSON.parse(data);
            } catch (error) {
                return data;
            }
        } catch (error) {
            return null;
        }
    },
    /**
     * Remove value from storage
     * @description Remove data from storage
     * @param key : key to delete
     */
    delete: key => {
        return cookie.remove(key, cookieGlobalConfig);
    }
};

/**
 * SESSION STORAGE : Tab Session
 * @description : Manage storage for active tab session. 
 * @storagekeymap : Storage key map discription
 * squ  : search query url, this can be use to redirect user to search result page
 * sqo  : search query data object
 * ss   : Booking sSession
 * ps   : Booking Pricing Solution Data (price break down and data)
 * cfpd : Selected booking flight pricing details cache with segments for use in fare rules request
 * cid  : Confirm Id from sell_from_rec
 * pf   : Payment fail at booking-payment -> bpoint component
 * pnrc : PNR Comiited
 * bt   : Booking timer
 * rd   : Search result data
 * anbp : Airport Name Board Point
 * anop : Airport Name Off Point
 * agp  : when an agent submitted the form
 *  
*///-------------------------------------------
export const SESSIONSTORE = {
    /**
     * Save value in storage
     * @description Remove data from session storage
     * @param key : key of store value
     * @param value : store value
     */
    save: (key, value) => {
        const valueType = typeof value;
        if (valueType === 'object') {
            return sessionStorage.setItem(key, JSON.stringify(value))
        } else {
            return sessionStorage.setItem(key, value);
        }
    },
    /**
     * Get value from storage
     * @description Get data from session storage
     * @param key : key of store value
     */
    get: key => {
        try {
            const data = sessionStorage.getItem(key);
            try {
                return JSON.parse(data);
            } catch (error) {
                return data;
            }
        } catch (error) {
            return null;
        }
    },
    /**
     * Remove value from storage
     * @description Remove data from session storage
     * @param key : key to delete
     */
    delete: key => {
        return sessionStorage.removeItem(key);
    }
};