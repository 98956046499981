import React from 'react';
import './ui-loader.scss';

const UiLoader = ({fullscreen}) => {
    return( 
        <>
            {
                fullscreen ?
                <div className="ui-loader-wrapper">
                    <div className="loader">
                        <svg className="circular" viewBox="25 25 50 50">
                            <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
                        </svg>
                    </div>
                </div>
                : 
                <div className="ui-loader-wrapper small-loader">
                    <div className="loader small-loader">
                        <svg className="circular" viewBox="25 25 50 50">
                            <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
                        </svg>
                    </div>
                </div>
            }
        </>
    );
};

export default UiLoader;