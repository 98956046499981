import React, { Fragment, useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import $ from 'jquery';
import DataTable from 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';
import { userBookingReport } from '../../../api/flight-booking.api'
import { toast, ToastContainer } from 'react-toastify';
import UserBookingReport from '../../flight-booking/components/user-booking-report/user-booking-report';
import UiLoader from '../../../common/components/ui-loader/ui-loader';

$.DataTable = DataTable;

const UserReport = () => {

    useEffect(() => {
        apiCall()

        // eslint-disable-next-line 
    }, [])

    const [reportData, setReportData] = useState(null);

    const [updating, setUpdating] = useState(false);

    const [filterPNR, setFilterPNR] = useState(false)
    const [filterReservationID, setFilterReservationID] = useState(false)
    const [filterReservationDate, setFilterReservationDate] = useState(false)

    const [pnrValue, setPnrValue] = useState(null);
    const [reservationIDValue, setReservationIDValue] = useState(null);


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);

    const onChange = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        setPnrValue(null);
        setReservationIDValue(null);

    };

    const today = new Date()

    const data = {
        pnrNumber: pnrValue ? pnrValue : null,
        reservationId: reservationIDValue ? reservationIDValue : null,
        reservationDate: filterReservationDate ? {
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : moment(today).format('YYYY-MM-DD'),
        }
            : null
    }

    const apiCall = () => {
        setUpdating(true)
        userBookingReport(pnrValue || reservationIDValue || filterReservationDate ? data : {}).then((res) => {
            setReportData(res.data)
            setUpdating(false)
        })
            .catch((err) => {
                handleFailed()
            })
    }

    const handleFailed = () => {
        notifyFailed()
    }

    // notification when failed
    const notifyFailed = () => toast.error(`oops, something went wrong!`, {
        containerId: 'notifyFailed',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
    });

    const funcFilterPNR = () => {
        setFilterPNR(true)
        setFilterReservationDate(false)
        setFilterReservationID(false)
    }

    const funcReservationID = () => {
        setFilterPNR(false)
        setFilterReservationDate(false)
        setFilterReservationID(true)
    }

    const funcReservationDate = () => {
        setFilterPNR(false)
        setFilterReservationDate(true)
        setFilterReservationID(false)
    }

    // const funcResetFilters = () => {
    //     setFilterReservationDate(false)
    //     setPnrValue(null);
    //     setReservationIDValue(null);


    //     console.log(reservationIDValue);
    //     console.log(pnrValue);

    //     apiCall();
    // }

    const pnrChange = (event) => {
        setPnrValue(event.target.value);
        setReservationIDValue(null);
    }
    const reservationIDChange = (event) => {
        setPnrValue(null);
        setReservationIDValue(event.target.value);
    }

    const searchClicked = () => {
        apiCall()
        setUpdating(true);
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <h3 className="text-primary ">
                        <i className="fa fa-book  mr-2" aria-hidden="true"></i>
                        Booking report
                    </h3>
                </div>

                <div className="col-12">
                    Please select a filter method
                </div>

                <div className="col-12">
                    <button className="btn btn-primary mr-3 mb-3" onClick={funcFilterPNR} >Filter by PNR</button>
                    <button className="btn btn-primary mr-3 mb-3" onClick={funcReservationID} >Filter by Reservation ID</button>
                    <button className="btn btn-primary mr-3 mb-3" onClick={funcReservationDate} >Filter by Reservation Date</button>
                </div>

                <div className="col-12">
                    {
                        filterPNR ?
                            <div>
                                <input type="text" placeholder="enter the PNR number" onChange={event => pnrChange(event)} />
                                <button className="btn btn-primary ml-3" onClick={searchClicked}  >Search</button>
                            </div>
                            : null
                    }
                    {
                        filterReservationID ?
                            <div>
                                <input type="text" placeholder="enter the reservation ID" onChange={event => reservationIDChange(event)} />
                                <button className="btn btn-primary ml-3" onClick={searchClicked}  >Search</button>
                            </div>
                            : null
                    }

                    {
                        filterReservationDate ?
                            <div className="row mb-3">
                                <div className="col">
                                    <DatePicker
                                        onChange={onChange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        maxDate={today}
                                        selectsRange
                                        inline
                                        monthsShown={2}
                                    />
                                </div>

                                <div className="col">
                                    <button className="btn btn-primary" onClick={searchClicked} disabled={!endDate} >Search</button>
                                    {
                                        endDate ? null : <div className="text-secondary mt-3">please select a date range</div>
                                    }
                                </div>
                            </div>
                            :
                            null
                    }

                </div>
            </div>

            {
                updating
                    ?
                    <UiLoader />
                    :
                    reportData ?
                        <UserBookingReport data={reportData} />
                        :
                        <UserBookingReport data={[]} />
            }

            <ToastContainer enableMultiContainer containerId={'notifyFailed'} position={toast.POSITION.BOTTOM_RIGHT} />

        </Fragment>
    )
}

export default UserReport
