import React, { Component } from 'react'
import $ from 'jquery';
import DataTable from 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';
import { Button, Modal } from 'react-bootstrap';
import * as moment from 'moment';

$.DataTable = DataTable;

export default class UserBookingReport extends Component {

    constructor(props) {
        super(props);
        this.tableAPI = null;
        this.state = {
            show: false,
            dataToModal: null
        }
    }

    componentDidMount() {
        this.tableAPI = $('#userBookingReportTable').DataTable({
            order: [1, 'desc']
        });
    }

    handleClose = () => {
        this.setState({
            show: false
        })
    };

    handleShow = (data, type) => {
        this.setState({
            show: true,
            dataToModal: { ...data, type }
        })
    };

    getFormattedReservationDate = (timeStamp) => {
        try {
            return moment(timeStamp).format('DD-MM-YYYY')+' at '+moment(timeStamp).format('LT');
        } catch (error) {
            return '';
        }
    }

    render() {
        return (
            <div>
                <table id="userBookingReportTable" className="table table-striped table-bordered w-100" >

                    <thead>
                        <tr>
                            <th>PNR</th>
                            <th>Reservation Date</th>
                            {/* <th>Reservation ID</th> */}
                            <th>Passenger Details</th>
                            <th>Trip Itineraries</th>
                            <th>Sub Total</th>
                            <th>Payment Status</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            this.props.data.map(data => (
                                <tr className="text-center" key={data.confirmId}>
                                    <td>{data.pnrNumber ? data.pnrNumber : '-'}</td>
                                    <td>{data.reservationDatetime ? this.getFormattedReservationDate(data.reservationDatetime) : '-'}</td>
                                    {/* <td>{data.reservationId ? data.reservationId : '-'}</td> */}
                                    <td>
                                        {data.passengerItineraries.length > 0 ? <button onClick={() => this.handleShow(data, 'pessenger')} className="btn btn-primary">view</button> : '-'}
                                    </td>
                                    <td>
                                        {data.tripItineraries.length > 0 ? <button onClick={() => this.handleShow(data, 'trips')} className="btn btn-primary">view</button> : '-'}
                                    </td>
                                    <td>{data.currency ? data.currency : null} {data.finalMarkedupOrDiscountedFare ? data.finalMarkedupOrDiscountedFare : '-'}</td>
                                    <td className="font-weight-bold" > {data.paymentStatus ? 'Payment Done' : 'Not Completed'} </td>
                                </tr>
                            ))
                        }

                    </tbody>

                </table>

                {
                    this.state.show ?
                        <Modal show={this.state.show} onHide={this.handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {this.state.dataToModal.type === 'pessenger' ? 'Passenger Details' : 'Trip Itineraries'}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    this.state.dataToModal.type === 'pessenger' ?
                                        this.state.dataToModal.passengerItineraries.map(passenger => (
                                            <ul key={passenger.passengerId}>
                                                <li>{passenger.title} {passenger.firstName}  {passenger.lastName}</li>
                                            </ul>
                                        ))
                                        :
                                        this.state.dataToModal.tripItineraries.map((trip, index) => (
                                            // <ol key={trip.tripId}>
                                            //     <li>{trip.boardPoint} {trip.offPoint} </li>
                                            // </ol>
                                            <div key={trip.tripId}>
                                                <p>Trip Itinerary : #{index + 1}</p>
                                                <ul>
                                                    <li>Boardpoint : {trip.boardPoint} </li>
                                                    <li>Offpoint : {trip.offPoint} </li>
                                                    <li>Flight Number : {trip.flightNumber} </li>
                                                    <li>Marketing Carrier : {trip.marketingCarrier} </li>
                                                </ul>
                                            </div>
                                        ))
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={this.handleClose}>
                                    Close
                            </Button>
                            </Modal.Footer>
                        </Modal>
                        :
                        null
                }

            </div>
        )
    }
}
