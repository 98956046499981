import React, { Component } from 'react';
import * as moment from 'moment';
import DatePicker from 'react-datepicker';
import AdminFooter from '../components/admin-footer/admin-footer';
import AdminHeader from '../components/admin-header/admin-header';
import AdminView from '../components/admin-view';
import { bookingReport } from '../api/admin-api';
import { toast, ToastContainer } from 'react-toastify';
import UiLoader from '../../common/components/ui-loader/ui-loader';
import BookingsReportComponent from '../components/bookings-report/bookings-report-component';
class BookingReport extends Component {

    constructor() {
        super();
        this.state = {
            loading: false,
            filterBy : 'RESERVATION_DATE', // posible values : PNR_NO, RESERVATION_ID, RESERVATION_DATE, BOOKER_EMAIL, BOOKER_NAME
            pnrNumber: null,
            reservationID: null,
            bookerEmail: null,
            bookerName: null,
            rangeStartDate : moment().add(-1, 'months').toDate(),
            rangeEndDate :  moment().toDate(),
            bookings : null
        };
    }

    componentDidMount() {
        this.loadReport();
    }

    setFilter(filterBy) {
        this.setState({
            filterBy
        });
    }

    loadReport() {
        this.setState({loading : true});

        const reqData = {
            pnrNumber: this.state.pnrNumber,
            reservationID: null,
            bookerEmail: null,
            bookerName: null,
            reservationDate: {
                startDate: null,
                endDate: null,
            }
        };

        switch (this.state.filterBy) {
            case 'PNR_NO':
                reqData.pnrNumber = this.state.pnrNumber;
                break;
            case 'RESERVATION_ID':
                reqData.reservationID = this.state.reservationID;
                break;
            case 'RESERVATION_DATE':
                reqData.reservationDate.startDate = moment(this.state.rangeStartDate).format('YYYY-MM-DD');
                reqData.reservationDate.endDate = moment(this.state.rangeEndDate).format('YYYY-MM-DD');
                break;
            case 'BOOKER_EMAIL':
                reqData.bookerEmail = this.state.bookerEmail;
                break;
            case 'BOOKER_NAME':
                reqData.bookerName = this.state.bookerName;
                break;
            default:
                break;
        }

        bookingReport(reqData).then((res) => {
            this.setState({
                loading : false,
                bookings : res.data
            });
        })
        .catch(() => {
            this.setState({
                loading : false
            });
            this.notifyFail();
        });
    }

    notifyFail(msg) {
        toast.error(msg ? msg : `oops, something went wrong!`, {
            containerId: 'notify',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
        })
    }

    notifySuccess(msg) {
        toast.success(msg ? msg : `Processed successfully!`, {
            containerId: 'notify',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
        })
    }

    render() {
        return (
            <div>
                <AdminHeader/>
                <AdminView>
                    <div className="container mb-5 mt-4">    
                        <div className="row">
                            <div className="col-12">
                                <h3 className="text-primary ">
                                    <i className="fa fa-book  mr-2" aria-hidden="true"></i>
                                    Booking report
                                </h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                Please select a filter method
                            </div>
                            <div className="col-12">
                                <button className="btn btn-primary mr-3 mb-3" onClick={() => this.setFilter('PNR_NO')} >Filter by PNR</button>
                                {/* <button className="btn btn-primary mr-3 mb-3" onClick={() => this.setFilter('RESERVATION_ID')} >Filter by Reservation ID</button> */}
                                <button className="btn btn-primary mr-3 mb-3" onClick={() => this.setFilter('RESERVATION_DATE')} >Filter by Reservation Date</button>
                                <button className="btn btn-primary mr-3 mb-3" onClick={() => this.setFilter('BOOKER_EMAIL')} >Filter by Booker Email</button>
                                <button className="btn btn-primary mr-3 mb-3" onClick={() => this.setFilter('BOOKER_NAME')} >Filter by Booker Name</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <form className="form-inline">
                                    { 
                                        this.state.filterBy === 'PNR_NO' ? 
                                            <>
                                                <input type="text" onChange={e => e.target.value !== '' ? this.setState({pnrNumber : e.target.value}) : this.setState({pnrNumber : null})} className="form-control mb-2 mr-sm-2" placeholder="Enter PNR Number"/> 
                                                <button type="button" className="btn btn-primary mb-2" onClick={() => this.loadReport()} disabled={this.state.loading || !this.state.pnrNumber}>Search</button>
                                            </>
                                        : 
                                        this.state.filterBy === 'RESERVATION_ID' ? 
                                            <>
                                                <input type="text" onChange={e => e.target.value !== '' ? this.setState({reservationID : e.target.value}) : this.setState({reservationID : null})} className="form-control mb-2 mr-sm-2" placeholder="Enter Reservation ID"/>
                                                <button type="button" className="btn btn-primary mb-2" onClick={() => this.loadReport()} disabled={this.state.loading || !this.state.reservationID}>Search</button>
                                            </>
                                        : 
                                        this.state.filterBy === 'RESERVATION_DATE' ? 
                                            <>
                                                <label className="mr-2">Reservation Date From</label>
                                                <DatePicker
                                                    className="form-control mb-2 mr-sm-2"
                                                    selected={this.state.rangeStartDate}
                                                    onChange={date => this.setState({rangeStartDate : date})}
                                                    selectsStart
                                                    startDate={this.state.rangeStartDate}
                                                    endDate={this.state.rangeEndDate}
                                                    maxDate={new Date()}
                                                />
                                                <label className="mr-2">To</label>
                                                <DatePicker
                                                    className="form-control mb-2 mr-sm-2"
                                                    selected={this.state.rangeEndDate}
                                                    onChange={date => this.setState({rangeEndDate : date})}
                                                    selectsEnd
                                                    startDate={this.state.rangeStartDate}
                                                    endDate={this.state.rangeEndDate}
                                                    minDate={this.state.rangeStartDate}
                                                    maxDate={new Date()}
                                                />
                                                <button type="button" className="btn btn-primary mb-2" onClick={() => this.loadReport()} disabled={this.state.loading || !this.state.rangeStartDate || !this.state.rangeEndDate}>Search</button>
                                            </>
                                        : 
                                        this.state.filterBy === 'BOOKER_EMAIL' ?
                                            <>
                                                <input type="text" onChange={e => e.target.value !== '' ? this.setState({bookerEmail : e.target.value}) : this.setState({bookerEmail : null})} className="form-control mb-2 mr-sm-2" placeholder="Enter Booker email"/>
                                                <button type="button" className="btn btn-primary mb-2" onClick={() => this.loadReport()} disabled={this.state.loading || !this.state.bookerEmail}>Search</button>
                                            </>
                                        : 
                                        this.state.filterBy === 'BOOKER_NAME' ?
                                            <>
                                                <input type="text" onChange={e => e.target.value !== '' ? this.setState({bookerName : e.target.value}) : this.setState({bookerName : null})} className="form-control mb-2 mr-sm-2" placeholder="Enter Booker name"/>
                                                <button type="button" className="btn btn-primary mb-2" onClick={() => this.loadReport()} disabled={this.state.loading || !this.state.bookerName}>Search</button>
                                            </>
                                        : null
                                    }
                                </form>
                            </div>
                        </div>

                        <div className="mt-3">
                            {
                                this.state.loading ? <UiLoader/> :
                                        this.state.bookings ?
                                            <BookingsReportComponent data={this.state.bookings} handleFailed={() => this.notifyFail()} handleEmailSuccess={() => this.notifySuccess('Email has send successfull')} /> :
                                            <BookingsReportComponent data={[]} />
                            }
                        </div>

                    </div>

                    <ToastContainer enableMultiContainer containerId={'notify'} position={toast.POSITION.BOTTOM_RIGHT} />
                </AdminView>
                <AdminFooter/>
            </div> 
        );
    }

}

export default BookingReport;