import React, { Fragment } from 'react';
import { Route } from "react-router-dom";
import AgentPending from './agent-pending';
import AgentProfile from './agent-profile';
import Agent from './agent';
import AppRoute from '../../../common/hoc/app-route';

const AgentRoute = ({ match }) => {
    return (
        <Fragment
        >
            <Route
                exact
                path={`${match.url}/`}
                component={Agent}
            />
            <AppRoute
                exact
                path={`${match.url}/pending`}
                requiredSessionStore={["agp"]}
                component={AgentPending}
            />
            <AppRoute
                exact
                path={`${match.url}/agent-profile`}
                component={AgentProfile}
            />
        </Fragment>
    )
}

export default AgentRoute
