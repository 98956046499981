import footerDestinations from './footer-destinations.json';
import React from 'react';
import logoLight from '../../../assets/images/Buddha-Trademark-Logo.jpg';
// import stripBanner from '../../../assets/images/strip-banner.jpg';
import './booking-engine-footer.scss';
import moment from 'moment';
// import { Link } from 'react-router-dom';
const footerLogos = require.context('../../../assets/images/footer-logos', true);

const BookingEngineFooter = ({ footerStyle }) => {

    const destinationSearchDateFrom = moment().add(1, 'day').format('YYYY-MM-DD');
    const destinationSearchDateTo = moment().add(8, 'days').format('YYYY-MM-DD');

    const getYear = () => {
        var estDate = "2008-08-22";
        var now = new Date();
        var past = new Date(estDate);
        var nowYear = now.getFullYear();
        var pastYear = past.getFullYear();
        var age = nowYear - pastYear;
        return age;
    }

    const getDestinationUrl = (fromCode, toCode) => {
        return `/booking-engine/flight/search?adults=1&children=0&infants=0&bookingClass=Y&tripType=return&flightType=A&travelItineraries_0_boardPoint=${fromCode}&travelItineraries_0_offPoint=${toCode}&travelItineraries_0_departureDate=${destinationSearchDateFrom}&travelItineraries_0_arrivalDate=${destinationSearchDateTo}`;
    }

    // default style for the footer
    const defaultFooterUi = (
        <footer className="booking-engine-footer">
            <div className="px-1 quick-links">
                <div className="airlines-logo text-center">
                    <div className="logo-img">
                        <a target="_blank" rel="noopener noreferrer"  href="https://www.buddhatravel.com.au">
                            <img className="img-fluid mb-3" src={footerLogos('./Buddha_Travel _AUS.png')} alt="Buddhatravel Australia" />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.buddhatravel.co.nz">
                            <img className="img-fluid mb-3" src={footerLogos('./Buddha_Travel_NZ.png')} alt="Buddhatravel New Zealand" />
                        </a>
                        {/* <a target="_blank"  href="https://www.mastercard.us/en-us.html">
                            <img className="img-fluid" src={footerLogos('./HappyWorldTravelLogo.jpg')} alt="Master Card" />
                        </a> */}
                        <a target="_blank" rel="noopener noreferrer" href="https://www.buddhatravelandtours.com">
                            <img className="img-fluid mb-3" src={footerLogos('./Buddha-Trademark-Logo.png')} alt="Buddhatravel And Tours" />
                        </a>
                        {/* <a target="_blank"  href="https://usa.visa.com/">
                            <img className="img-fluid" src={footerLogos('./nirankar_logo_grey.png')} alt="Visa" />
                        </a> */}
                        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@BGEntertainmentCinema" >
                            <img className="img-fluid-1 mb-3" src={footerLogos('./40868937_500121433733426_1928468911796256768_o.png')} alt="BG Entertainment" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row m-0 footer-blocks mt-3">
                    <div className="col-sm-12 col-lg-3 pt-4 pr-lg-5 text-center text-lg-left  company-summary pl-lg-0">
                        <img width="167" src={logoLight} alt="jdjd" />
                        <div className="footer-description"><p>Having served for over {getYear()} years, we are an independently owned and a full service travel retail company delivering wide range of Domestic &amp; International flights, Stopover &amp; Holiday Packages, Adventure Tours and Travel Insurance with up to date travel solutions worldwide.</p>
                            <p>Our sole objective is to provide&nbsp;quality customer service and after-sales service to our customers. We are&nbsp;&ldquo;Your Travel Agency&rdquo;.</p></div>
                    </div>
                    <div className="col-sm-12 col-lg-9 pr-lg-5 pt-3" id="bottom-footer">
                        <div className="row">
                            <div className="col-sm-12 col-lg-3 pr-lg-0 ">
                                <div className="wrapper quick-link text-center text-lg-left pb-3">
                                    <h4 className="mb-2 text-uppercase">General</h4>
                                    <ul>
                                        <li><a href="https://buddhatravel.com.au/branches">Our Branches</a></li>
                                        <li><a href="https://buddhatravel.com.au/award">Awards & Recognition</a></li>
                                        <li><a href="https://buddhatravel.com.au/gallery">Gallery</a></li>
                                        <li>
                                            <a href="https://buddhatravel.com.au/useful-pages/about-us">About Us</a>
                                        </li>
                                        <li>
                                            <a href="https://buddhatravel.com.au/useful-pages/terms-and-conditions">Terms and Conditions</a>
                                        </li>
                                        <li>
                                            <a href="https://buddhatravel.com.au/useful-pages/sitemap">Sitemap</a>
                                        </li>
                                        <li>
                                            <a href="https://buddhatravel.com.au/useful-pages/refund">Refund</a>
                                        </li>
                                        <li>
                                            <a href="https://buddhatravel.com.au/useful-pages/faqs">FAQs</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-3 pr-lg-0 ">
                                <div className="wrapper quick-link text-center text-lg-left pb-3">
                                    <h4 className="mb-2 text-uppercase">Holidays</h4>
                                    <ul>
                                        <li><a href="https://buddhatravel.com.au/holiday-package/nepal">Nepal</a> </li>
                                        <li><a href="https://buddhatravel.com.au/holiday-package/india">India</a> </li>
                                        <li><a href="https://buddhatravel.com.au/holiday-package/bali">Bali</a> </li>
                                        <li><a href="https://buddhatravel.com.au/holiday-package/thailand">Thailand</a> </li>
                                        <li><a href="https://buddhatravel.com.au/holiday-package/hongkong">Hongkong</a> </li>
                                        <li><a href="https://buddhatravel.com.au/holiday-package/singapore">Singapore</a> </li>
                                        <li><a href="https://buddhatravel.com.au/holiday-package/malaysia">Malaysia </a> </li>
                                        <li><a href="https://www.buddhatravel.com.au/holiday-packages">Show More </a> </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-3 pr-lg-0 ">
                                <div className="wrapper quick-link text-center text-lg-left pb-3">
                                    <h4 className="mb-2 text-uppercase">Extras</h4>
                                    <ul>
                                        <li>
                                            <a href="https://buddhatravel.com.au/car-hire">Car Hire</a>
                                        </li>
                                        <li>
                                            <a href="https://buddhatravel.com.au/cruise">Cruises Holidays</a>
                                        </li>
                                        <li>
                                            <a href="https://buddhatravel.com.au/insurance">Insurance</a>
                                        </li>
                                        <li>
                                            <a href="https://buddhatravel.com.au/railway">Railways</a>
                                        </li>
                                        <li>
                                            <a href="https://buddhatravel.com.au/tour">Tours & Activities</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-3 pr-lg-0 ">
                                <div className="wrapper quick-link text-center text-lg-left pb-3">
                                    <h4 className="mb-2 text-uppercase"><a href="https://buddhatravel.com.au/blog" id="travel-blog-topic" >Travel Blog</a></h4>
                                    <ul>
                                        <li> <a href="https://buddhatravel.com.au/useful-pages/tempting-temples-of-india">Tempting Temples of India</a></li>
                                        <li> <a href="https://buddhatravel.com.au/useful-pages/places-to-visit-in-india-during-summer-and-start-of-monsoon">Places to visit in India during summer and start of Monsoon</a></li>
                                        <li> <a href="https://buddhatravel.com.au/useful-pages/trekking-season-in-nepal">Trekking Season in Nepal</a></li>
                                        <li> <a href="https://buddhatravel.com.au/useful-pages/nepal-and-its-wonders-the-exceptional-beauty-it-holds">Nepal and its wonders: The exceptional beauty it holds!</a></li>
                                        <li> <a href="https://buddhatravel.com.au/useful-pages/covid-19-update">COVID-19 Update</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container mt-3'>
                    <div className='row'>
                        {
                            footerDestinations.map((footerDestination, i) => <div key={i} className='col-sm-12 col-lg-2  text-center text-lg-left'>
                                <h4 className='text-uppercase'>{footerDestination.name}</h4>
                                <ul>
                                    {
                                        footerDestination.destinations.map((destination, k) =>
                                            <li key={k}><a className='my-3' href={getDestinationUrl(destination.fromAirportCode, destination.toAirportCode)}>{destination.name}</a></li>
                                        )
                                    }
                                </ul>
                            </div>)
                        }
                    </div>
                </div>

                <div className="col-12 social mt-5 text-center" id="social-media">
                    <a href="https://www.facebook.com/BuddhaTravel"> <i className="fa fa-facebook-square fa-3x" aria-hidden="true"></i></a>
                    <a href="https://www.instagram.com/buddha.travel/"> <i className="fa fa-instagram fa-3x" aria-hidden="true"></i></a>
                    <a href="https://twitter.com/buddhatravel"> <i className="fa fa-twitter-square fa-3x" aria-hidden="true"></i></a>
                    <a href="https://www.youtube.com/channel/UCpLwa4rL2rhdg9cSrsB2jlg/videos"> <i className="fa fa-youtube-play fa-3x" aria-hidden="true"></i></a>
                    <a href="https://www.pinterest.com.au/buddhatravel"> <i className="fa fa-pinterest fa-3x" aria-hidden="true"></i></a>
                </div>
                <div className="bottom-footer text-center mt-3">
                    <p className="pt-2 pb-2 mb-0"><i className="fa fa-copyright" aria-hidden="true"></i> Buddha Travel and Tours</p>
                </div>
            </div>
        </footer>
    );

    // footer style compact
    const compactFooterUi = (
        <footer className="booking-engine-footer">
            <div className="px-1 quick-links">
                <div className="airlines-logo text-center">
                <div className="logo-img">
                        <a target="_blank" rel="noopener noreferrer"  href="https://www.buddhatravel.com.au">
                            <img className="img-fluid mb-3" src={footerLogos('./Buddha_Travel _AUS.png')} alt="Buddhatravel Australia" />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.buddhatravel.co.nz">
                            <img className="img-fluid mb-3" src={footerLogos('./Buddha_Travel_NZ.png')} alt="Buddhatravel New Zealand" />
                        </a>
                        {/* <a target="_blank"  href="https://www.mastercard.us/en-us.html">
                            <img className="img-fluid" src={footerLogos('./HappyWorldTravelLogo.jpg')} alt="Master Card" />
                        </a> */}
                        <a target="_blank" rel="noopener noreferrer" href="https://www.buddhatravelandtours.com">
                            <img className="img-fluid mb-3" src={footerLogos('./Buddha-Trademark-Logo.png')} alt="Buddhatravel And Tours" />
                        </a>
                        {/* <a target="_blank"  href="https://usa.visa.com/">
                            <img className="img-fluid" src={footerLogos('./nirankar_logo_grey.png')} alt="Visa" />
                        </a> */}
                        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@BGEntertainmentCinema" >
                            <img className="img-fluid-1 mb-3" src={footerLogos('./40868937_500121433733426_1928468911796256768_o.png')} alt="BG Entertainment" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row m-0 footer-blocks mt-3">
                    <div className="col-sm-12 col-lg-2 pt-4 pr-lg-5 text-center text-lg-left  company-summary pl-lg-0">
                        <img width="167" src={logoLight} alt="jdjd" />
                    </div>
                    <div className="col-sm-12 col-lg-10 pr-lg-5 pt-3" id="bottom-footer">
                        <div className="footer-description text-justify"><p>Having served for over {getYear()} years, we are an independently owned and a full service travel retail company delivering wide range of Domestic &amp; International flights, Stopover &amp; Holiday Packages, Adventure Tours and Travel Insurance with up to date travel solutions worldwide.</p>
                            <p>Our sole objective is to provide&nbsp;quality customer service and after-sales service to our customers. We are&nbsp;&ldquo;Your Travel Agency&rdquo;.</p></div>
                    </div>
                </div>

                <div className="col-12 social mt-5 text-center" id="social-media">
                    <a href="https://www.facebook.com/BuddhaTravel"> <i className="fa fa-facebook-square fa-3x" aria-hidden="true"></i></a>
                    <a href="https://www.instagram.com/buddha.travel/"> <i className="fa fa-instagram fa-3x" aria-hidden="true"></i></a>
                    <a href="https://twitter.com/buddhatravel"> <i className="fa fa-twitter-square fa-3x" aria-hidden="true"></i></a>
                    <a href="https://www.youtube.com/channel/UCpLwa4rL2rhdg9cSrsB2jlg/videos"> <i className="fa fa-youtube-play fa-3x" aria-hidden="true"></i></a>
                    <a href="https://www.pinterest.com.au/buddhatravel"> <i className="fa fa-pinterest fa-3x" aria-hidden="true"></i></a>
                </div>
                <div className="bottom-footer text-center mt-3">
                    <p className="pt-2 pb-2 mb-0"><i className="fa fa-copyright" aria-hidden="true"></i> Buddha Travel and Tours</p>
                </div>
            </div>
        </footer>
    );

    return footerStyle === 'compact' ? compactFooterUi : defaultFooterUi;
}

export default BookingEngineFooter;