import { SEARCHING_FLIGHTS, INITIALIZING_FLIGHT_BOOKING} from "../actions/flight-booking.actions";

// flight booking state
const flightBookingState = {
    searchingFlights : false,
    initializingFlightBooking : false,
    bookingTimer : null
};



export default function flightBookingReducer(state = flightBookingState, action) {
    switch (action.type) {
        case SEARCHING_FLIGHTS:
            return {
                ...state,
                searchingFlights : action.payload
            };
        case INITIALIZING_FLIGHT_BOOKING:
            return {
                ...state,
                initializingFlightBooking : action.payload
            };
        default:
            return state;
    }
}