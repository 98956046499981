import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import { getCurrentUser } from '../aws-functions';

const AwsCognitoRoutes = ({ component: Component, group, ...rest }) => (
    getCurrentUser() ?
        <Route {...rest} render={(props) => (
            getCurrentUser().groupConfig.groups.includes(group)
                ?
                <Component {...props} />
                :
                <Redirect to='/unauthorized' />
        )} />
        :
        <Redirect to='/unauthorized' />
)

export default AwsCognitoRoutes;
