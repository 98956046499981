import React from 'react';
import AdminHeader from '../components/admin-header/admin-header';
import AdminFooter from '../components/admin-footer/admin-footer';
import AdminView from '../components/admin-view';
import MenuItem from '../components/menu-items/menu-item';

const MenuItems = () => {
    return (
        <div>
            <AdminHeader />
            <AdminView>
                <div className="container">
                    <div className="mb-5 mt-4">
                        <h3> Mobile App Settings</h3>
                        <h3 className="text-primary "><i className="fa fa-bars mr-2" aria-hidden="true"></i> Menu Item List</h3>
                        <MenuItem/>
                    </div>
                </div>
            </AdminView>
            <AdminFooter />
        </div>
    )
}

export default MenuItems;