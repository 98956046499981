import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
// import DataTable from 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';
import './section-table.scss';
import SectionUpdateModal from '../section-update/section-update-model';
import SectionDeleteModal from '../section-delete/section-delete-model';
import { Button } from 'react-bootstrap';
import SectionCreateModal from '../section-create/section-create-model';
import { sectionGet } from '../../../api/cms.api';
import UiLoader from '../../../../common/components/ui-loader/ui-loader';

const SectionTable = () => {

    //set data
    const [data, setData] = useState('');

    // modal create display
    const [show, setShow] = useState(false);
    const showCreateModal = () => {
        setShow(true);
    }

    //loding
    const [updating, setUpdating] = useState(false);

    // modal edit display
    const [showEdit, setShowEdit] = useState(false);
    const showEditModal = (data) => {
        setShowEdit(true);
        setData(data)
    }

    // delete modal display
    const [showDelete, setShowDelete] = useState(false);
    const showDeleteModal = (data) => {
        setData(data)
        setShowDelete(true);
    }

    //fetch data
    const fetchAllData = () => {
        setUpdating(true);
        //fetch data
        sectionGet().then((data) => {
            //initilize  table
            $('#sectiontable').DataTable({
                order: [[2, "asc"]],
                data: data.data,
                columns: [
                    { title: "Package Name", searchable: true, data: "packageName" },
                    { title: "Layout Type", searchable: true, data: "layoutType" },
                    {
                        title: "Action",
                        data: 'id',
                        searchable: false,
                        sortable: false
                    }
                ],
                columnDefs: [
                    {
                        'targets': [1],
                        createdCell: (td, cellData, rowData, row, col) => {
                            ReactDOM.render(
                                <div>
                                    {
                                        (cellData === 'GRID') ? 'Grid Layout' : (cellData === 'LIST') ? 'List Layout' : (cellData === 'SLIDE') ? 'Slide Layout' : 'Layout Type is Wrong'
                                    }
                                </div>
                                , td);
                        }
                    },
                    {
                        'targets': [2],
                        createdCell: (td, cellData, rowData, row, col) => {
                            ReactDOM.render(
                                <div className="text-center">
                                    <i onClick={() => showEditModal(rowData)} className="fa fa-pencil-square fa-2x pointers mr-1 backcoloredit" aria-hidden="true"></i>
                                    <i onClick={() => showDeleteModal(rowData)} className="fa fa-window-close fa-2x pointers backcolordelete" aria-hidden="true"></i></div>
                                , td);
                        }
                    }
                ],
                paging: true,
                searching: true,
                destory: true
            });

            setUpdating(false);
        })
    }


    useEffect(() => {
        //fetch data
        const fetchAllDataEff = () => {
            setUpdating(true);
            //fetch data
            sectionGet().then((data) => {
                //initilize table
                $('#sectiontable').DataTable({
                    order: [[2, "asc"]],
                    data: data.data,
                    columns: [
                        { title: "Package Name", searchable: true, data: "packageName" },
                        { title: "Layout Type", searchable: true, data: "layoutType" },
                        {
                            title: "Action",
                            data: 'id',
                            searchable: false,
                            sortable: false
                        }
                    ],
                    columnDefs: [
                        {
                            'targets': [1],
                            createdCell: (td, cellData, rowData, row, col) => {
                                ReactDOM.render(
                                    <div>
                                        {
                                            (cellData === 'GRID') ? 'Grid Layout' : (cellData === 'LIST') ? 'List Layout' : (cellData === 'SLIDE') ? 'Slide Layout' : 'Layout Type is Wrong'
                                        }
                                    </div>
                                    , td);
                            }
                        },
                        {
                            'targets': [2],
                            createdCell: (td, cellData, rowData, row, col) => {
                                ReactDOM.render(
                                    <div className="text-center">
                                        <i onClick={() => showEditModal(rowData)} className="fa fa-pencil-square fa-2x pointers mr-1 backcoloredit" aria-hidden="true"></i>
                                        <i onClick={() => showDeleteModal(rowData)} className="fa fa-window-close fa-2x pointers backcolordelete" aria-hidden="true"></i></div>
                                    , td);
                            }
                        }
                    ],
                    paging: true,
                    searching: true,
                    destory: true
                });

                setUpdating(false);
            })
        }


        fetchAllDataEff();
    }, []);

    //refresh data
    const refreshAllData = () => {
        $('#sectiontable').DataTable().destroy();
        //fetch data
        fetchAllData();
    }

    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <div className="float-lg-right">
                        <Button onClick={() => showCreateModal()}>Create Section</Button>
                    </div>
                </div>
                {
                    updating ?
                        <UiLoader />
                        :
                        null
                }
                <div className="col-12 agent mt-2 mt-lg-4">
                    <table id="sectiontable" className="table display table-striped table-bordered w-100" >
                    </table>
                </div>
            </div>

            <SectionCreateModal show={show} onHide={() => setShow(false)} refresh={() => refreshAllData()} />
            <SectionUpdateModal data={data} show={showEdit} onHide={() => setShowEdit(false)} refresh={() => refreshAllData()} />
            <SectionDeleteModal data={data} show={showDelete} onHide={() => setShowDelete(false)} refresh={() => refreshAllData()} />
        </div>
    )
}

export default SectionTable
