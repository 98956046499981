import React from "react";
import CounterInput from "react-counter-input";
import moment from "moment";
import TripItemList from "./trip-item-list";
import "./flight-search-widget.scss";
import { Alert } from "react-bootstrap";

class FlightSearchWidget extends React.Component {
    constructor(props) {
        super(props);

        // set initial widget state
        this.state = {
            submitted: false,
            adults: props.adults ? props.adults : 1,
            children: props.children ? props.children : 0,
            infants: props.infants ? props.infants : 0,
            bookingClass: props.bookingClass ? props.bookingClass : "Y",
            tripType: props.tripType ? props.tripType : "return",
            flightType: "A", // cardcoded,
            travelItineraries: props.travelItineraries ? props.travelItineraries : [
                {
                    boardPoint: null, // default set to melbourne australia
                    offPoint: null,
                    departureDate: null,
                    arrivalDate: null // only sets if trip type is 'return'
                }
            ],
            searchErrors: []
        };
    }

    /* Handle trip type change */
    handleTripTypeSelect = newTripType => {
        if (this.state.tripType !== newTripType) {
            let updatedTravelItineraries = [...this.state.travelItineraries];
            // reset all arrival dates to null
            updatedTravelItineraries = updatedTravelItineraries.map(trip => ({
                ...trip,
                arrivalDate: null
            }));

            switch (newTripType) {
                case "oneway":
                    let oneWayTrip = {
                        ...updatedTravelItineraries[0],
                        arrivalDate: null
                    };
                    updatedTravelItineraries = [oneWayTrip];
                    break;

                case "return":
                    let returnTrip = {
                        ...updatedTravelItineraries[0],
                        arrivalDate: updatedTravelItineraries[0].departureDate
                            ? moment(
                                updatedTravelItineraries[0].departureDate
                            ).format("YYYY-MM-DD")
                            : null
                    };
                    updatedTravelItineraries = [returnTrip];
                    break;

                case "multicity":
                    if (this.state.travelItineraries.length < 2) {
                        updatedTravelItineraries = [
                            ...updatedTravelItineraries,
                            {
                                boardPoint: null,
                                offPoint: null,
                                departureDate: updatedTravelItineraries.pop()
                                    .departureDate,
                                arrivalDate: null
                            }
                        ];
                    }
                    break;

                default:
                    break;
            }
            this.setState({
                tripType: newTripType,
                travelItineraries: updatedTravelItineraries
            });
        }
    };

    /* Handle booking class change */
    handleBookingClassSelect = bookingClass => {
        this.setState({ bookingClass });
    };

    /* Handle trip item add */
    handleAddTripItem = prevIndex => {
        if (this.state.travelItineraries.length < 4) {
            let updatedTravelItineraries = [...this.state.travelItineraries];
            // add new item after
            updatedTravelItineraries.splice(prevIndex + 1, 0, {
                boardPoint: null,
                offPoint: null,
                departureDate:
                    updatedTravelItineraries[prevIndex].departureDate,
                arrivalDate: null
            });
            // updatedTravelItineraries.push({
            //     boardPoint: null,
            //     offPoint: null,
            //     departureDate: updatedTravelItineraries[prevIndex].departureDate,
            //     arrivalDate : null
            // });
            this.setState({ travelItineraries: updatedTravelItineraries });
        }
    };

    /* Handle trip item remove */
    handleRemoveTripItem = removeItemIndex => {
        if (this.state.travelItineraries.length > 2) {
            let updatedTravelItineraries = [...this.state.travelItineraries];
            updatedTravelItineraries.pop();

            this.setState({
                travelItineraries: updatedTravelItineraries
            });
        }
    };

    /* Handle Board Point (Origin) change in item */
    handleBoardPointChangeTripItem = (index, selectedAirport) => {
        let updatedTravelItineraries = [...this.state.travelItineraries];
        updatedTravelItineraries[index].boardPoint = selectedAirport.apIataCode3;

        if (selectedAirport.apCountryCodeIso) { updatedTravelItineraries[index].boardPointCountry = selectedAirport.apCountryCodeIso; }

        this.setState({
            travelItineraries: updatedTravelItineraries
        });
    };

    /* Handle Off Point (Destination) change in item */
    handleOffPointChangeTripItem = (index, selectedAirport) => {
        let updatedTravelItineraries = [...this.state.travelItineraries];
        updatedTravelItineraries[index].offPoint = selectedAirport.apIataCode3;

        if (selectedAirport.apCountryCodeIso) { updatedTravelItineraries[index].offPointCountry = selectedAirport.apCountryCodeIso; }

        this.setState({
            travelItineraries: updatedTravelItineraries
        });
    };

    /* Handle departure date change in item */
    handleDepartureDateChangeTripItem = (index, newDepartureDate) => {
        let updatedTravelItineraries = [...this.state.travelItineraries];

        updatedTravelItineraries[index].departureDate = newDepartureDate;

        // update rest if departure date is before
        for (let i = index; i < updatedTravelItineraries.length; i++) {
            // if (updatedTravelItineraries[index].departureDate < newDepartureDate) {
            if (
                moment(updatedTravelItineraries[i].departureDate).isBefore(
                    newDepartureDate
                )
            ) {
                updatedTravelItineraries[i].departureDate = newDepartureDate;
            }
        }

        // if arrival date is before departure date then set arrival date to same as departure date
        if (
            this.state.tripType === "return" &&
            moment(updatedTravelItineraries[index].arrivalDate).isBefore(
                newDepartureDate
            )
        ) {
            updatedTravelItineraries[index].arrivalDate = newDepartureDate;
        }

        this.setState({ travelItineraries: updatedTravelItineraries });
    };

    /* Handle arrival date change in item */
    handleArrivalDateChangeTripItem = (index, newArrivalDate) => {
        let updatedTravelItineraries = [...this.state.travelItineraries];
        updatedTravelItineraries[index].arrivalDate = newArrivalDate;

        this.setState({ travelItineraries: updatedTravelItineraries });
    };

    /* Handle widget submit */
    handleWidgetSubmit = () => {
        this.setState({ submitted: true });

        let exportQuery = { ...this.state };
        delete exportQuery.submitted;

        this.validateSearch().then(isValid => {
            if (isValid) {
                this.props.searchSubmit(exportQuery);
            }
        });
    };

    validateSearch = () => {
        return new Promise((resolve, reject) => {
            this.setState({
                searchErrors: []
            }, () => {
                let isValidSearch = true;
                let updatedTripsWithErrors = [...this.state.travelItineraries];

                // validate trips
                updatedTripsWithErrors.forEach((trip,index) => {
                    if (!trip.boardPoint || !trip.offPoint || !trip.departureDate) {
                        isValidSearch = false;
                    } else if (this.state.tripType === "return" && !trip.arrivalDate) {
                        isValidSearch = false;
                    }

                    // covid message
                    // if (trip.boardPointCountry && trip.boardPoint) {
                    //     if (trip.boardPointCountry !== 'AU' && index === 0) {
                    //         isValidSearch = false;
                    //         this.setState({
                    //             searchErrors: [...this.state.searchErrors, 'Due to the current situation, only the Australian Airports are available. Sorry for the inconvenience caused!']
                    //         });
                    //     }
                    // }
                });

                setTimeout(() => {
                    // validate passenger count
                    if ((this.state.adults + this.state.children + this.state.infants) > 9) {
                        isValidSearch = false;
                        this.setState({
                            searchErrors: [...this.state.searchErrors, 'Maximum allowed passenger limit is 9']
                        });
                    }
                })
                
                resolve(isValidSearch);
            });
        });
    };

    render() {
        return (
            <div className="container main-search-widget">
                <div className="col-lg-10 col-sm-12 for-iframe-booking-engine">
                    <div className="flight-text-box text-uppercase">
                        Flight Search
                </div>
                    <div className="card widget-wrapper-new">
                        <div className="card-body">
                            <div className="container p-0">
                                <div className="row" id="select-buttons">
                                    <div className="col-12 col-lg-6">
                                        <button
                                            onClick={() =>
                                                this.handleTripTypeSelect("oneway")
                                            }
                                            className={
                                                "btn btn-outline-primary mr-2 px-3 " +
                                                (this.state.tripType === "oneway"
                                                    ? "active"
                                                    : "")
                                            }
                                        >
                                            One way
                                    </button>
                                        <button
                                            onClick={() =>
                                                this.handleTripTypeSelect("return")
                                            }
                                            className={
                                                "btn btn-outline-primary mr-2 px-3 " +
                                                (this.state.tripType === "return"
                                                    ? "active"
                                                    : "")
                                            }
                                        >
                                            Return
                                    </button>
                                    </div>
                                    <div className="col-12 col-lg-6 mt-1 mt-lg-0 text-lg-right">
                                        <button
                                            onClick={() =>
                                                this.handleBookingClassSelect("Y")
                                            }
                                            className={
                                                "btn btn-outline-primary mr-2 px-3 " +
                                                (this.state.bookingClass === "Y"
                                                    ? "active"
                                                    : "")
                                            }
                                        >
                                            Economic
                                    </button>
                                        <button
                                            onClick={() =>
                                                this.handleBookingClassSelect("W")
                                            }
                                            className={
                                                "btn btn-outline-primary mr-2 px-3  mt-1 mt-sm-0 " +
                                                (this.state.bookingClass === "W"
                                                    ? "active"
                                                    : "")
                                            }
                                        >
                                            Economic Premium
                                    </button>
                                        <button
                                            onClick={() =>
                                                this.handleBookingClassSelect("C")
                                            }
                                            className={
                                                "btn btn-outline-primary mr-2 px-3 mt-1 mt-sm-0 " +
                                                (this.state.bookingClass === "C"
                                                    ? "active"
                                                    : "")
                                            }
                                        >
                                            Business
                                    </button>
                                    </div>
                                </div>

                                <div className="container p-0 mt-3">
                                    <TripItemList
                                        tripType={this.state.tripType}
                                        travelItineraries={this.state.travelItineraries}
                                        showValidationErrors={this.state.submitted}
                                        onAddTripItem={this.handleAddTripItem}
                                        onRemoveTripItem={this.handleRemoveTripItem}
                                        onBoardPointChangeTripItem={
                                            this.handleBoardPointChangeTripItem
                                        }
                                        onOffPointChangeTripItem={
                                            this.handleOffPointChangeTripItem
                                        }
                                        onDepartureDateChangeItem={
                                            this.handleDepartureDateChangeTripItem
                                        }
                                        onArrivalDateChangeItem={
                                            this.handleArrivalDateChangeTripItem
                                        }
                                    />
                                </div>

                                <div className="row mt-2">
                                    <div className="col-lg-3 col-6 text-center">
                                        <div className="row">
                                            <div className="col-12 mb-1 mb-lg-0">
                                                <div className="d-inline-block">
                                                    <div>Adults</div>
                                                </div>
                                            </div>

                                            <div className="col-12 d-flex justify-content-center">
                                                <div className="counter-input-wrapper">
                                                    <CounterInput
                                                        count={this.state.adults}
                                                        min={1}
                                                        max={5}
                                                        onCountChange={count =>
                                                            this.setState({
                                                                adults: count
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                (age 12+)
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-6 text-center mt-lg-0">
                                        <div className="row">
                                            <div className="col-12 mb-1 mb-lg-0">
                                                <div className="d-inline-block">
                                                    <div>Children</div>
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center">
                                                <div className="counter-input-wrapper">
                                                    <CounterInput
                                                        count={this.state.children}
                                                        min={0}
                                                        max={5}
                                                        onCountChange={count =>
                                                            this.setState({
                                                                children: count
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                (age 2-12)
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 text-center mt-lg-0">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="d-inline-block">
                                                    <div>Infants</div>
                                                </div>
                                            </div>

                                            <div className="col-12 d-flex justify-content-center">
                                                <div className="counter-input-wrapper">
                                                    <CounterInput
                                                        count={this.state.infants}
                                                        min={0}
                                                        max={5}
                                                        onCountChange={count =>
                                                            this.setState({
                                                                infants: count
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                (age 0-2)
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 mt-3">
                                        {
                                            // this.state.searchErrors.length > 0 &&
                                            // this.state.searchErrors.map((err, i) => <div key={i} className="small text-danger">{err}</div>)
                                        }
                                        <button
                                            className="btn btn-primary btn-block btn-lg mt-2 mt-lg-0 "
                                            onClick={this.handleWidgetSubmit}
                                        >
                                            SEARCH
                                    </button>
                                    </div>
                                </div>

                                {
                                    // general errors
                                    this.state.searchErrors.length > 0 &&
                                    (
                                        <Alert className="widget-errors-alert small mt-2" variant="primary" onClose={() => this.setState({searchErrors : []})} dismissible>
                                            <ul>
                                            {this.state.searchErrors.map((err, i) => <li key={i}>{err}</li>)}
                                            </ul>
                                        </Alert>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FlightSearchWidget;
