import React, { useRef, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import { toast, ToastContainer } from 'react-toastify';
import { googleMap } from '../../../booking-engine/booking-enigne-config';
import { getAgentDetails, saveAgentDetails, updateAgentDetails, userBookingRegisterSuccess, getCountryCodeByCountry } from '../../../api/flight-booking.api';
import { uploadAgentUserIdentificationDoc, deleteAgentUserIdentificationDoc, getAgentUserIdentificationDoc } from '../../../api/storage.api';
import NotifyModal from '../../../common/components/notify-modal';
import { SESSIONSTORE } from '../../../common/storage';

const AgentForm = (props) => {

    const [search, setSearch] = useState('');
    const [mapData, setMapData] = useState('');
    const [mapDataCountry, setMapDataCountry] = useState([]);
    const [notifyError, setNotifyError] = useState(null)

    const [agentInfo, setAgentInfo] = useState('');

    // document url
    const [documentUrl, setDocumentUrl] = useState(null);

    useEffect(() => {
        if (props.edit) {
            getAgentDetails().then((res) => {
                setAgentInfo(res.data);
                getAgentUserIdentificationDoc(res.data.cognitoUserAttributes.username)
                .then(res => {
                    setDocumentUrl(res.data);
                }).catch( err => console.log(err))
            })
            .catch((err) => {
                setNotifyError({
                    title: "Oops! ",
                    subTitle: "Something went wrong.",
                    body: "Please contact the system support."
                })
            })
        }
    }, [props])

    const ref = useRef();

    const schema = Yup.object().shape({
        b2buTelephone: Yup.string()
            .min(8, 'Telephone must contain at least 8 digits')
            .max(13, "Telephone can't exceed 13 digits")
            .required('Telephone is required'),
        b2buGender: Yup.string(),
        b2buCity: Yup.string()
            .required('City is required'),
        b2buCopyResEmailsTo: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        // b2buIdentyficationDoc: Yup.object().shape({
        //     file: Yup.object(),
        //     name: Yup.string().required()
        //   }).label('File'),
        b2buIdentyficationDoc : Yup.object(),
        b2buCompanyName: Yup.string()
            .required('Company Name is required'),
        b2buCompanyTradingName: Yup.string()
            .required('Trading Name is required'),
        b2buABNACN: Yup.string(),
        b2buAddress: Yup.string()
            .required('Address is required'),
        b2buCompanyEmail: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        b2buCompanyTelephone: Yup.string()
            .min(8, 'Telephone must contain at least 8 digits')
            .max(13, "Telephone can't exceed 13 digits")
            .required('Telephone is required'),
        acceptTerms: Yup.bool()
            .oneOf([true], 'Accepting Termss & Conditions is required')
    });

    const countryDetails = (data) => data.address_components.filter(list => {
        if (list.types.includes('country')) {
            setMapDataCountry(list)

            console.log(mapDataCountry);
            getCountryCodeByCountry(mapDataCountry.long_name).then((res) => {
                console.log(res.data);
            })
                .catch((err) => {
                    console.log(err);
                })
        }
        return list
    });

    const submit = () => {
        setNotifyError(null);

        const formData = ref.current.values;

        const hiddenFieldsWhenSave = {
            b2buLocationId: mapData.place_id,
            b2buCountryCode: mapDataCountry.short_name,
            b2buCountryName: mapDataCountry.long_name,
            b2buDiscount: 0,
            b2buPaymentMode: 'CARD',
        }

        const hiddenFieldsWhenUpdating = {
            b2bUID: agentInfo.b2bUID,
            userIdentityServiceId: agentInfo.userIdentityServiceId,
            b2buLocationId: agentInfo.b2buLocationId,
            b2buCountryCode: agentInfo.b2buCountryCode,
            b2buCountryName: agentInfo.b2buCountryName,
        }

        // console.log('formData : ', formData);
        if (formData.b2buAddress && formData.b2buCompanyName && formData.b2buCopyResEmailsTo && formData.b2buTelephone && formData.b2buCompanyEmail && formData.b2buCompanyTelephone) {
            const dataToSendWhenSaving = { ...formData, ...hiddenFieldsWhenSave }

            const dataToSendWhenUpdating = { ...formData, ...hiddenFieldsWhenUpdating }

            // when editing
            if (props.edit) {
                
                // update agent details
                updateAgentDetails(dataToSendWhenUpdating).then((res) => {
                    // update id doc if available after agent updated
                    if (dataToSendWhenSaving.b2buIdentyficationDoc) {

                        // delete previous file available
                        if (documentUrl) {
                            // delete previous file
                            deleteAgentUserIdentificationDoc(dataToSendWhenUpdating.userIdentityServiceId)
                            .then(() => {
                                // upload new file
                                uploadAgentUserIdentificationDoc(dataToSendWhenUpdating.userIdentityServiceId, dataToSendWhenSaving.b2buIdentyficationDoc)
                                .then(() => {
                                    dataSaveSuccess()
                                })
                                .catch(() => {
                                    setNotifyError({
                                        title: "Oops! ",
                                        subTitle: "Could not update your ID document",
                                        body: "Please contact the system support."
                                    })
                                })
                            })
                            .catch(() => {
                                console.log('could not delete previouse uploaded file');
                            })
                        } else {
                            // upload new file
                            uploadAgentUserIdentificationDoc(dataToSendWhenUpdating.userIdentityServiceId, dataToSendWhenSaving.b2buIdentyficationDoc)
                            .then(() => {
                                dataSaveSuccess()
                            })
                            .catch(() => {
                                setNotifyError({
                                    title: "Oops! ",
                                    subTitle: "Could not update your ID document",
                                    body: "Please contact the system support."
                                })
                            })
                        }
                        
                    } else {
                        dataSaveSuccess()
                    }
                    
                })
                .catch(() => {
                    setNotifyError({
                        title: "Oops! ",
                        subTitle: "Something went wrong.",
                        body: "Please contact the system support."
                    })
                })
            } else {
                // initialize
                saveAgentDetails(dataToSendWhenSaving).then((res) => {
                    // save id doc if available after agent created
                    if (dataToSendWhenSaving.b2buIdentyficationDoc) {
                        uploadAgentUserIdentificationDoc(res.data.userIdentityServiceId,dataToSendWhenSaving.b2buIdentyficationDoc)
                        .then(() => {
                            SESSIONSTORE.save("agp", true);
                            props.history.push({
                                pathname: '/agent/pending'
                            })
        
                            userBookingRegisterSuccess().then(() => {
                                console.log('called');
                            })
                            .catch(() => {
                                setNotifyError({
                                    title: "Email sending error...!!!",
                                    subTitle: "something went wrong when sending the email",
                                    body: "Please contact system support if you need to know anything further."
                                })
                            });
                        })
                        .catch(() => {
                            setNotifyError({
                                title: "File upload failed",
                                subTitle: "Your registration is success but could not upload the ID document",
                                body: "Please contact system support if you need to know anything further."
                            })
                        });
                    } else {
                        SESSIONSTORE.save("agp", true);
                        props.history.push({
                            pathname: '/agent/pending'
                        })
    
                        userBookingRegisterSuccess().then(() => {
                            console.log('called');
                        })
                        .catch(() => {
                            setNotifyError({
                                title: "Email sending error...!!!",
                                subTitle: "something went wrong when sending the email",
                                body: "Please contact system support if you need to know anything further."
                            })
                        });
                    }
                    
                })
                    .catch((err) => {
                        if (err.response.data.status === 409) {
                            setNotifyError({
                                title: "You have already submitted the form...!!!",
                                subTitle: "Please wait until it review.",
                                body: "Please contact system support if you need to know anything further."
                            })
                        } else {
                            setNotifyError({
                                title: "Oops! ",
                                subTitle: "Something went wrong.",
                                body: "Please contact the system support."

                            })
                        }
                    })
            }
        }
    }

    const dataSaveSuccess = () => {
        notifyDataSaveSuccess()
    }

    // notification when successfull grant an agent
    const notifyDataSaveSuccess = () => toast.success(`successfully save the details!`, {
        containerId: 'notifyDataSaveSuccess',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
    });

    return (
        <div>
            <Formik
                innerRef={ref}
                enableReinitialize
                initialValues={{
                    b2buTelephone: agentInfo.b2buTelephone ? agentInfo.b2buTelephone : "",
                    b2buGender: agentInfo.b2buGender ? agentInfo.b2buGender : "Male",
                    b2buCity: agentInfo.b2buCity ? agentInfo.b2buCity : "",
                    b2buCopyResEmailsTo: agentInfo.b2buCopyResEmailsTo ? agentInfo.b2buCopyResEmailsTo : "",
                    b2buIdentyficationDoc : null,
                    // company
                    b2buCompanyName: agentInfo.b2buCompanyName ? agentInfo.b2buCompanyName : "",
                    b2buCompanyTradingName: agentInfo.b2buCompanyTradingName ? agentInfo.b2buCompanyTradingName : "",
                    b2buABNACN: agentInfo.b2buABNACN ? agentInfo.b2buABNACN : "",
                    b2buAddress: agentInfo.b2buAddress ? agentInfo.b2buAddress : "",
                    b2buCompanyEmail: agentInfo.b2buCompanyEmail ? agentInfo.b2buCompanyEmail : "",
                    b2buCompanyTelephone: agentInfo.b2buCompanyTelephone ? agentInfo.b2buCompanyTelephone : "",
                    acceptTerms: false
                }}
                validationSchema={schema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                }}
            >
                {({ errors, values, touched, isValidating, setFieldValue, setFieldTouched, isSubmitting, handleReset, handleChange }) => (
                    <Form >

                        <div className="form-group row mt-2 mt-lg-4">
                            <label htmlFor="b2buTelephone" className="col-sm-4 col-form-label">Phone Number</label>
                            <div className="col-sm-8">
                                <Field className={'form-control' + (errors.b2buTelephone && touched.b2buTelephone ? ' is-invalid' : '')} name="b2buTelephone" type="string" placeholder="Enter Telephone" />
                                <ErrorMessage name="b2buTelephone" component="div" className="invalid-feedback" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="b2buGender" className="col-sm-4 col-form-label">Gender</label>
                            <div className="col-sm-8">

                                <div role="group" aria-labelledby="my-radio-group">
                                    <label className="mr-4">
                                        <Field type="radio" name="b2buGender" className="mr-2" value="Male" defaultChecked />
                                                                Male
                                                            </label>
                                    <label className="mr-4">
                                        <Field type="radio" name="b2buGender" className="mr-2" value="Femail" />
                                                                Femail
                                                            </label>
                                    <label className="">
                                        <Field type="radio" name="b2buGender" className="mr-2" value="Other" />
                                                                Other
                                                            </label>
                                </div>
                            </div>
                        </div>

                        <div className="form-group row mt-2 mt-lg-4" >
                            <label htmlFor="b2buCity" className="col-sm-4 col-form-label">City</label>
                            <div className="col-sm-8">

                                <ReactGoogleMapLoader
                                    params={{
                                        key: googleMap,
                                        libraries: "places,geocode",
                                    }}
                                    render={googleMaps =>
                                        googleMaps && (
                                            <ReactGooglePlacesSuggest
                                                googleMaps={googleMaps}
                                                autocompletionRequest={{
                                                    input: search
                                                }}
                                                onSelectSuggest={suggest => {
                                                    setMapData(suggest);
                                                    setFieldValue('b2buCity', suggest.formatted_address)
                                                    setSearch("");
                                                    countryDetails(suggest);
                                                }}
                                                customRender={prediction => (
                                                    <div className="customWrapper">
                                                        {prediction
                                                            ? prediction.description
                                                            : "no results found"}
                                                    </div>
                                                )}
                                            >
                                                <Field
                                                    onChange={e => {
                                                        setSearch(e.target.value);
                                                        handleChange(e);
                                                    }}
                                                    className={'form-control' + (errors.b2buCity && touched.b2buCity ? ' is-invalid' : '')}
                                                    name="b2buCity" type="text" placeholder="Enter City" />
                                                <ErrorMessage name="b2buCity" component="div" className="invalid-feedback" />
                                            </ReactGooglePlacesSuggest>
                                        )
                                    }
                                />
                            </div>
                        </div>

                        <div className="form-group row mt-2 mt-lg-4">
                            <label htmlFor="b2buCopyResEmailsTo" className="col-sm-4 col-form-label">Copy Reservation Emails To</label>
                            <div className="col-sm-8">
                                <Field name="b2buCopyResEmailsTo" type="text" placeholder="Enter Copy Reservation Emails To" className={'form-control' + (errors.b2buCopyResEmailsTo && touched.b2buCopyResEmailsTo ? ' is-invalid' : '')} />
                                <ErrorMessage name="b2buCopyResEmailsTo" component="div" className="invalid-feedback" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="b2buIdentyficationDoc" className="col-sm-4 col-form-label">Identyfication Document</label>
                            <div className="col-sm-4">
                                <input id="b2buIdentyficationDoc" name="b2buIdentyficationDoc" type="file" onChange={(event) => {
                                    setFieldValue("b2buIdentyficationDoc", event.currentTarget.files[0]);
                                }} />
                            </div>
                            <div className="col-sm-4 text-sm-right">
                                {documentUrl ? <a href={documentUrl} className="btn btn-outline-primary btn-sm" download><i className="fa fa-download mr-2"></i> Download</a> : null}
                            </div>
                        </div>

                        <h5 className="my-4">If company,</h5>

                        <div className="form-group row mt-2 mt-lg-4">
                            <label htmlFor="b2buCompanyName" className="col-sm-4 col-form-label">Company Register Name</label>
                            <div className="col-sm-8">
                                {
                                    props.edit ?
                                        <Field name="b2buCompanyName" type="text" disabled placeholder="Enter Company Registered Name" className='form-control' />
                                        :
                                        <Field name="b2buCompanyName" type="text" placeholder="Enter Company Name" className={'form-control' + (errors.b2buCompanyName && touched.b2buCompanyName ? ' is-invalid' : '')} />
                                }
                                <ErrorMessage name="b2buCompanyName" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="form-group row mt-2 mt-lg-4">
                            <label htmlFor="b2buCompanyTradingName" className="col-sm-4 col-form-label">Company Trading Name</label>
                            <div className="col-sm-8">
                                {
                                    props.edit ?
                                        <Field name="b2buCompanyTradingName" type="text" disabled placeholder="Enter Company Trading Name" className='form-control' />
                                        :
                                        <Field name="b2buCompanyTradingName" type="text" placeholder="Enter Company Name" className={'form-control' + (errors.b2buCompanyTradingName && touched.b2buCompanyTradingName ? ' is-invalid' : '')} />
                                }
                                <ErrorMessage name="b2buCompanyTradingName" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="form-group row mt-2 mt-lg-4">
                            <label htmlFor="b2buABNACN" className="col-sm-4 col-form-label">ABN/ACN</label>
                            <div className="col-sm-8">
                                <Field className={'form-control' + (errors.b2buABNACN && touched.b2buABNACN ? ' is-invalid' : '')} name="b2buABNACN" type="text" placeholder="Enter ABN/ACN" />
                                <ErrorMessage name="b2buABNACN" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="form-group row mt-2 mt-lg-4">
                            <label htmlFor="b2buAddress" className="col-sm-4 col-form-label">Address</label>
                            <div className="col-sm-8">
                                <Field className={'form-control' + (errors.b2buAddress && touched.b2buAddress ? ' is-invalid' : '')} name="b2buAddress" type="text" placeholder="Enter Company Address" />
                                <ErrorMessage name="b2buAddress" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="form-group row mt-2 mt-lg-4">
                            <label htmlFor="b2buCompanyEmail" className="col-sm-4 col-form-label">Email</label>
                            <div className="col-sm-8">
                                <Field className={'form-control' + (errors.b2buCompanyEmail && touched.b2buCompanyEmail ? ' is-invalid' : '')} name="b2buCompanyEmail" type="email" placeholder="Enter Company Email" />
                                <ErrorMessage name="b2buCompanyEmail" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="form-group row mt-2 mt-lg-4">
                            <label htmlFor="b2buCompanyTelephone" className="col-sm-4 col-form-label">Phone no</label>
                            <div className="col-sm-8">
                                <Field className={'form-control' + (errors.b2buCompanyTelephone && touched.b2buCompanyTelephone ? ' is-invalid' : '')} name="b2buCompanyTelephone" type="text" placeholder="Enter Copmany Phone no" />
                                <ErrorMessage name="b2buCompanyTelephone" component="div" className="invalid-feedback" />
                            </div>
                        </div>

                        {
                            props.edit ?
                                null
                                :
                                <div className="form-group form-check">
                                    <Field type="checkbox" name="acceptTerms" checked={values.acceptTerms} className={'form-check-input ' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')} />
                                    <label htmlFor="acceptTerms" className="form-check-label">Accept Terms & Conditions. <a className="text-muted" href="https://buddhatravel.com.au/useful-pages/terms-and-conditions" target="_blank" rel="noopener noreferrer" >Click Here to read terms and conditions</a>  </label>
                                    <ErrorMessage name="acceptTerms" component="div" className="invalid-feedback" />
                                </div>
                        }

                        <div className="form-group mt-2 mt-lg-4">
                            {
                                props.edit ?
                                    <button type="submit" className="btn btn-primary mr-2" onClick={submit}>Save</button>
                                    :
                                    <button type="submit" className="btn btn-primary mr-2" onClick={submit}>Register</button>
                            }

                            {/* <button type="reset" className="btn btn-secondary">Reset</button> */}
                            <button type="button" onClick={() => {
                                handleReset()
                            }} className="btn btn-secondary">Reset</button>
                        </div>
                    </Form>
                )}
            </Formik>

            {
                notifyError ?
                    <NotifyModal
                        type="danger"
                        title={notifyError.title}
                        subTitle={notifyError.subTitle}
                        body={notifyError.body}

                    />
                    :
                    null
            }

            <ToastContainer enableMultiContainer containerId={'notifyDataSaveSuccess'} position={toast.POSITION.BOTTOM_RIGHT} />
        </div>
    )
}

export default withRouter(AgentForm);
