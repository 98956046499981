import React from "react";
import { Route } from "react-router-dom";
import Home from "./views/home/home";
import SearchResult from "./flight-booking/views/search-result/search-result";
import Login from "./views/login/login";
import Register from "./views/register/register";
import PassengerDetails from "./flight-booking/views/passenger-details/passenger-details";
import BookingSuccess from "./flight-booking/views/booking-success/booking-success";
import AppRoute from "../common/hoc/app-route";
import BookingPayment from "./flight-booking/views/booking-payment/booking-payment";
import FlightSearch from "./views/flight-search/flight-search";
import BookingSuccessMobileApp from "./flight-booking/views/booking-success-mobile-app/booking-success-mobile-app";

const BookingEngine = ({ match }) => {
    return (
        <>
            <Route
                path={`${match.url}/flight/search`}
                component={SearchResult}
            />

            <AppRoute
                path={`${match.url}/flight/passenger-details`}
                requiredSessionStore={["ss", "rd", "sqo", "cid"]}
                component={PassengerDetails}
            />
            <AppRoute
                path={`${match.url}/flight/booking-payment`}
                requiredSessionStore={["ss", "rd", "sqo", "ps", "cid"]}
                component={BookingPayment}
            />

            <Route
                path={`${match.url}/flight/booking-success`}
                component={BookingSuccess}
            />

            {/* Mobile app payment response page for in app browser */}
            <Route
                path={`${match.url}/flight/mobile-app/payment-response`}
                component={BookingSuccessMobileApp}
            />

            <Route path={`${match.url}/login`} component={Login} />
            <Route path={`${match.url}/register`} component={Register} />

            <Route exact path={match.path} component={Home} />
            <Route
                exact
                path={`${match.path}/search-widget/flight`}
                component={FlightSearch}
            />
        </>
    );
};

export default BookingEngine;
