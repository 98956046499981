import React, { Fragment } from 'react';
import MenuTable from './menu-table/menu-table';

const MenuItem = (props) => {

    return (
        <Fragment>
            <MenuTable />
        </Fragment>
    );
};

export default MenuItem;