import React from 'react';
import BookingEngineHeader from '../../components/booking-engine-header/booking-engine-header';
import BookingEngineFooter from '../../components/booking-engine-footer/booking-engine-footer';
import BookingEngineView from '../../components/booking-engine-view';
import FlightSearchWidget from '../../flight-booking/components/flight-search-widget/flight-search-widget';
import { generateQueryUrl } from '../../../common/util';
import './home.scss';

const Home = (props) => {

    const handleSearchSubmit = searchQuearyObject => {
        props.history.push({
            pathname: '/booking-engine/flight/search',
            search: '?' + generateQueryUrl(searchQuearyObject)
        });
    };

    return (
        <>
            <BookingEngineHeader />
            <BookingEngineView>
                <div className="container-fluid home-banner">
                    <div className="container">
                        <h2 className="text-light text-right">FLIGHT SEARCH</h2>
                        <FlightSearchWidget searchSubmit={handleSearchSubmit} />
                    </div>
                </div>

                <div className="container">
                    <p className="mt-2 text-center">This is a sample search widget page. you will be able to see widget on your website.</p>
                </div>
            </BookingEngineView>
            <BookingEngineFooter />
        </>
    );

};

export default Home;

// {
//     "adults": 1,
//     "children": 1,
//     "infants": 1,
//     "bookingClass": "M",
//     "flightType": "A",
//     "travelItineraries": [
//         {
//             "boardPoint": "LHR",
//             "offPoint": "JFK",
//             "departureDate": "2019-09-10"
//         },
//         {
//             "boardPoint": "JFK",
//             "offPoint": "LHR",
//             "departureDate": "2019-09-10"
//         }
//     ]
// }