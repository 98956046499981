import React from 'react';
import BookingEngineHeader from '../../components/booking-engine-header/booking-engine-header';
import BookingEngineFooter from '../../components/booking-engine-footer/booking-engine-footer';
import BookingEngineView from '../../components/booking-engine-view';
import { NavLink } from 'react-router-dom';

const Login = () => {

    return (
        <>
            <BookingEngineHeader />
            <BookingEngineView>
                <div className="container mt-3">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <i className="fa fa-5x fa-user-circle my-3 text-muted"></i>
                                        <h1 className="text-primary">Sign In</h1>
                                    </div>

                                    Login User

                                    <NavLink
                                        to="/booking-engine/register"
                                        className="d-block m-2 small text-center text-primary"
                                    >
                                        Create new account
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BookingEngineView>
            <BookingEngineFooter />
        </>
    );

};


export default Login;