import React from 'react';
import './search-loader.scss';
import { SESSIONSTORE } from '../../../../common/storage';
// import SearchingLoader from "../../../../assets/images/flight-search-loader.gif";

const SearchLoader = () => {
    // get airport details from sessionStorage
    const airportNameBroadPoint = SESSIONSTORE.get('anbp');
    const airportNameOffPoint = SESSIONSTORE.get('anop');

    return (
        <div className="container h-100">
            <div className="row justify-content-center align-items-center search-loader">
                <div className="font-weight-bold h6 d-none d-sm-block">{airportNameBroadPoint ? airportNameBroadPoint.apCityName : ''}</div>
                <div className="font-weight-bold h6 d-block d-sm-none">{airportNameBroadPoint ? airportNameBroadPoint.apIataCode3 : ''}</div>
                <div className="col-8 col-sm-5 text-center">
                    <div className="">
                        <svg className="loader-svg" viewBox="0 0 500.87 226.96">
                            <path id="motionPath" d="M38.63,102.32
                                c40.72-28.82,245.98-158.99,429.19,1"/>
                            <circle className="circ" cx="30.4" cy="108.6" r="9.92"/>
                            <circle className="circ" cx="472.7" cy="110.6" r="9.92"/>
                            <path id="plane" transform="rotate(28) translate(-74, -84)" d="M79.94,77.97c0,0-2.06-2.2-4.56-0.77c-2.02,1.16-3.28,1.62-3.28,1.62l-20.96-8.23l-2.3,1.41
                                l16.19,10.53L52.1,92.81l-8.37-3.2c-1.65,1.09-1.08,2.98-1.08,2.98l7.56,3.67l0.33,8.49c0,0,1.5,1.43,2.91,0.65l1.11-8.95l14.6-6.98
                                l2.15,19.16l2.4-1.48l1.99-22.59l3.07-2.2C78.76,82.35,81.11,80.76,79.94,77.97z"/>
                            <animateMotion
                                rotate="auto"
                                xlinkHref="#plane"
                                dur="3s"
                                repeatCount="indefinite"           
                                >
                                <mpath xlinkHref="#motionPath" />
                            </animateMotion>
                        </svg>
                    </div>
                    <div className="text-muted ">Available airfares will be displayed shortly</div>
                </div>
                <div className="font-weight-bold h6 d-none d-sm-block">{airportNameOffPoint ? airportNameOffPoint.apCityName : ''}</div>
                <div className="font-weight-bold h6 d-block d-sm-none">{airportNameOffPoint ? airportNameOffPoint.apIataCode3 : ''}</div>
            </div>
        </div>
    );
};

export default SearchLoader;