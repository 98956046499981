import React from 'react';
import { Modal } from 'react-bootstrap';
import { getFlightPricing, getMiniRules } from '../../../../api/flight-booking.api';
// import fareRuleMessages from './fare-rule-messages-utll';
import UiLoader from '../../../../common/components/ui-loader/ui-loader';
import { SESSIONSTORE } from '../../../../common/storage';
import FareRuleMsg from './fare-rule-msg';
import './fare-rules-modal.scss';

const FareRulesModal = ({
    show,
    handleClose,
    flightPricingDetails
}) => {

    const [loading, setLoading] = React.useState(true);
    const [fareRulesData, setFareRulesData] = React.useState(null);
    let pricingData = null;
    const flightPricingPayload = flightPricingDetails ? flightPricingDetails : SESSIONSTORE.get('cfpd');

    /* Handle modal show */
    const handleShow = () => {
        // request pricing details
        if (fareRulesData) {
            setLoading(false);
        } else if(flightPricingPayload) {
            getFlightPricing(flightPricingPayload)
                .then(res => {
                    pricingData = res.data;
                    // request fare rules data
                    getMiniRules({ sSession: pricingData.sSession })
                        .then(res => {
                            // fareRulesData = res.data;
                            // console.log(res.data);
                            setFareRulesData(res.data);
                            setLoading(false);
                        });
                });
        } else {
            setLoading(false);
        }
    }

    // ui to load after data is available
    const uiFareRulesList = (
        <div>
            {
                fareRulesData
                    ?
                    <div>
                        {
                            Object.keys(fareRulesData).map((category, index) => {
                                if (category !== 'sSession' && fareRulesData[category]) {
                                    return (
                                        <div key={index}>
                                            {
                                                category === 'adultFareRule' && fareRulesData.adultFareRule
                                                    ?
                                                    <div>
                                                        <h6 className="text-primary">Adults</h6>
                                                        {
                                                            fareRulesData.adultFareRule.reissueRule
                                                                ?
                                                                <FareRuleMsg data={fareRulesData.adultFareRule.reissueRule} type="reissue" />
                                                                :
                                                                <FareRuleMsg data={{}} type="reissue" />
                                                        }
                                                        {
                                                            fareRulesData.adultFareRule.refundRule
                                                                ?
                                                                <FareRuleMsg data={fareRulesData.adultFareRule.refundRule} type="refund" />
                                                                :
                                                                <FareRuleMsg data={{}} type="refund" />
                                                        }
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                category === 'childFareRule' && fareRulesData.childFareRule
                                                    ?
                                                    <div>
                                                        <h6 className="text-primary">Child</h6>
                                                        {
                                                            fareRulesData.childFareRule.reissueRule
                                                                ?
                                                                <FareRuleMsg data={fareRulesData.childFareRule.reissueRule} type="reissue" />
                                                                :
                                                                <FareRuleMsg data={{}} type="reissue" />
                                                        }
                                                        {
                                                            fareRulesData.childFareRule.refundRule
                                                                ?
                                                                <FareRuleMsg data={fareRulesData.childFareRule.refundRule} type="refund" />
                                                                :
                                                                <FareRuleMsg data={{}} type="refund" />
                                                        }
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                category === 'infantFareRule' && fareRulesData.infantFareRule
                                                    ?
                                                    <div>
                                                        <h6 className="text-primary">Infant</h6>
                                                        {
                                                            fareRulesData.childFareRule.reissueRule
                                                                ?
                                                                <FareRuleMsg data={fareRulesData.childFareRule.reissueRule} type="reissue" />
                                                                :
                                                                <FareRuleMsg data={{}} type="reissue" />
                                                        }
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    )
                                }
                                return null;
                            })
                        }
                        <div> <i className="fa fa-info-circle" aria-hidden="true"></i> <span className="text-danger ml-2"> Penalty fees are in addition to any difference in fare </span>  </div>
                        <div> <i className="fa fa-info-circle" aria-hidden="true"></i> <span className="text-danger ml-2"> Check full fare conditions for additional information </span>  </div>
                    </div>
                    :
                    null
            }
        </div>
    );

    return (
        <Modal dialogClassName="modal-50w" onShow={handleShow} show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Fare Rules</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    {(loading && !fareRulesData) ?
                        <UiLoader /> :
                        uiFareRulesList
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-default" onClick={handleClose}>Close</button>
            </Modal.Footer>
        </Modal>
    );
};

export default FareRulesModal;