import React, { Fragment, useEffect, useRef, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import ActiveAgentTable from './active-agent/active-agent-table';
import PendingAgentTable from './pending-agent/pending-agent-table';
import DisabledAgentTable from './disabled-agent/disabled-agent-table';
import { getAllAgents } from '../../api/admin-api';
import UiLoader from '../../../common/components/ui-loader/ui-loader';
import { Button, Modal } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ReactGoogleMapsLoader from 'react-google-maps-loader';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';
import { googleMap } from '../../../booking-engine/booking-enigne-config'
import { getCountryCodeByCountry, saveAgentDetailsAdmin, userBookingRegisterSuccess } from '../../../api/flight-booking.api'
import { uploadAgentUserIdentificationDoc } from '../../../api/storage.api';

const Agent = () => {

    useEffect(() => {
        getAllAgentsFunc()
        // eslint-disable-next-line
    }, [])

    const formRef = useRef();

    const [activeAgents, setActiveAgents] = useState(0);
    const [pendingAgents, setPendingAgents] = useState(0);
    const [dissabledAgents, setDissabledAgents] = useState(0);
    const [updating, setUpdating] = useState(false);

    const [search, setSearch] = useState('');
    const [mapData, setMapData] = useState('');
    const [mapDataCountry, setMapDataCountry] = useState([]);

    const [lgShow, setLgShow] = useState(false);

    const handleClose = () => setLgShow(false);

    const getAllAgentsFunc = () => {
        setUpdating(true);
        getAllAgents().then((res) => {

            const allAgents = res.data;
            if (Object.keys(allAgents).length > 0) {
                setActiveAgents(allAgents.activeb2busers);
                setDissabledAgents(allAgents.disabledb2busers)
                setPendingAgents(allAgents.pendingb2busers);
                setUpdating(false);
            } else {
                setActiveAgents(false);
                setDissabledAgents('')
                setPendingAgents('');
                setUpdating(false);
            }
        })
            .catch((err) => {
                setUpdating(false);
                console.log(err);
                setActiveAgents(false);
                handleModalAgentFailed()
            })

    }

    const update = async () => {
        await getAllAgentsFunc()
    }

    const handleModalDataSaveSuccess = (name) => {
        notifyAgentDataSaveSuccess(name)
    }
    const handleModalAgentEmailFailed = (name) => {
        notifyAgentEmailFailed(name)
    }
    const handleModalAgentDeactivateSuccess = (name) => {
        notifyAgentDeactivateSuccess(name)
    }
    const handleModalAgentActivatedSuccess = (name) => {
        notifyAgentActiveSuccess(name)
    }
    const handleModalAgentFailed = () => {
        notifyAgentFailed()
    }

    const handleModalAgentGrantSuccess = (name) => {
        notifyAgentGrantSuccess(name)
    }

    const handleModalAgentRevokeSuccess = (name) => {
        notifyAgentRevokeSuccess(name)
    }

    // notification when successfull grant an agent
    const notifyAgentGrantSuccess = (name) => toast.success(`successfully grant B2B access of agent, ${name ? name : ''}!`, {
        containerId: 'notifyAgentGrantSuccess',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
    });

    // notification when successfull revoke an agent
    const notifyAgentRevokeSuccess = (name) => toast.warn(`successfully revoke B2B access of agent, ${name ? name : ''}!`, {
        containerId: 'notifyAgentRevokeSuccess',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
    });

    // notification when successfull activate an agent
    const notifyAgentActiveSuccess = (name) => toast.success(`successfully activated the agent, ${name ? name : ''}!`, {
        containerId: 'notifyAgentActiveSuccess',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
    });

    // notification when successfull deactivate an agent
    const notifyAgentDeactivateSuccess = (name) => toast.warn(`successfully deactivate of agent, ${name ? name : ''}!`, {
        containerId: 'notifyAgentDeactivateSuccess',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
    });

    // notification when successfull data changed
    const notifyAgentDataSaveSuccess = (name) => toast.success(`successfully data saved of the agent, ${name ? name : ''}!`, {
        containerId: 'notifyAgentDataSaveSuccess',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
    });
    // notification when successfull data changed
    const notifyAgentEmailFailed = (name) => toast.error(`email sending failed of, ${name ? name : ''}!`, {
        containerId: 'notifyAgentEmailFailed',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
    });

    // notification when failed
    const notifyAgentFailed = () => toast.error(`oops, something went wrong!`, {
        containerId: 'notifyAgentFailed',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
    });

    const schema = Yup.object().shape({
        b2buTelephone: Yup.string()
            .min(10, 'Telephone must contain at least 10 digits')
            .max(13, "Telephone can't exceed 13 digits")
            .required('Telephone is required'),
        b2buGender: Yup.string(),
        b2buCity: Yup.string()
            .required('City is required'),
        b2buCopyResEmailsTo: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        b2buIdentyficationDoc : Yup.object().nullable(),
        b2buCompanyName: Yup.string()
            .required('Company Name is required'),
        b2buCompanyTradingName: Yup.string()
            .required('Trading Name is required'),
        b2buABNACN: Yup.string(),
        b2buAddress: Yup.string()
            .required('Address is required'),
        b2buCompanyEmail: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        b2buCompanyTelephone: Yup.string()
            .min(10, 'Telephone must contain at least 10 digits')
            .max(13, "Telephone can't exceed 13 digits")
            .required('Telephone is required'),
        b2buDiscount: Yup.number()
            .min(0, 'Can not be negative')
            .lessThan(99, 'can not be exceed 99')
            .required('Required'),
        // cognito details
        cognito_username: Yup.string()
            .email('User email is invalid')
            .required('User email is required'),
        cognito_given_name: Yup.string()
            .required('First name is required'),
        cognito_family_name: Yup.string()
            .required('Last name is required'),
        // cognito_phone_number : "",
    });

    const agentCreate = () => {
        const formData = formRef.current.values;
        const hiddenFieldsWhenSave = {
            b2buLocationId: mapData.place_id,
            b2buCountryCode: mapDataCountry.short_name,
            b2buCountryName: mapDataCountry.long_name,
            b2buPaymentMode: 'CARD',
            cognitoUserAttributes : {
                username: formData.cognito_username,
                userAttributesMap: {
                    given_name: formData.cognito_given_name,
                    family_name: formData.cognito_family_name,
                    phone_number: formData.b2buTelephone
                }
            }
        };
        const dataToSendWhenSaving = { ...formData, ...hiddenFieldsWhenSave };
        saveAgentDetailsAdmin(dataToSendWhenSaving).then((res) => {
            
            if (dataToSendWhenSaving.b2buIdentyficationDoc) {
                handleModalDataSaveSuccess(res.data.cognitoUserAttributes.userAttributesMap.family_name);
                uploadAgentUserIdentificationDoc(res.data.userIdentityServiceId,dataToSendWhenSaving.b2buIdentyficationDoc)
                .then(() => {
                    userBookingRegisterSuccess().then(() => {
                        console.log('called');
                    })
                    .catch(() => {
                        handleModalAgentEmailFailed('')
                    });
                });
            } else {
                handleModalDataSaveSuccess(res.data.cognitoUserAttributes.userAttributesMap.family_name);
                userBookingRegisterSuccess().then(() => {
                    console.log('called');
                });
            }

            handleClose();
        })
        .catch((err) => {
            handleModalAgentFailed()
        })
        
    }

    const countryDetails = (data) => data.address_components.filter(list => {
        if (list.types.includes('country')) {
            setMapDataCountry(list)

            console.log(mapDataCountry);
            getCountryCodeByCountry(mapDataCountry.long_name).then((res) => {
                console.log(res.data);
            })
                .catch((err) => {
                    console.log(err);
                })
        }
        return list
    });

    return (
        <Fragment>
            {
                updating ?
                    <UiLoader />
                    :

                    <div className="row">
                        
                        <div className="col-12">
                            <div className="float-lg-right">
                                <Button onClick={() => setLgShow(true)}>Create Agent</Button>
                            </div>
                        </div>

                        <div className="col-12 agent mt-2 mt-lg-4">

                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <a className="nav-item nav-link active" id="nav-active-tab" data-toggle="tab" href="#nav-active" role="tab" aria-controls="nav-active" aria-selected="true">Active Agents</a>
                                    <a className="nav-item nav-link" id="nav-pending-tab" data-toggle="tab" href="#nav-pending" role="tab" aria-controls="nav-pending" aria-selected="false">Pending Agents</a>
                                    <a className="nav-item nav-link" id="nav-dissabled-tab" data-toggle="tab" href="#nav-dissabled" role="tab" aria-controls="nav-dissabled" aria-selected="false">Dissabled Agents</a>
                                </div>
                            </nav>

                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-active" role="tabpanel" aria-labelledby="nav-active-tab">
                                    <div className="mt-3 w-100">
                                        {
                                            activeAgents.length > 0 ?
                                                // activeAgents.map(activeAgent => (
                                                <ActiveAgentTable
                                                    data={activeAgents}
                                                    update={update}
                                                    modalAgentDataChanged={handleModalDataSaveSuccess}
                                                    modalAgentDeactivated={handleModalAgentDeactivateSuccess}
                                                    modalAgentActivated={handleModalAgentActivatedSuccess}
                                                    modalAgentGrant={handleModalAgentGrantSuccess}
                                                    modalAgentRevoke={handleModalAgentRevokeSuccess}
                                                    modalAgentFailed={handleModalAgentFailed}
                                                />
                                                // ))
                                                :
                                                <ActiveAgentTable
                                                    data={[]}
                                                />
                                        }
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-pending" role="tabpanel" aria-labelledby="nav-pending-tab">
                                    <div className="mt-3 w-100">
                                        {
                                            pendingAgents.length > 0 ?
                                                // pendingAgents.map(pendingAgent => (
                                                <PendingAgentTable
                                                    data={pendingAgents}
                                                    update={update}
                                                    modalAgentDataChanged={handleModalDataSaveSuccess}
                                                    modalAgentDeactivated={handleModalAgentDeactivateSuccess}
                                                    modalAgentActivated={handleModalAgentActivatedSuccess}
                                                    modalAgentGrant={handleModalAgentGrantSuccess}
                                                    modalAgentRevoke={handleModalAgentRevokeSuccess}
                                                    modalAgentFailed={handleModalAgentFailed}
                                                />
                                                // ))
                                                :
                                                <PendingAgentTable
                                                    data={[]}
                                                />
                                        }

                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-dissabled" role="tabpanel" aria-labelledby="nav-dissabled-tab">
                                    <div className="mt-3 w-100">
                                        {
                                            dissabledAgents.length > 0 ?
                                                // dissabledAgents.map(dissabledAgent => (
                                                <DisabledAgentTable
                                                    data={dissabledAgents}
                                                    update={update}
                                                    modalAgentDataChanged={handleModalDataSaveSuccess}
                                                    modalAgentDeactivated={handleModalAgentDeactivateSuccess}
                                                    modalAgentActivated={handleModalAgentActivatedSuccess}
                                                    modalAgentGrant={handleModalAgentGrantSuccess}
                                                    modalAgentRevoke={handleModalAgentRevokeSuccess}
                                                    modalAgentFailed={handleModalAgentFailed}
                                                />
                                                // ))
                                                :
                                                <DisabledAgentTable
                                                    data={[]}
                                                />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            <ToastContainer enableMultiContainer containerId={'notifyAgentActiveSuccess'} position={toast.POSITION.BOTTOM_RIGHT} />
            <ToastContainer enableMultiContainer containerId={'notifyAgentDeactivateSuccess'} position={toast.POSITION.BOTTOM_RIGHT} />
            <ToastContainer enableMultiContainer containerId={'notifyAgentGrantSuccess'} position={toast.POSITION.BOTTOM_RIGHT} />
            <ToastContainer enableMultiContainer containerId={'notifyAgentRevokeSuccess'} position={toast.POSITION.BOTTOM_RIGHT} />
            <ToastContainer enableMultiContainer containerId={'notifyAgentDataSaveSuccess'} position={toast.POSITION.BOTTOM_RIGHT} />
            <ToastContainer enableMultiContainer containerId={'notifyAgentEmailFailed'} position={toast.POSITION.BOTTOM_RIGHT} />
            <ToastContainer enableMultiContainer containerId={'notifyAgentFailed'} position={toast.POSITION.BOTTOM_RIGHT} />


            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                dialogClassName="modal-70w"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Create Agent
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <Formik
                                innerRef={formRef}
                                enableReinitialize
                                initialValues={{
                                    b2buTelephone: "",
                                    b2buGender: "Male",
                                    b2buCity: "",
                                    b2buCopyResEmailsTo: "",
                                    b2buIdentyficationDoc : null,
                                    b2buDiscount : 0,
                                    // cognito user details
                                    cognito_username : "",
                                    cognito_given_name : "",
                                    cognito_family_name : "",
                                    // company
                                    b2buCompanyName: "",
                                    b2buCompanyTradingName: "",
                                    b2buABNACN: "",
                                    b2buAddress: "",
                                    b2buCompanyEmail: "",
                                    b2buCompanyTelephone: ""
                                }}
                                validationSchema={schema}

                                onSubmit={(values, { setSubmitting }) => {
                                    // console.log('in hopuse values : ', values);
                                    agentCreate();
                                    setSubmitting(false);
                                }}
                            >
                                {({ errors, values, touched, isValidating, setFieldValue, setFieldTouched, isSubmitting, handleReset, handleChange }) => (
                                    <Form >

                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <div className="mb-3 h5">
                                                    Agent information
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="cognito_given_name" className="col-sm-4 col-form-label"> First Name</label>
                                                    <div className="col-sm-8">
                                                        <Field className={'form-control' + (errors.cognito_given_name && touched.cognito_given_name ? ' is-invalid' : '')} name="cognito_given_name" type="text" placeholder="AWS User Give Name" />
                                                        <ErrorMessage name="cognito_given_name" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="cognito_family_name" className="col-sm-4 col-form-label"> Last Name</label>
                                                    <div className="col-sm-8">
                                                        <Field className={'form-control' + (errors.cognito_family_name && touched.cognito_family_name ? ' is-invalid' : '')} name="cognito_family_name" type="text" placeholder="AWS User Family Name" />
                                                        <ErrorMessage name="cognito_family_name" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="cognito_username" className="col-sm-4 col-form-label">Email</label>
                                                    <div className="col-sm-8">
                                                        <Field className={'form-control' + (errors.cognito_username && touched.cognito_username ? ' is-invalid' : '')} name="cognito_username" type="text" placeholder="AWS User Email" />
                                                        <ErrorMessage name="cognito_username" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="b2buGender" className="col-sm-4 col-form-label">Gender</label>
                                                    <div className="col-sm-8">

                                                        <div role="group" aria-labelledby="my-radio-group">
                                                            <label className="mr-4">
                                                                <Field type="radio" name="b2buGender" className="mr-2" value="Male" defaultChecked />
                                                                Male
                                                            </label>
                                                            <label className="mr-4">
                                                                <Field type="radio" name="b2buGender" className="mr-2" value="Female" />
                                                                Femail
                                                            </label>
                                                            <label className="">
                                                                <Field type="radio" name="b2buGender" className="mr-2" value="Other" />
                                                                Other
                                                            </label>
                                                        </div>

                                                        {/* <Field className={'form-control' + (errors.b2buGender && touched.b2buGender ? ' is-invalid' : '')} name="b2buGender" type="number" /> */}
                                                    </div>
                                                </div>

                                                <div className="form-group row" >
                                                    <label htmlFor="b2buCity" className="col-sm-4 col-form-label">City</label>
                                                    <div className="col-sm-8">
                                                        <ReactGoogleMapsLoader
                                                            params={{
                                                                key: googleMap,
                                                                libraries: "places,geocode",
                                                            }}
                                                            render={googleMaps =>
                                                                googleMaps && (
                                                                    <ReactGooglePlacesSuggest
                                                                        googleMaps={googleMaps}
                                                                        autocompletionRequest={{
                                                                            input: search
                                                                        }}
                                                                        onSelectSuggest={suggest => {
                                                                            setMapData(suggest);
                                                                            setFieldValue('b2buCity', suggest.formatted_address)
                                                                            setSearch("");
                                                                            countryDetails(suggest);
                                                                        }}
                                                                        customRender={prediction => (
                                                                            <div className="customWrapper">
                                                                                {prediction
                                                                                    ? prediction.description
                                                                                    : "no results found"}
                                                                            </div>
                                                                        )}
                                                                    >
                                                                        <Field
                                                                            onChange={e => {
                                                                                setSearch(e.target.value);
                                                                                handleChange(e);
                                                                            }}
                                                                            className={'form-control' + (errors.b2buCity && touched.b2buCity ? ' is-invalid' : '')}
                                                                            name="b2buCity" type="text" placeholder="City" />
                                                                        <ErrorMessage name="b2buCity" component="div" className="invalid-feedback" />
                                                                    </ReactGooglePlacesSuggest>
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="b2buTelephone" className="col-sm-4 col-form-label">Telephone</label>
                                                    <div className="col-sm-8">
                                                        <Field className={'form-control' + (errors.b2buTelephone && touched.b2buTelephone ? ' is-invalid' : '')} name="b2buTelephone" type="text" placeholder="Telephone" />
                                                        <ErrorMessage name="b2buTelephone" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="b2buIdentyficationDoc" className="col-sm-4 col-form-label">Identyfication Doc</label>
                                                    <div className="col-sm-8">
                                                        <input id="b2buIdentyficationDoc" name="b2buIdentyficationDoc" type="file" onChange={(event) => {
                                                            setFieldValue("b2buIdentyficationDoc", event.currentTarget.files[0]);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="b2buCopyResEmailsTo" className="col-sm-4 col-form-label">Copy Reservation Emails To</label>
                                                    <div className="col-sm-8">
                                                        <Field className={'form-control' + (errors.b2buCopyResEmailsTo && touched.b2buCopyResEmailsTo ? ' is-invalid' : '')} name="b2buCopyResEmailsTo" type="text" placeholder="Email" />
                                                        <ErrorMessage name="b2buCopyResEmailsTo" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="b2buDiscount" className="col-sm-4 col-form-label">Discount (%)</label>
                                                    <div className="col-sm-8">
                                                        <Field className={'form-control' + (errors.b2buDiscount && touched.b2buDiscount ? ' is-invalid' : '')} name="b2buDiscount" type="number" />
                                                        {touched.b2buDiscount && errors.b2buDiscount && <div className="text-secondary small">{errors.b2buDiscount}</div>}
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-12 col-lg-6">
                                                <div className="mb-3 h5">
                                                    Company information
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="b2buCompanyName" className="col-sm-4 col-form-label">Company Register Name</label>
                                                    <div className="col-sm-8">
                                                        <Field className={'form-control' + (errors.b2buCompanyName && touched.b2buCompanyName ? ' is-invalid' : '')} name="b2buCompanyName" type="text" placeholder="Company Name" />
                                                        <ErrorMessage name="b2buCompanyName" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="b2buCompanyTradingName" className="col-sm-4 col-form-label">Company Trading Name</label>
                                                    <div className="col-sm-8">
                                                        <Field className={'form-control' + (errors.b2buCompanyTradingName && touched.b2buCompanyTradingName ? ' is-invalid' : '')} name="b2buCompanyTradingName" type="text" placeholder="Company Trading Name" />
                                                        <ErrorMessage name="b2buCompanyTradingName" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="b2buABNACN" className="col-sm-4 col-form-label">ABN/ACN</label>
                                                    <div className="col-sm-8">
                                                        <Field className={'form-control' + (errors.b2buABNACN && touched.b2buABNACN ? ' is-invalid' : '')} name="b2buABNACN" type="text" placeholder="ABN/ACN" />
                                                        <ErrorMessage name="b2buABNACN" component="div" className="invalid-feedback" /> 
                                                    </div>
                                                </div>
                                                <div className="form-group row mt-2 mt-lg-4">
                                                    <label htmlFor="b2buAddress" className="col-sm-4 col-form-label">Address</label>
                                                    <div className="col-sm-8">
                                                        <Field className={'form-control' + (errors.b2buAddress && touched.b2buAddress ? ' is-invalid' : '')} name="b2buAddress" type="text" placeholder="Enter Company Address" />
                                                        <ErrorMessage name="b2buAddress" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                                <div className="form-group row mt-2 mt-lg-4">
                                                    <label htmlFor="b2buCompanyEmail" className="col-sm-4 col-form-label">Email</label>
                                                    <div className="col-sm-8">
                                                        <Field className={'form-control' + (errors.b2buCompanyEmail && touched.b2buCompanyEmail ? ' is-invalid' : '')} name="b2buCompanyEmail" type="email" placeholder="Enter Company Email" />
                                                        <ErrorMessage name="b2buCompanyEmail" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                                <div className="form-group row mt-2 mt-lg-4">
                                                    <label htmlFor="b2buCompanyTelephone" className="col-sm-4 col-form-label">Phone no</label>
                                                    <div className="col-sm-8">
                                                        <Field className={'form-control' + (errors.b2buCompanyTelephone && touched.b2buCompanyTelephone ? ' is-invalid' : '')} name="b2buCompanyTelephone" type="text" placeholder="Enter Copmany Phone no" />
                                                        <ErrorMessage name="b2buCompanyTelephone" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <Modal.Footer>
                                            <Button type="button" variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button>
                                            <Button type="submit" variant="primary" >
                                                Save
                                            </Button>

                                        </Modal.Footer>
                                    </Form>
                                )}
                            </Formik>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </Fragment>
    )
}

export default Agent;
