import React, { Fragment } from 'react'
import BookingEngineHeader from '../../components/booking-engine-header/booking-engine-header'
import BookingEngineView from '../../components/booking-engine-view'
import AgentForm from './agent-form';
import { getCurrentUser } from '../../../aws-cognito/aws-functions';
import Unauthorized from '../../../common/views/unauthorized';


const AgentProfile = () => {

    const currentUser = getCurrentUser()

    return (
        <Fragment>
            {
                currentUser && currentUser.groupConfig.groups.includes('B2B') ?
                    <Fragment>
                        <BookingEngineHeader />
                        <BookingEngineView>
                            <div className="pending-agent-message">
                                <div className="container">
                                    <div className="mt-5">
                                        <div className="col-12 col-lg-8">
                                            <AgentForm edit />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </BookingEngineView>
                    </Fragment>
                    :
                    <Unauthorized />
            }
        </Fragment>
    )

}

export default AgentProfile
