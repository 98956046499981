import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import moment from "moment";
import { searchAirport } from "../../../../api/flight-booking.api";
import { SESSIONSTORE } from '../../../../common/storage';
import './trip-item.scss';

const TripItem = ({
    index,
    boardPoint,
    offPoint,
    departureDate,
    arrivalDate,
    minDepartureDate,
    enableArrivalDateSelect,
    enableRemoveOption,
    enableAddOption,
    showValidationErrors,
    onBoardPointChange,
    onOffPointChange,
    onDepartureDateChange,
    onArrivalDateChange,
    onAddItem,
    onRemoveItem
}) => {

    ///////////////////// local state //////////////////
    const [FlyFromData, setFlyFromData] = useState();
    const [FlyToData, setFlyToData] = useState();


    /* Ui for the drop down of the airport select */
    const formatAirportSelect = (selectedObj, options) => {
        if (options.context === "menu") {
            // option ui
            return (
                <div className="airport-option">
                    <div className="text-1">
                        {selectedObj.apIataCode3} :
                        <span className="small">{selectedObj.apName}</span>
                    </div>
                    <div className="text-2">{selectedObj.apCityName} </div>
                </div>
            );
        } else {
            // selected value ui
            return <div>{selectedObj.apIataCode3}</div>;
        }
    };

    /* Search airtport list promise */
    const airportListLoadPromise = (inputValue, searchType) => {

        return new Promise((resolve, reject) => {
            if (inputValue.length >= 3) {
                searchAirport(inputValue).then(res => {
                    let airportList = [];
                    if (searchType === "offpoint") {
                        res.data.forEach(airport => {
                            /////////// old logic ////////////////////////////
                            // if (airport.apIataCode3 !== boardPoint && airport.apCountryCodeIso === 'AU') {
                            //     // SESSIONSTORE.save("anop", airport);
                            //     airportList.push(airport);
                            // }
                            if (airport.apIataCode3 !== boardPoint) {
                                // SESSIONSTORE.save("anop", airport);

                                if(!FlyFromData){
                                    airportList.push(airport);
                                }else{
                                    if(FlyFromData?.apCountryCodeIso === 'AU'){
                                        airportList.push(airport);
                                    }else{
                                        if(airport.apCountryCodeIso === 'AU'){
                                            airportList.push(airport); 
                                        }
                                    }
                                }
                            }
                        });
                    } else {
                        res.data.forEach(airport => {
                            /////////// old logic ////////////////////////////
                            // if (airport.apIataCode3 !== offPoint && airport.apCountryCodeIso === 'AU') {
                            //     // SESSIONSTORE.save("anbp", airport);
                            //     airportList.push(airport);
                            // }
                            if (airport.apIataCode3 !== offPoint) {
                                // SESSIONSTORE.save("anbp", airport);
                                if(!FlyToData){
                                    airportList.push(airport);
                                }else{
                                    if(FlyToData?.apCountryCodeIso === 'AU'){
                                        airportList.push(airport);
                                    }else{
                                        if(airport.apCountryCodeIso === 'AU'){
                                            airportList.push(airport); 
                                        }
                                    }
                                }
                            }
                        });
                    }

                    resolve(airportList);
                });

            } else {
                reject();
            }
        });
    };


    return (
        <div className="trip-item">
            <div className="form-row">
                <div className="form-group col-lg-3">
                    <p className="d-md-block font-weight-bold m-0 mb-1">
                        Flying from
                    </p>

                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={searchVal => 
                            airportListLoadPromise(searchVal, "boardpoint")
                        }
                        placeholder="Select Airport"
                        formatOptionLabel={formatAirportSelect}
                        getOptionValue={obj => obj.apIataCode3}
                        defaultValue={
                            boardPoint ? { apIataCode3: boardPoint } : null
                        }
                        onChange={selected => {
                            setFlyFromData(selected);
                            onBoardPointChange(index, selected)
                            SESSIONSTORE.save("anbp", selected);
                        }
                        }
                    />
                    {showValidationErrors && !boardPoint ? (
                        <div className="text-danger small">
                            Please type Board point
                        </div>
                    ) : null}
                </div>
                <div className="form-group col-lg-3">
                    <p className="d-md-block font-weight-bold m-0 mb-1">
                        Flying to
                    </p>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={searchVal =>
                            airportListLoadPromise(searchVal, "offpoint")
                        }
                        placeholder="Select Airport"
                        formatOptionLabel={formatAirportSelect}
                        getOptionValue={obj => obj.apIataCode3}
                        defaultValue={offPoint ? { apIataCode3: offPoint } : null}
                        onChange={selected => {
                            setFlyToData(selected);
                            onOffPointChange(index, selected);
                            SESSIONSTORE.save("anop", selected);
                        }
                        }
                    />
                    {showValidationErrors && !offPoint ? (
                        <div className="text-danger small">
                            Please type Off point
                        </div>
                    ) : null}
                </div>
                <div className="form-group col-lg-3">
                    <p className="d-md-block font-weight-bold m-0 mb-1">
                        Departure
                    </p>
                    <DatePicker
                        className="form-control"
                        dateFormat="dd-MM-yyyy"
                        placeholderText="Select Date"
                        selected={departureDate ? new Date(departureDate) : null}
                        minDate={
                            minDepartureDate
                                ? new Date(minDepartureDate)
                                : new Date()
                        }
                        onChange={date =>
                            date !== null
                                ? onDepartureDateChange(
                                    index,
                                    moment(date).format("YYYY-MM-DD")
                                )
                                : false
                        }
                    />
                    {showValidationErrors && !departureDate ? (
                        <div className="text-danger small">Please select date</div>
                    ) : null}
                </div>

                {enableArrivalDateSelect ? (
                    <div className="form-group col-lg-3">
                        <p className="d-md-block font-weight-bold m-0 mb-1">
                            Return
                        </p>
                        <DatePicker
                            className="form-control"
                            dateFormat="dd-MM-yyyy"
                            placeholderText="Select Date"
                            selected={arrivalDate ? new Date(arrivalDate) : null}
                            minDate={
                                departureDate ? new Date(departureDate) : new Date()
                            }
                            maxDate={
                                departureDate ? moment(departureDate).add('360', 'days').toDate() : moment().add('360', 'days').toDate()
                            }
                            onChange={date =>
                                date !== null
                                    ? onArrivalDateChange(
                                        index,
                                        moment(date).format("YYYY-MM-DD")
                                    )
                                    : false
                            }
                        />
                        {showValidationErrors && !arrivalDate ? (
                            <div className="text-danger small">
                                Please select date
                            </div>
                        ) : null}
                    </div>
                ) : (
                        <div className="col-lg-3">
                            {enableRemoveOption ? (
                                <button
                                    className="btn btn-link text-danger"
                                    onClick={() => onRemoveItem(index)}
                                >
                                    Remove city
                                </button>
                            ) : null}
                            {enableAddOption ? (
                                <button
                                    className="btn btn-link float-right"
                                    onClick={() => onAddItem(index)}
                                >
                                    + ADD CITY
                                </button>
                            ) : null}
                        </div>
                    )}
            </div>
        </div>
    );
};

export default TripItem;
