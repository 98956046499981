import React, { Fragment, Component } from 'react';
import { fareManageList } from '../api/admin-api';
import { ToastContainer, toast } from 'react-toastify';
import AdminHeader from '../components/admin-header/admin-header';
import AdminFooter from '../components/admin-footer/admin-footer';
import FareItem from '../components/fare-manage/fare-item/fare-item';
import FareItemAdd from '../components/fare-manage/fare-item-add';
import UiLoader from '../../common/components/ui-loader/ui-loader';
import AdminView from '../components/admin-view';
import 'react-toastify/dist/ReactToastify.css';

class FareManage extends Component {
    constructor() {
        super();
        this.state = {
            fareList: null,
            updates: false
        }
    }

    componentDidMount() {
        this.handleFareManageList();
    }

    handleModalDataChangersSuccess = () => {
        this.notifyModalDataSuccess();
    }

    handleModalDataChangersFailed = () => {
        this.notifyModalDataFailed();
    }

    handleModalNoDataChangers = () => {
        this.notifyModalNoDataChange();
    }

    // notification when successfull attempt of change airline details
    notifyModalDataSuccess = () => toast.success(`Data Successfully Changed!`, {
        containerId: 'notifyModalDataSuccess',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
    });
    // notification when fail attempt of change airline details
    notifyModalDataFailed = () => toast.error('Oops....Something went wrong. Try again!', {
        containerId: 'notifyModalDataFailed',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
    });
    // notification when no change of airline details
    notifyModalNoDataChange = () => toast.warn(`It's already added`, {
        containerId: 'notifyModalNoDataChange',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
    });

    // get fare manage list api call
    handleFareManageList = () => {
        this.setState({
            updates: true
        });
        fareManageList().then(res => {
            this.setState({
                fareList: res.data,
                updates: false,
            })
        }).catch(error => {
            this.setState({
                updates: false
            })
        }).finally(
            this.setState({
                updates: false
            })
        );
    }

    render() {
        if (this.state.updates) {
            return (
                <div>
                    <UiLoader />
                </div>
            )
        }

        return (
            <Fragment>
                <AdminHeader />
                <AdminView>
                    <div className="container">

                        <h3 className="text-primary mt-lg-2">
                            <i className="fa fa-paper-plane-o mr-2" aria-hidden="true"></i>Fare Manage Report
                        </h3>
                        <FareItemAdd updates={this.handleFareManageList} />
                        {
                            this.state.fareList ? this.state.fareList.map(
                                (fare) => (
                                    <FareItem
                                        fare={fare}
                                        key={fare.airlineCode}
                                        updates={this.handleFareManageList}
                                        modalChangers={this.handleModalDataChangersSuccess}
                                        modalFails={this.handleModalDataChangersFailed}
                                        modalNoChange={this.handleModalNoDataChangers} />
                                )
                            ) : // before any data entered
                                // <div>Not Available</div>
                                <UiLoader />
                        }

                        <ToastContainer enableMultiContainer containerId={'notifyModalDataSuccess'} position={toast.POSITION.BOTTOM_RIGHT} />
                        <ToastContainer enableMultiContainer containerId={'notifyModalDataFailed'} position={toast.POSITION.BOTTOM_RIGHT} />
                        <ToastContainer enableMultiContainer containerId={'notifyModalNoDataChange'} position={toast.POSITION.BOTTOM_RIGHT} />
                    </div>
                </AdminView>
                <AdminFooter />
            </Fragment>
        )
    }
}

export default FareManage;
