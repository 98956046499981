import React from 'react';
import { sendConfirmEmail } from '../../../api/flight-booking.api';
import successIcon from '../../../assets/images/success-icon.gif';
import { removeBookingSession } from '../../../common/util';
import { SESSIONSTORE } from '../../../common/storage';
import { checkStatus } from '../../payment-gateway/anz-worldline/anz-api';
import { withRouter } from "react-router";

class ProcessPaymentResponse extends React.Component {

    constructor(props) {
        super(props);
        this.confirmId = new URLSearchParams(props.location.search).get("confirm_id");
        this.ipg = new URLSearchParams(props.location.search).get("ipg");
        this.urlStatus = new URLSearchParams(props.location.search).get("status");
        this.state = {
            notifyError: null,
            paymentStatus: "processing" // "processing" | "success" | "failed" | "cancel" | "unknown"
        }
    }
    componentDidMount() {
        // remove booking session data
        removeBookingSession(['rd', 'confirmationSent']);
        this.checkPaymentStatus();
    }

    componentDidUpdate() {
        if (this.state.paymentStatus === "success" && !SESSIONSTORE.get('confirmationSent')) {
            this.sendEmail();
            SESSIONSTORE.save('confirmationSent', true);
        }
    }

    checkPaymentStatus() {
        if (!this.confirmId) return;

        if (this.ipg === 'anz') {
            // check if anz payment completed
            let counter = 0;
            const checkAnzStatus = () => {
                checkStatus(this.confirmId).then(res => {
                    switch (res.data.createdPaymentOutput.paymentStatusCategory) {
                        case "STATUS_UNKNOWN":
                            // check for 15 times maximum if payments get completed
                            if (counter < 15) {
                                checkAnzStatus();
                            } else {
                                this.setState({
                                    paymentStatus: "unknown"
                                });
                            }
                            break;
                        case "REJECTED":
                            this.setState({
                                paymentStatus: "failed"
                            });
                            break;
                        case "SUCCESSFUL":
                            this.setState({
                                paymentStatus: "success"
                            });
                            break;
                        default:
                            break;
                    }
                }).catch(err => {
                    console.log(err);
                });
                counter++;
            }
            checkAnzStatus();

        } else if (this.urlStatus) {
            // check with url status
            this.setState({
                paymentStatus: this.urlStatus
            });
        } else {
            this.setState({
                paymentStatus: "unknown"
            });
        }
    }

    sendEmail() {
        if (!this.confirmId) return;
        sendConfirmEmail(this.confirmId)
            .then(() => {
                console.log('mail sent');
            }).catch(() => {
                this.setState({
                    notifyError: {
                        body: "We are unable to send you the invoice. Please contact system administrator."
                    }
                });
            });
    }
    
    render() {

        if (!this.confirmId) {
            return (
                <>
                    <div className="container text-center booking-success-block">
                        <h1 className='text-danger'> Invalid Page </h1>
                    </div>
                </>
            );
        }

        const getDynamicUi = () => {
            switch (this.state.paymentStatus) {
                case "processing":
                    return <div className="container text-center booking-success-block">
                        <i style={{ fontSize: '60px' }} className='fa fa-spin fa-spinner text-info mb-2'></i>
                        <div className='h4'>We are processing your payment informations</div>
                        <div className='small'>Please wait...</div>
                    </div>
                case "success":
                    return <div className="container text-center booking-success-block">
                        <img style={{ maxWidth: '100px' }} className="success-icon mb-2 w-25" src={successIcon} alt="Success" />
                        <h1 className="text-success">Thank you!</h1>
                        <div className='h4'>Your booking has been successfully completed.</div>
                        <div className='small'> We have sent you an email with the booking details.</div>
                        <div className='small'>One of our travel consultant will give you a call back to reconfirm your booking and provide you with your final e-ticket.</div>
                    </div>
                case "failed":
                    return <div className="container text-center booking-success-block">
                        <h1 className="text-danger">Payment not completed</h1>
                        <div className='small'>Your payment has been failed.</div>
                    </div>
                case "cancel":
                    return <div className="container text-center booking-success-block">
                        <h1 className="text-danger">Payment not completed</h1>
                        <div className='small'>Your payment has been cancelled.</div>
                    </div>
                case "unknown":
                    return <div className="container text-center booking-success-block">
                        <h1 className="text-muted">Payment unavailable</h1>
                        <div className='small'>We could not retrieve your payment information.</div>
                    </div>
                default:
                    return null
            }
        }

        return (
            <>
                {getDynamicUi()}
                {
                    this.state.notifyError ?
                        // <NotifyModal
                        //     type="danger"
                        //     title="Oops! Something went wrong"
                        //     body={this.state.notifyError.body}
                        //     showSearchButton={true}
                        //     disableBackdrop={true}
                        //     disableClose={true}
                        // />
                        <div className="alert alert-danger mt-3 col-11 col-sm-8 mx-auto text-center">{this.state.notifyError.body}</div>
                        : null
                }
            </>
        );
    }
};

export default withRouter(ProcessPaymentResponse);
