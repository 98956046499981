import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { userInfo, authenticateUser } from '../api/aws-cognito.api';
import { awsRefreshTokenExpireTime, appRootURL } from '../aws-config';
import { setAccessToken, setIdToken, setRefreshToken, getCurrentUser } from '../aws-functions';
import UiLoader from './ui-loader/ui-loader';

const Signin = (props) => {

    // extract code from the aws response uri
    const code = new URLSearchParams(useLocation().search).get("code");
    const state = new URLSearchParams(useLocation().search).get("state");

    useEffect(() => {
        const currentU = getCurrentUser();

        // block going back after login
        if (currentU) {
            props.history.push({
                pathname: currentU.groupConfig.loginSuccessRedirectUrl
            })
        } else {
            authenticateUser(code).then(res => {

                if (res.status === 200) {
                    const access_token = res.data.access_token;
                    const id_token = res.data.id_token;
                    const refresh_token = res.data.refresh_token;
                    // const expires = res.data.expires_in;

                    setAccessToken(access_token, (awsRefreshTokenExpireTime));
                    setIdToken(id_token, (awsRefreshTokenExpireTime));
                    setRefreshToken(refresh_token, (awsRefreshTokenExpireTime));

                    //   aws user accessed information api call
                    userInfo().then(res => {
                        if (res.status === 200) {
                            const user = getCurrentUser();
                            if (state === 'B2B_AGENT') {
                                props.history.push({
                                    pathname: '/agent'
                                })
                            } else {
                                if (user.groupConfig.loginSuccessRedirectUrl === appRootURL) {
                                    window.location = appRootURL
                                } else {
                                    props.history.push({
                                        pathname: user.groupConfig.loginSuccessRedirectUrl
                                    })
                                }
                            }

                        }
                    })
                        .catch(err => {
                            console.log(err);
                        })
                }
            })
        }
    }, [props, code, state])

    return (
        <div>
            <UiLoader />
        </div >
    )
}

export default Signin
