import { api } from './api';

/** 
 * @function uploadAgentUserIdentificationDoc
 * @description Upload btb user identification doc
*/
export const uploadAgentUserIdentificationDoc = (userIdentityServiceId, file) => {
    const formData = new FormData();
    formData.append('file',file);
    return api.post('/storage/b2bdoc/'+userIdentityServiceId, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    });
}

/** 
 * @function deleteAgentUserIdentificationDoc
 * @description Delete btb user identification doc
*/
export const deleteAgentUserIdentificationDoc = (userIdentityServiceId) => {
    return api.delete('/storage/b2bdoc/'+userIdentityServiceId);
}

/** 
 * @function getAgentUserIdentificationDoc
 * @description Get btb user identification doc
*/
export const getAgentUserIdentificationDoc = (userIdentityServiceId) => {
    return api.get('/storage/b2bdoc/'+userIdentityServiceId);
}
