import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
// import DataTable from 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';
import './menu-table.scss';
import MenuDeleteModal from '../menu-delete/menu-delete-model';
import { menuGet } from '../../../api/cms.api';
import MenuCreateModal from '../menu-create/menu-create-model';
import { Button } from 'react-bootstrap';
import UiLoader from '../../../../common/components/ui-loader/ui-loader';

const MenuTable = () => {

    //set data
    const [data, setData] = useState('');

    // modal create display
    const [show, setShow] = useState(false);
    const showCreateModal = () => {
        setShow(true);
    }

    //loding
    const [updating, setUpdating] = useState(false);

    // delete modal display
    const [showDelete, setShowDelete] = useState(false);

    //fetch data
    const fetchAllData = () => {
        setUpdating(true);
        //fetch data
        menuGet().then((data) => {
            //initilize table
            $('#menutable').DataTable({
                order: [[2, "asc"]],
                data: data.data,
                columns: [
                    { title: "Name", searchable: true, data: "menuName" },
                    { title: "Page Url", searchable: true, data: "pageUrl" },
                    {
                        title: "Action",
                        data: 'id',
                        searchable: false,
                        sortable: false
                    }
                ],
                columnDefs: [
                    {
                        'targets': [2],
                        createdCell: (td, cellData, rowData, row, col) => {
                            ReactDOM.render(
                                <div className="text-center">
                                    {/* <i onClick={() => showEditModal()} className="fa fa-pencil-square fa-2x pointers mr-1 backcoloredit" aria-hidden="true"></i> */}
                                    <i onClick={() => showDeleteModal(rowData)} className="fa fa-window-close fa-2x pointers backcolordelete" aria-hidden="true"></i></div>
                                , td);
                        }
                    }
                ],
                paging: true,
                searching: true,
                destory: true
            });

            setUpdating(false);
        })
    }


    useEffect(() => {
        //fetch data
        const fetchAllDataEff = () => {
            setUpdating(true);
            //fetch data
            menuGet().then((data) => {
                //initilize table
                $('#menutable').DataTable({
                    order: [[2, "asc"]],
                    data: data.data,
                    columns: [
                        { title: "Name", searchable: true, data: "menuName" },
                        { title: "Page Url", searchable: true, data: "pageUrl" },
                        {
                            title: "Action",
                            data: 'id',
                            searchable: false,
                            sortable: false
                        }
                    ],
                    columnDefs: [
                        {
                            'targets': [2],
                            createdCell: (td, cellData, rowData, row, col) => {
                                ReactDOM.render(
                                    <div className="text-center">
                                        {/* <i onClick={() => showEditModal()} className="fa fa-pencil-square fa-2x pointers mr-1 backcoloredit" aria-hidden="true"></i> */}
                                        <i onClick={() => showDeleteModal(rowData)} className="fa fa-window-close fa-2x pointers backcolordelete" aria-hidden="true"></i></div>
                                    , td);
                            }
                        }
                    ],
                    paging: true,
                    searching: true,
                    destory: true
                });

                setUpdating(false);
            })
        }


        fetchAllDataEff();
    }, []);

    const showDeleteModal = (data) => {
        setData(data)
        setShowDelete(true);
    }

    //refresh data
    const refreshAllData = () => {
        $('#menutable').DataTable().destroy();
        //fetch data
        fetchAllData();
    }

    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <div className="float-lg-right">
                        <Button onClick={() => showCreateModal()}>Create Menu</Button>
                    </div>
                </div>

                {
                    updating ?
                        <UiLoader />
                        :
                        null
                }
                <div className="col-12 agent mt-2 mt-lg-4">
                    <table id="menutable" className="table display table-striped table-bordered w-100" >
                    </table>
                </div>
            </div>

            <MenuDeleteModal data={data} show={showDelete} onHide={() => setShowDelete(false)} refresh={() => refreshAllData()} />
            <MenuCreateModal show={show} onHide={() => setShow(false)} refresh={() => refreshAllData()} />
        </div>
    )
}

export default MenuTable
