import React from 'react';
import { NavLink } from 'react-router-dom';
import './admin-header.scss';
import { getCurrentUser } from '../../../aws-cognito/aws-functions';
import { awsSignOutUrl } from '../../../aws-cognito/aws-config';
import logoImg from '../../../assets/images/logo-primary-light.png';

const AdminHeader = () => {
    const user = getCurrentUser();
    return (
        <div className="admin-header">
            <nav className="navbar navbar-expand-md navbar-primary bg-primary">
                <NavLink to="/admin">
                    <img src={logoImg} alt="" className="navbar-brand img-fluid " />
                </NavLink>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <NavLink exact to="/admin" className="nav-link text-light text-center">
                                Dashboard
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/admin/faremanage" className="nav-link text-light text-center">
                                Fare Manage
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/admin/bookingreport" className="nav-link text-light text-center">
                                Booking Report
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/admin/agents" className="nav-link text-light text-center">
                                Agents
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/admin/menu-items" className="nav-link text-light text-center">
                                Menu Item
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/admin/sections" className="nav-link text-light text-center">
                                Sections
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <a href="/" target="_blank" className="nav-link text-light text-center">
                                Web Site
                            </a>
                        </li>
                    </ul>
                    <ul className="navbar-nav mr-lg-5">
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="##" id="admin-account" data-toggle="dropdown" aria-expanded="false">
                                {user.name}
                            </a>
                            <div className="dropdown-menu left-zero" aria-labelledby="admin-account">
                                <div className="ml-2">
                                    <div className="small text-muted">{user.groupConfig.displayName}</div>
                                    <a className="" data-placement="top" id="logout" title="Logout" href={awsSignOutUrl}>Logout  </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}

export default AdminHeader;
