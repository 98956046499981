import React, {useState, useEffect} from 'react';
import {Formik,Field, FieldArray, Form, getIn} from 'formik';
import * as Yup from 'yup';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import { getCountryList } from '../../../api/master-data.api';
import Select from 'react-select';
import { handleException } from '../../../common/util';

const PassengerDetailsForm = ({
    adults,
    children,
    infants,
    formRendered
}) => {

    const schema = Yup.object().shape({
        passengers: Yup.array()
          .of(
            Yup.object().shape({
                type : Yup.string()
                .required('Required'),
                birthday : Yup.string()
                .required('Required'),
                mealPlan : Yup.string(),
                specialRequest : Yup.string(),
                name: Yup.object().shape({
                    title: Yup.string()
                    .required('Required'),
                    firstName: Yup.string()
                        .required('Required'),
                    lastName: Yup.string()
                        .required('Required')
                }),
                passport: Yup.object().shape({
                    number: Yup.string(),
                        // .required('Required'),
                    countryCode: Yup.string(),
                        // .required('Required'),
                    issuedDate: Yup.string(),
                        // .required('Required'),
                    expiryDate: Yup.string()
                        // .required('Required')
                })
            })
          )
          .required('Must have Passengers')
    });

    // country list
    const [countryList, setCountryList] = useState([]);

    //get country list
    useEffect(() => {
        getCountryList()
        .then(res => {
            setCountryList(res.data);
        })
        .catch(err => {
            handleException(err, 'Could not load country list');
        });
    }, []);

    // meal plan list
    const mealPlanList = [
        {value : 'AVML',label : 'VEGETARIAN HINDU MEAL'},
        {value : 'BBML',label : 'BABY MEAL'},
        {value : 'BLML',label : 'BLAND MEAL'},
        {value : 'CHML',label : 'CHILD MEAL'},
        {value : 'DBML',label : 'DIABETIC MEAL'},
        {value : 'FPML',label : 'FRUIT PLATTER MEAL'},
        {value : 'GFML',label : 'GLUTEN INTOLERANT MEAL'},
        {value : 'HNML',label : 'HINDU (NON VEGETARIAN) MEAL SPECIFIC'},
        {value : 'IVML',label : 'INDIAN VEGETARIAN MEAL (UA SPECIFIC)'},
        {value : 'JPML',label : 'JAPANESE MEAL (LH SPECIFIC)'},
        {value : 'KSML',label : 'KOSHER MEAL'},
        {value : 'LCML',label : 'LOW CALORIE MEAL'},
        {value : 'LFML',label : 'LOW FAT MEAL'},
        {value : 'LSML',label : 'LOW SALT MEAL'},
        {value : 'MOML',label : 'MOSLEM MEAL'},
        {value : 'NFML',label : 'NO FISH MEAL (LH SPECIFIC)'},
        {value : 'NLML',label : 'LOW LACTOSE MEAL'},
        {value : 'OBML',label : 'JAPANESE OBENTO MEAL (UA SPECIFIC)'},
        {value : 'RVML',label : 'VEGETARIAN RAW MEAL'},
        {value : 'SFML',label : 'SEA FOOD MEAL'},
        {value : 'SPML',label : 'SPECIAL MEAL, SPECIFY FOOD'},
        {value : 'VGML',label : 'VEGETARIAN VEGAN MEAL'},
        {value : 'VJML',label : 'VEGETARIAN JAIN MEAL '},
        {value : 'VOML',label : 'VEGETARIAM ORIENTAL MEAL'},
        {value : 'VLML',label : 'VEGETARIAN LACTO-OVO MEAL'}
    ];

    // special request list
    const specialRequestList = [
        {value : 'BIKE', label : 'Bicycle'},
        {value : 'BLND', label : 'Blind passenger'},
        {value : 'BSCT', label : 'Bassinet/carry cot/basket'},
        {value : 'CBBG', label : 'Cabin baggage'},
        {value : 'CHML', label : 'Child meal'},
        {value : 'DEAF', label : 'Deaf passenger'},
        {value : 'EPAY', label : 'Ticketless airlines'},
        {value : 'EXST', label : 'Extra seat'},
        {value : 'FQTR', label : 'Frequent flyer (redemption journey)'},
        {value : 'FQTV', label : 'Frequent flyer (mileage accrual travel)'},
        {value : 'FOID', label : 'Form of ID'},
        {value : 'FPML', label : 'Fruit platter'},
        {value : 'GRPF', label : 'Group fare'},
        {value : 'INFT', label : 'Manual creation of infant SSR element'},
        {value : 'KSML', label : 'Kosher meal'},
        {value : 'LANG', label : 'Language spoken'},
        {value : 'LSML', label : 'Low salt meal'},
        {value : 'OTHS', label : 'Other service'},
        {value : 'PCTC', label : 'Emergency contact information'},
        {value : 'PETC', label : 'Pet in cabin'},
        {value : 'DOCS', label : 'Passport information APIS Airlines'},
        {value : 'DOCO', label : 'Passport information APIS Airlines'},
        {value : 'DOCA', label : 'Passport information APIS Airlines'},
        {value : 'SFML', label : 'Seafood meal'},
        {value : 'SPML', label : 'Special meal, without a specific code'},
        {value : 'UMNR', label : 'Unaccompanied minor'},
        {value : 'VGML', label : 'Vegetarian meal'},
        {value : 'WCHC', label : 'Wheelchair, aircraft cabin'},
        {value : 'WCHR', label : 'Wheelchair, ramp'},
        {value : 'WCHS', label : 'Wheelchair, stairs'},
        {value : 'WEAP', label : 'Weapon, firearm and ammunition information'}
    ];

    // initialize form values
    const initValues = {
        passengers:[]
    }

    for (let i = 0; i < adults; i++) {
        initValues.passengers.push({ 
            type : 'Adult',
            birthday: '',
            mealPlan : '',
            specialRequest : '',
            name:{ 
                 title:'',
                 firstName:'',
                 lastName: ''
            },
            passport:{ 
               number:'',
               countryCode:'',
               issuedDate:'',
               expiryDate:''
            }
        });
    }

    for (let i = 0; i < children; i++) {
        initValues.passengers.push({
            type : 'Child',
            birthday: '',
            mealPlan : '',
            specialRequest : '',
            name:{ 
                 title:'',
                 firstName:'',
                 lastName: ''
            },
            passport:{ 
               number:'',
               countryCode:'',
               issuedDate:'',
               expiryDate:''
            }
        });
    }

    for (let i = 0; i < infants; i++) {
        initValues.passengers.push({ 
            type : 'Infant',
            birthday: '',
            mealPlan : '',
            specialRequest : '',
            name:{ 
                 title:'',
                 firstName:'',
                 lastName: ''
            },
            passport:{ 
               number:'',
               countryCode:'',
               issuedDate:'',
               expiryDate:''
            }
        });
    }

    // get minimum birth day according to passenger type
    const getMinMaxBirthDay = passengerType => {
        let dates = { 
            min : new Date('1950-01-01'),
            max : new Date()
        };

        switch (passengerType) {
            case 'Adult':
                // min 1900 , max 12 years before today
                dates.max = moment().subtract(12, 'years').toDate();
                break;
            case 'Child': 
                // min 12 years before today , max 2 years before today
                dates.min = moment().subtract(12, 'years').subtract(1, 'days').toDate();
                dates.max = moment().subtract(2, 'years').toDate();
                break;
            case 'Infant':
                // min 2 years before today , max today
                dates.min = moment().subtract(2, 'years').subtract(1, 'days').toDate();
                break;
            default:
                break;
        }
        return dates;
    }

    return (
        <Formik
        initialValues={initValues}
        validationSchema={schema}
        render={(formikProps) => {

            // map formik form to props : parent will recieve form instence
            formRendered(formikProps);

            return (
            <Form noValidate>
                <FieldArray
                    name="adults"
                    render={arrayHelpers => (
                    <div>
                        {formikProps.values.passengers.map((passenger, index) => (
                            <div className="container mb-4 border-bottom py-2" key={index}>
                                <h5>Passenger {index+1} ({passenger.type})</h5>
                                
                                <div className="form-row">
                                   <div className="form-group col-lg-4 mb-3">
                                        <label>Title</label>
                                        <Field component="select" className="form-control" name={`passengers.${index}.name.title`}>
                                            <option value="">- Select -</option>
                                            <option value="Mr">Mr.</option>
                                            <option value="Mrs">Mrs.</option>
                                            <option value="Miss">Miss.</option>
                                            <option value="Master">Master.</option>
                                        </Field>
                                        <FormErrorMessage name={`passengers.${index}.name.title`} />
                                   </div>
                                   <div className="form-group col-lg-4 mb-3">
                                        <label>First Name</label>
                                        <Field className="form-control" name={`passengers.${index}.name.firstName`} />
                                        <FormErrorMessage name={`passengers.${index}.name.firstName`} />
                                   </div>
                                   <div className="form-group col-lg-4 mb-3">
                                        <label>Last Name</label>
                                        <Field className="form-control" name={`passengers.${index}.name.lastName`} />
                                        <FormErrorMessage name={`passengers.${index}.name.lastName`} />
                                   </div>
                                </div>

                                <div className="form-row">
                                   <div className="form-group col-lg-4 datpicker-w-100-wrapper mb-3">
                                        <label>Date of Birth</label>

                                        <Field type="hidden" className="form-control" name={`passengers.${index}.birthday`} />

                                        <DatePicker
                                            className="form-control"
                                            dateFormat="yyyy-MM-dd"
                                            minDate={getMinMaxBirthDay(passenger.type).min}
                                            maxDate={getMinMaxBirthDay(passenger.type).max}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            selected={
                                                formikProps.values.passengers[index].birthday === '' ?
                                                formikProps.values.passengers[index].birthday : 
                                                new Date(formikProps.values.passengers[index].birthday)                                              
                                            }
                                            onChange={date => {
                                                formikProps.setFieldValue(
                                                    `passengers.${index}.birthday`,
                                                    moment(date).format('YYYY-MM-DD')
                                                );
                                            }}
                                        />

                                        <FormErrorMessage name={`passengers.${index}.birthday`} />
                                   </div>
                                   <div className="form-group col-lg-4 mb-3">
                                        <label>Meal Plan</label>
                                        <Field component="select" className="form-control" name={`passengers.${index}.mealPlan`}>
                                            <option value="">- Select -</option>
                                            {mealPlanList.map((mealPlan, i) => <option key={i} value={mealPlan.value}>{mealPlan.label}</option>)}
                                        </Field>
                                        <FormErrorMessage name={`passengers.${index}.mealPlan`} />
                                   </div>
                                   <div className="form-group col-lg-4 mb-3">
                                        <label>Special Request</label>
                                        <Field component="select" className="form-control" name={`passengers.${index}.specialRequest`}>
                                            <option value="">- Select -</option>
                                            {specialRequestList.map((specialRequest, i) => <option key={i} value={specialRequest.value}>{specialRequest.label}</option>)}
                                        </Field>
                                        <FormErrorMessage name={`passengers.${index}.specialRequest`} />
                                   </div>
                                   
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-lg-4 mb-3">
                                        <label>Passport No</label>
                                        <Field placeholder="Optional" className="form-control" name={`passengers.${index}.passport.number`} />
                                        <FormErrorMessage name={`passengers.${index}.passport.number`} />
                                   </div>
                                    <div className="form-group col-lg-4 mb-3">
                                        <label>Passport Issued Country</label>
                                        <Field type="hidden" className="form-control" name={`passengers.${index}.passport.countryCode`} />
                                        <Select 
                                        placeholder="Optional"
                                        getOptionLabel={ option => option.countryName} 
                                        getOptionValue={option => option.countryCodeIso} 
                                        options={countryList} 
                                        onChange={option => {formikProps.setFieldValue(`passengers.${index}.passport.countryCode`, option.countryCodeIso)}}
                                        />
                                        <FormErrorMessage name={`passengers.${index}.passport.countryCode`} />
                                   </div>
                                   <div className="form-group col-lg-4 datpicker-w-100-wrapper mb-3">
                                        <label>Passport Issued Date</label>
                                        <Field type="hidden" className="form-control" name={`passengers.${index}.passport.issuedDate`} />

                                        <DatePicker
                                            placeholderText="Optional"
                                            className="form-control"
                                            dateFormat="yyyy-MM-dd"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            selected={
                                                formikProps.values.passengers[index].passport.issuedDate === '' ?
                                                formikProps.values.passengers[index].passport.issuedDate : 
                                                new Date(formikProps.values.passengers[index].passport.issuedDate)                                              
                                            }
                                            maxDate={new Date()}
                                            onChange={date => {
                                                formikProps.setFieldValue(
                                                    `passengers.${index}.passport.issuedDate`,
                                                    moment(date).format('YYYY-MM-DD')
                                                );
                                            }}
                                        />

                                        <FormErrorMessage name={`passengers.${index}.passport.issuedDate`} />
                                   </div>
                                   <div className="form-group col-lg-4 datpicker-w-100-wrapper mb-3">
                                        <label>Passport Exp. Date</label>
                                        <Field type="hidden" className="form-control" name={`passengers.${index}.passport.expiryDate`} />

                                        <DatePicker
                                            placeholderText="Optional"
                                            className="form-control"
                                            dateFormat="yyyy-MM-dd"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            minDate={new Date()}
                                            selected={
                                                formikProps.values.passengers[index].passport.expiryDate === '' ?
                                                formikProps.values.passengers[index].passport.expiryDate : 
                                                new Date(formikProps.values.passengers[index].passport.expiryDate)                                              
                                            }
                                            onChange={date => {
                                                formikProps.setFieldValue(
                                                    `passengers.${index}.passport.expiryDate`,
                                                    moment(date).format('YYYY-MM-DD')
                                                );
                                            }}
                                        />

                                        <FormErrorMessage name={`passengers.${index}.passport.expiryDate`} />
                                   </div>
                                </div>
                                
                            </div>
                        ))}
                        
                    </div>
                    )

                }/>
            </Form>
            );
            }}
        />

    );
};

const FormErrorMessage = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? <div className="invalid-feedback d-block"> {error} </div> : null;
      }}
    />
);

export default PassengerDetailsForm;