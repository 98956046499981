import React, { Fragment } from 'react';
import { Button, Modal, Form, Row, Col, Spinner } from 'react-bootstrap';
import './menu-create-model.scss';
import { Formik, Field, ErrorMessage } from 'formik';
import { menuCreate } from '../../../api/cms.api';

const MenuCreateModal = ({ show, onHide, refresh }) => {

    const [showResults, setShowResults] = React.useState(false);

    return (
        <Fragment>
            {
                <Modal size="md" show={show} onHide={() => onHide()} centered>
                    <Modal.Header closeButton>
                        <Modal.Title >
                            <span>Menu Create</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <Formik
                            initialValues={{ menuName: '', pageUrl: '' }}
                            validate={values => {
                                const errors = {};
                                if (!values.menuName) {
                                    errors.menuName = 'Menu name is required';
                                }
                                if (!values.pageUrl) {
                                    errors.pageUrl = 'Page Url (URL) is required';
                                }
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                setShowResults(true);
                                setTimeout(() => {

                                    let removeSpaceMenuName = values.menuName.replace(/\s/g, '-')

                                    const Menu = {
                                        menuName: values.menuName,
                                        pageUrl: values.pageUrl,
                                        appType: "MOBILE",
                                        menuCode: removeSpaceMenuName.toUpperCase()
                                    }

                                    menuCreate(Menu).then(() => {
                                        onHide();
                                        refresh();
                                        setSubmitting(false);
                                        setShowResults(false);
                                    }).catch((error) => {
                                        console.log(error);
                                    })
                                }, 400);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => (

                                <Form onSubmit={handleSubmit}>
                                    <Form.Group as={Row} controlId="formHorizontalMenuName">
                                        <Form.Label column sm={4}>Menu Name</Form.Label>
                                        <Col sm={8}>
                                            <Field className={'form-control' + (errors.menuName && touched.menuName ? ' is-invalid' : '')} name="menuName" type="text" placeholder="Enter menu name" />
                                            <ErrorMessage name="menuName" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalMenuLink">
                                        <Form.Label column sm={4}>Page Url (URL)</Form.Label>
                                        <Col sm={8}>
                                            <Field className={'form-control' + (errors.pageUrl && touched.pageUrl ? ' is-invalid' : '')} name="pageUrl" type="text" placeholder="Enter page url" />
                                            <ErrorMessage name="pageUrl" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    {showResults ?
                                        <Form.Group as={Row}>
                                            <Col sm={8}>
                                            </Col>
                                            <Col sm={4}>
                                                <Button variant="primary" disabled>
                                                    <Spinner
                                                        as="span"
                                                        variant="warning"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        animation="grow" />
                                                    Loading...
                                                </Button>
                                            </Col>
                                        </Form.Group>
                                        :
                                        <Form.Group as={Row}>
                                            <Col sm={9}>
                                            </Col>
                                            <Col sm={3}>
                                                <Button type="submit" disabled={isSubmitting}>Submit</Button>
                                            </Col>
                                        </Form.Group>
                                    }
                                </Form>
                            )}
                        </Formik>
                    </Modal.Body>
                </Modal>
            }
        </Fragment>
    );
}

export default MenuCreateModal;