import { api } from '../../../api/api';

export const initPayment = (confirmId, returnUrl) => {
    const body = {
        confirmId,
        returnUrl,
    }
    return api.post(`/anzworldline/hostedcheckoutrequest`, body);
}

export const checkStatus = (confirmId) => {
    return api.get(`/anzworldline/hostedcheckoutstatus/${confirmId}`);
}
