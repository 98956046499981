import React, { Fragment } from 'react'
import { Route } from "react-router-dom";
import UserInfo from './user-info';

const User = ({ match }) => {
    return (
        <Fragment>
            <Route
                exact
                path={`${match.url}/`}
                component={UserInfo}
            />

        </Fragment>
    )
}

export default User
