import React from "react";
import Select from "react-select";
import './filter-airline-codes.scss';

const FilterAirlineCodes = ({ filterData, filterChanged }) => {
    const { data, valueData } = filterData;
    const filterKey = "airlineCodes";
    if (data) {
        return (
            <div className="container">
                <div className="row">
                    <div className="airlineCodes col-12 col-lg-12">
                        <div className="font-weight-bold mb-2">
                            Filter result by Airline
                        </div>
                        <Select
                            isMulti={true}
                            placeholder="Select Airlines"
                            getOptionLabel={option => option.airlineName}
                            getOptionValue={option => option.airlineCode}
                            options={data}
                            value={valueData}
                            formatOptionLabel={(selectedObj, options) => (
                                <div className="small">
                                    {selectedObj.airlineName}
                                </div>
                            )}
                            onChange={airlineList => {
                                if (airlineList != null) {
                                    filterChanged(
                                        filterKey,
                                        airlineList.map(airline =>
                                            airline.airlineCode
                                        ),
                                        airlineList
                                    );
                                } else {
                                    filterChanged(filterKey, null);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default FilterAirlineCodes;
