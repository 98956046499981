import React, {useState} from 'react';
import BookingEngineHeader from '../../components/booking-engine-header/booking-engine-header';
import BookingEngineFooter from '../../components/booking-engine-footer/booking-engine-footer';
import AgentForm from './agent-form';
import BookingEngineView from '../../components/booking-engine-view';
import { getCurrentUser } from '../../../aws-cognito/aws-functions';
import { awsSignUpAgentUrl, awsSignOutUrl } from '../../../aws-cognito/aws-config';
import './agent.scss';

const Agent = () => {

    const currentUser = getCurrentUser();

    const [divDissabled, setDivDissabled] = useState(false);

    const onChangeValue = (event) => {
        if (event.target.value === 'Yes') {
            setDivDissabled(true)
        } else {
            setDivDissabled(false)
        }
    }

    return (
        <>

            <BookingEngineHeader />
            <BookingEngineView>
            <div className="agent-form">
                <div className="container mt-3 pb-5">
                    <div className="row justify-content-between mt-3 mb-3">
                        <div className="col-12" >
                            {
                                divDissabled ?
                                    null
                                    :
                                    <div id="agent-process-step1" className="mt-3">
                                        {
                                            currentUser ?
                                                currentUser.groupConfig.groups.includes("B2B") ?
                                                <div className="container my-3 text-center">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h1 className="card-title text-primary mb-4">Agent Registration</h1>
                                                            <p className="card-text">You are already an Agent.</p>
                                                        </div>
                                                    </div>
                                                </div>   
                                        :
                                        <div className="container agent-registration my-3 text-center">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h1 className="card-title text-primary mb-4">Agent Registration</h1>
                                                    <p className="card-text">You have login as <b>{currentUser.name}</b></p>
                                                    <p className="card-text">Do you want to proceed with B2B register under <b>{currentUser.name}</b></p>
                                                    <div onChange={onChangeValue} className="text-center mt-2">
                                                        <span className="mr-4">
                                                            <input type="radio" value="Yes" name="agreeToBeAgentProcess" /> Yes
                                                        </span>
                                                        <span>
                                                            <input type="radio" value="No" name="agreeToBeAgentProcess" defaultChecked /> No
                                                        </span>
                                                        <div className="col-12">
                                                            <a className="btn btn-primary mt-3" href={awsSignOutUrl}>Sign Out</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="container my-3 text-center">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h1 className="card-title text-primary mb-4">Agent Registration</h1>
                                                    <p className="card-text">Kindly fill out the below fields to proceed further</p>
                                                    <div className="col-12 mt-2">
                                                        <a className="btn btn-primary ml-2" href={awsSignUpAgentUrl}>Sign Up</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>   
                                        
                                        }
                                    </div>
                            }
                        </div>
                        <div className="col d-flex justify-content-center">
                            <div className="col-12 col-sm-8" id="agent-process-step2" >
                                {
                                    divDissabled ?
                                        <AgentForm save />
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </BookingEngineView>
            <BookingEngineFooter />

        </>
    )
}

export default Agent;
