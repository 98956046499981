import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { getCountryList } from '../../../api/master-data.api';
import Select from 'react-select';
import { handleException } from '../../../common/util';
import { getCurrentUser } from '../../../aws-cognito/aws-functions';

const BookingPersonDetailsForm = ({
    formRendered,
    adults
}) => {

    const [schema, setSchema] = useState(Yup.object().shape({
        bookerIndex: Yup.string()
            .required('Required'),
        bookerName: Yup.string(),
        bookerEmail: Yup.string()
            .email('Invalid email')
            .required('Required'),
        bookerCountryCode: Yup.string()
            .required('Required'),
        bookerMobile: Yup.string()
            .required('Required'),
    }));

    const [countryList, setCountryList] = useState([]);

    //get country list
    useEffect(() => {
        getCountryList()
            .then(res => {
                setCountryList(res.data);
            })
            .catch(err => {
                handleException(err, 'Could not load country list')
            });
    }, []);


    const passengerChangeHandler = (e) => {
        if (e.target.value === 'other') {
            setSchema(Yup.object().shape({
                bookerIndex: Yup.string()
                    .required('Required'),
                bookerName: Yup.string()
                    .required('Required'),
                bookerEmail: Yup.string()
                    .email('Invalid email')
                    .required('Required'),
                bookerCountryCode: Yup.string()
                    .required('Required'),
                bookerMobile: Yup.string()
                    .required('Required'),
            }));
        }
    }

    return (
        <Formik
            initialValues={getCurrentUser() ? {
                bookerIndex: adults === 1 ? 0 : '',
                bookerName: getCurrentUser().name,
                bookerEmail: getCurrentUser().tokenDecoded.idToken.email,
                bookerCountryCode: '',
                bookerMobile: getCurrentUser().tokenDecoded.idToken.phone_number
            } : {
                bookerIndex: adults === 1 ? 0 : '',
                bookerName: '',
                bookerEmail: '',
                bookerCountryCode: '',
                bookerMobile: ''
            }}
            validationSchema={schema}
            render={(formikProps) => {

                // map form render formik to props
                formRendered(formikProps);

                // passenger select options list
                const passengerOptions = [];
                for (let i = 0; i < adults; i++) {
                    passengerOptions.push(<option key={i} value={i}> Adult {i + 1} </option>);
                }


                return (
                    <Form noValidate>
                        <div className="row">
                            <div className="form-group col-lg-6 mb-3">
                                <label>Passenger</label>
                                <Field onChange={(e) => {
                                    formikProps.setFieldValue('bookerIndex', e.target.value);
                                    formikProps.setFieldValue('bookerName', '');
                                    passengerChangeHandler(e);
                                }} component="select" className="form-control" name="bookerIndex">
                                    <option value="">- Select -</option>
                                    {passengerOptions}
                                    <option value="other">Other</option>
                                </Field>
                                <FormErrorMessage name="bookerIndex" />
                            </div>

                            {formikProps.values.bookerIndex === 'other' ?
                                <div className="form-group col-lg-6 mb-3">
                                    <label>Booker Name</label>
                                    <Field className="form-control" name="bookerName" />
                                    <FormErrorMessage name="bookerName" />
                                </div>
                                : null
                            }


                            <div className="form-group col-lg-6 mb-3">
                                <label>Email</label>
                                <Field className="form-control" name="bookerEmail" />
                                <FormErrorMessage name="bookerEmail" />
                            </div>
                            {/* </div> */}
                            {/* <div className="form-row"> */}
                            <div className="form-group col-lg-6 mb-3">
                                <label>Country</label>
                                <Field type="hidden" className="form-control" name="bookerCountryCode" />
                                <Select
                                    styles={{
                                        menu: provided => ({ ...provided, zIndex: 9999 })
                                    }}
                                    getOptionLabel={option => option.countryName}
                                    getOptionValue={option => option.countryCodeIso}
                                    options={countryList}
                                    onChange={option => { formikProps.setFieldValue('bookerCountryCode', option.countryCodeIso) }}
                                />
                                <FormErrorMessage name="bookerCountryCode" />
                            </div>
                            <div className="form-group col-lg-6 mb-3">
                                <label>Mobile</label>
                                <Field className="form-control" name="bookerMobile" />
                                <FormErrorMessage name="bookerMobile" />
                            </div>
                        </div>
                    </Form>
                );

            }}
        />

    );
};

const FormErrorMessage = ({ name }) => (
    <Field
        name={name}
        render={({ form }) => {
            const error = getIn(form.errors, name);
            const touch = getIn(form.touched, name);
            return touch && error ? <div className="invalid-feedback d-block"> {error} </div> : null;
        }}
    />
);

export default BookingPersonDetailsForm;