import React from 'react';

const AdminView = (props) => {
    const style = {
        padding: '10px 30px 30px 30px',
        minHeight: '70vh'
    };
    return <main style={style} className="admin-view">{props.children}</main>;
};

export default AdminView;