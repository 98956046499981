import axios from 'axios';
import { handleException } from '../common/util';
import { getAccessToken, getIdToken, authErrorHandler } from '../aws-cognito/aws-functions';

/* API */
const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE
});

/* API */
const countryApi = axios.create({
    baseURL: 'https://restcountries.eu/rest/v2'
});


// api : inject app auth token
api.interceptors.request.use(config => {
    try {
        const token = getIdToken() ? getIdToken() : getAccessToken();
        config.headers.Authorization = token ? `Bearer ${token}` : '';
        return config;
    } catch (error) {
        handleException(error, 'Could not get app token');
    }
    return config;
});

// api : handle response errors
api.interceptors.response.use(response => {
    return response;
}, (error) => {
    // aws function call
    if (error.response.status === 401) {
        return authErrorHandler(api, error);
    }
    return Promise.reject(error);
});

export {
    api,
    countryApi
}
