import React, { useRef, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { Formik } from 'formik';
import AsyncSelect from "react-select/async";
import { promoAirport, validatePromoAirport, airportSearch } from '../../../api/admin-api';
import './modal-promo.scss';
import moment from 'moment';
import * as Yup from 'yup';
import PromoAirlineList from './promo-airline-list';
import LaddaButton, { SLIDE_UP } from 'react-ladda';

const ModalPromo = ({ setShow, handleClose, airlineName, airlineCode, promoList, updates, modalChangers, modalFails, modalNoChange }) => {

    const formRef = useRef();

    // save button loading
    const [buttonLoading, setButtonLoading] = useState(false);

    const handleSubmit = () => {
        const airport = formRef.current.values.airport;
        const fromDate = formRef.current.values.fromDate;
        const toDate = formRef.current.values.toDate;
        if (airport && fromDate && toDate) {
            handlePromoAirport(formRef.current.values);
        }

        // handlePromoAirport(newList);
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    }

    // add promoAirport
    const handlePromoAirport = ({ airport, fromDate, toDate }) => {
        setButtonLoading(true);
        validatePromoAirport(airlineCode, airport).then(res => {
            if (res.status === 200) {
                modalNoChange();
                // modalFails();
            } else {
                modalFails();
            }
        }).catch(error => {
            if (error.response.status === 404) {
                const activeStatus = true;
                promoAirport(airlineCode, activeStatus, moment(fromDate).format('YYYY-MM-DD'), moment(toDate).format('YYYY-MM-DD'), airport).then(res => {
                    updates();
                    modalChangers();
                })
                    .catch(err => {
                        modalFails();
                    })
            }
            else {
                modalFails();
            }
        })
    }

    const schema = Yup.object().shape({
        airport: Yup.string()
            .required('Required'),
        fromDate: Yup.string()
            .required('Required'),
        toDate: Yup.string()
            .required('Required'),
    });

    /* Ui for the drop down of the airport select */
    const formatAirportSelect = (selectedObj, options) => {
        if (options.context === "menu") {
            // option ui
            return (
                <div className="airport-option">
                    <div className="text-1">
                        {selectedObj.apIataCode3} :{" "}
                        <span className="small">{selectedObj.apName}</span>
                    </div>
                    <div className="text-2">{selectedObj.apCityName} </div>
                </div>
            );
        } else {
            // selected value ui
            return <div>{selectedObj.apIataCode3}</div>;
        }
    };

    /* Search airport list promise */
    const airportListLoadPromise = (inputValue) => {
        return new Promise((resolve, reject) => {
            if (inputValue.length >= 3) {
                // airport search api
                airportSearch(inputValue).then(res => {
                    resolve(res.data);
                })
            } else {
                reject();
            }
        });
    };

    return (
        <div>
            <Modal show={setShow} onHide={handleClose} >
                <div className="text-center mt-2">
                    <Modal.Title >{airlineName}</Modal.Title>
                </div>
                <Modal.Body>
                    <h5 className="text-center">Promotion</h5>

                    <div className="container">
                        <Formik
                            innerRef={formRef}
                            initialValues={{
                                airport: "",
                                fromDate: "",
                                toDate: "",
                            }}
                            validationSchema={schema}

                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(false);
                            }}
                        >
                            {({ values, errors, touched, setFieldValue, handleChange, isSubmitting }) => (
                                <Form >
                                    <div className="form-group row">
                                        <label htmlFor="airport" className="col-sm-4 col-form-label">Destination</label>
                                        <div className="col-sm-8">
                                            <AsyncSelect
                                                name="airport"
                                                cacheOptions
                                                defaultOptions
                                                loadOptions={value =>
                                                    airportListLoadPromise(value)
                                                }
                                                placeholder="Select Airport"
                                                formatOptionLabel={formatAirportSelect}
                                                getOptionValue={obj => obj.apIataCode3}
                                                selected={values.airport}
                                                onChange={(value) => {
                                                    setFieldValue('airport', value.apIataCode3)
                                                }}
                                            />
                                            {errors.airport && touched.airport ? (
                                                <small className="text-danger font-italic">{errors.airport}</small>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="fromDate" className="col-sm-4 col-form-label">From</label>
                                        <div className="col-sm-8">
                                            <DatePicker
                                                name="fromDate"
                                                className={"form-control " + (errors.fromDate && touched.fromDate ? 'is-invalid' : '')}
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="Select Date"
                                                minDate={new Date()}
                                                selected={values.fromDate}
                                                onChange={date => {
                                                    setFieldValue('fromDate', date)
                                                }
                                                }
                                            />
                                            <i className="text-primary fa fa-calendar-o ml-2"></i>
                                            {errors.fromDate && touched.fromDate ? (
                                                <small className="text-danger font-italic">{errors.fromDate}</small>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="toDate" className="col-sm-4 col-form-label">To</label>
                                        <div className="col-sm-8">
                                            <DatePicker
                                                name="toDate"
                                                className={"form-control " + (errors.toDate && touched.toDate ? 'is-invalid' : '')}
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="Select Date"
                                                minDate={new Date(values.fromDate)}
                                                selected={values.toDate}
                                                onChange={(date) => setFieldValue('toDate', date)}
                                            />
                                            <i className="text-primary fa fa-calendar-o ml-2"></i>
                                            {errors.toDate && touched.toDate ? (
                                                <small className="text-danger font-italic">{errors.toDate}</small>
                                            ) : null}
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>

                        <hr />

                        <div>
                            {
                                promoList ?
                                    promoList.map((list) => {
                                        return (
                                            <div key={list.id}>
                                                <PromoAirlineList list={list} airlineCode={airlineCode} updates={updates} modalChangers={modalChangers} modalFails={modalFails} />
                                            </div>
                                        )
                                    })
                                    : null
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>

                    <LaddaButton
                        type="submit"
                        className="btn btn-primary ladda-button"
                        loading={buttonLoading}
                        data-style={SLIDE_UP}
                        onClick={handleSubmit}
                    >
                        Add
                    </LaddaButton>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default ModalPromo;
