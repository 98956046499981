import React from 'react';
import AsyncSelect from "react-select/async";
import { fareManageAddList, validateAirline, airlineSearch } from '../../api/admin-api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FareManageAdd = ({ updates }) => {

    // notification when successfull attempt of adding a new airline
    const notifyAirlineAddSuccess = (airlineCode) => toast.success(`successfully added the ${airlineCode} airline! Please wait...!!!`, {
        containerId: 'notifyAirlineAddSuccess',
        autoClose: 5000,
        // hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
    });
    // notification when fail attempt of adding a new airline
    const notifyAirlineAddFailed = () => toast.error('Oops....Something went wrong. Try again!', {
        containerId: 'notifyAirlineAddFailed',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
    });
    // notification when adding already added airline
    const notifyAirlineAddAlready = (airlineCode) => toast.info(`Airline ${airlineCode} is already added!`, {
        containerId: 'notifyAirlineAddAlready',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
    });

    // add fare manage
    const fareManageAddListFunc = (value) => {
        validateAirline(value).then(res => {
            if (res.status === 200) {
                notifyAirlineAddAlready(res.data);
                // scrolling when already added
                const elmnt = document.getElementById(res.data);
                elmnt.scrollIntoView();
            } else {
                notifyAirlineAddFailed();
            }
        }).catch(error => {
            if (error.response.status === 404) {
                fareManageAddList(value).then(res => {
                    updates();
                    const airlineCode = res.data[0].airlineCode;
                    notifyAirlineAddSuccess(airlineCode);

                    // scrolling when added
                    setTimeout(function () {
                        const elmnt1 = document.getElementById(airlineCode);
                        elmnt1.scrollIntoView()
                    }, 5000);

                })
                    .catch(error => {
                        notifyAirlineAddFailed();
                    })
            }
            else {
                notifyAirlineAddFailed();
            }
        })
    }

    /* Ui for the drop down of the airline select */
    const formatAirlineSelect = (selectedObj, options) => {
        if (options.context === "menu") {
            // option ui
            return (
                <div className="airport-option">
                    <div className="text-1">
                        {selectedObj.airlineCode} :{" "}
                        <span className="small">{selectedObj.airlineName}</span>
                    </div>
                </div>
            );
        } else {
            // selected value ui
            return <div>{selectedObj.airlineCode}</div>;
        }
    };

    /* Search airline list promise */
    const airlineListLoadPromise = (inputValue) => {
        return new Promise((resolve, reject) => {
            if (inputValue.length >= 2) {
                // airline search api
                airlineSearch(inputValue).then(res => {
                    resolve(res.data);
                })

            } else {
                reject();
            }
        });
    };

    return (
        <div className="mt-lg-3 mb-lg-4">
            <div className="row">
                <div className="col-md-2 col-sm-12">
                    <p>Enter an Airline </p>
                </div>

                <ToastContainer enableMultiContainer containerId={'notifyAirlineAddSuccess'} position={toast.POSITION.BOTTOM_RIGHT} />
                <ToastContainer enableMultiContainer containerId={'notifyAirlineAddFailed'} position={toast.POSITION.BOTTOM_RIGHT} />
                <ToastContainer enableMultiContainer containerId={'notifyAirlineAddAlready'} position={toast.POSITION.BOTTOM_RIGHT} />

                <div className="col-md-4 col-sm-12">
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={value =>
                            airlineListLoadPromise(value)
                        }
                        placeholder="Select Airline"
                        formatOptionLabel={formatAirlineSelect}
                        getOptionValue={obj => obj.airlineName}
                        onChange={value => {
                            fareManageAddListFunc(value.airlineCode);
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default FareManageAdd;
