import { api, countryApi } from './api';

/** 
 * @function searchAirport
 * @description search airport by keyword : use this for search
*/
export const searchAirport = term => {
    return api.get('/list/airport', {
        params: {
            searchType: 'TERM',
            activeStatus: 1,
            term
        }
    });
}

/** 
 * @function flightSearch
 * @description search fligh availability : use this for search widget
*/
export const flightSearch = (queryObject) => {
    return api.post('/flight/faresearch', queryObject);
}

/** 
 * @function getFlightPricing
 * @description get pricing details, pre request for mini rules request
*/
export const getFlightPricing = flightResultDetails => {
    return api.post('/flight/pricing', flightResultDetails);
}

/** 
 * @function getMiniRules
 * @description get mini rules fligh rules details, request after flight pricing request
 * @param sessionData : flight pricing session data
*/
export const getMiniRules = sessionData => {
    return api.post('/flight/minirules', sessionData);
}

/** 
 * @function sellFromRecomondation
 * @description Init Flight Booking, Sell From Recomondation, Use this to inititalize booking when user click book now
 * @param sellFormItineraries : search item flight information data
*/
export const sellFromRecomondation = sellFormItineraries => {
    return api.post('/flight/sellfromrec', sellFormItineraries);
}

/** 
 * @function passengerDetails
 * @description Submit passenger details
 * @param passengersDetails : details of the passengers with booking sSession
*/
export const passengerDetails = (passengersDetails) => {
    return api.post('/flight/passengerdetails', passengersDetails);
}

/** 
 * @function pricingSolution
 * @description Request after sending passenger details
 * @param sSession : booking sSession
 * @param confirmId : booking confirm id (cid)
*/
export const pricingSolution = (sSession, confirmId) => {
    return api.post('/flight/pricingsolution', { sSession, confirmId });
}

/** 
 * @function storeTst
 * @description Request after pricing solution
 * @param sSession : booking sSession
 * @param fareReferences : references from pricing solution request
 * @param confirmId : booking confirm id (cid)
*/
export const storeTst = (fareReferences, sSession, confirmId) => {
    return api.post('/flight/storetst', { fareReferences, sSession, confirmId });
}

/** 
 * @function pnrCommit
 * @description Final Request to booking
 * @param sSession : booking sSession
 * @param confirmId : booking confirm id (cid)
*/
export const pnrCommit = (sSession, confirmId) => {
    return api.post('/flight/pnrcommit', { sSession, confirmId });
}

/** 
 * @function sendConfirmEmail
 * @description Final Request to send mails after booking
 * @param confirmId : booking confirm id (cid)
*/
export const sendConfirmEmail = (confirmId) => {
    return api.post(`/sendemail/flight/confirmbooking/${confirmId}`);
}

/** 
 * @function saveAgentDetails
 * @description when the B2C user apply as the agent (pending agent), save details
 * @param data : pending agent informations
*/
export const saveAgentDetails = (data) => {
    return api.post(`/b2b/initb2buser`, data);
}

/** 
 * @function saveAgentDetailsAdmin
 * @description  create agent from admins
 * @param data : agent details object with cognito details
*/
export const saveAgentDetailsAdmin = (data) => {
    return api.post(`/backend/b2b/admincreateb2buser`, data);
}

/** 
 * @function updateAgentDetails
 * @description B2B agent front end side - details modification
 * @param data : agent informations
*/
export const updateAgentDetails = (data) => {
    return api.patch(`/b2b/updateb2buser`, data);
}
/** 
 * @function getAgentDetails
 * @description B2B agent front end side - get details
*/
export const getAgentDetails = () => {
    return api.post(`/b2b/getuser`);
}

// user booking report (both B2b and B2C)
export const userBookingReport = (data) => {
    return api.post(`/report/booking/user`, data);
}
// send email when successfuly registering as agent
export const userBookingRegisterSuccess = () => {
    return api.post(`/sendemail/b2b/register`);
}

// select phone country code by country
export const getCountryCodeByCountry = (name) => {
    return countryApi.get(`/name/${name}`)
}
