import axios from 'axios';
import { awsCognitoClientId, awsCognitoClientSecret, awsClientEndClientID, awsClientEndClientSecret, appSignInRedirectUri } from '../aws-config'
import { getAccessToken } from '../aws-functions';

/* AWS COGINITO API */
const awsCognitoApi = axios.create({
    baseURL: process.env.REACT_APP_AWS_COGNITO_BASE
});

// api : inject app auth token
awsCognitoApi.interceptors.request.use(config => {
    try {
        const token = getAccessToken();
        config.headers.Authorization = token ? `Bearer ${token}` : '';
        return config;
    } catch (error) {
        // console.log('Could not get acceess token');
    }
    return config;
});

// App authenticate
export const authenticateApp = () => {
    const params = new URLSearchParams();
    params.append('grant_type', 'client_credentials');

    return awsCognitoApi.post('/oauth2/token', params, {
        auth: {
            username: awsCognitoClientId,
            password: awsCognitoClientSecret
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}

// User authenticate
export const authenticateUser = (code) => {
    const params = new URLSearchParams();
    params.append('grant_type', 'authorization_code');
    params.append('client_id', awsClientEndClientID);
    params.append('code', code);
    params.append('redirect_uri', appSignInRedirectUri);

    const authorization = btoa(`${awsClientEndClientID}:${awsClientEndClientSecret}`);
    return axios.post(process.env.REACT_APP_AWS_COGNITO_BASE + '/oauth2/token', params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic ' + authorization,
        }
    });
}

// AWS userinfo-endpoint 
export const userInfo = () => {
    return awsCognitoApi.get('/oauth2/userInfo');
}
