/**
 * AWS configurations
 */
const baseURL = process.env.REACT_APP_API_BASE;
const appRootURL = process.env.REACT_APP_WEBSITE_HOME;
const awsBaseURL = process.env.REACT_APP_AWS_COGNITO_BASE;
const awsClientEndClientID = process.env.REACT_APP_AWS_COGNITO_ENDCLIENT_ID;
const awsClientEndClientSecret = process.env.REACT_APP_AWS_COGNITO_ENDCLIENT_SECRET;
const awsCognitoClientId = process.env.REACT_APP_AWS_COGNITO_CLIENT_ID;
const awsCognitoClientSecret = process.env.REACT_APP_AWS_COGNITO_CLIENT_SECRET;
const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;
const appUrl = process.env.REACT_APP_BASE;
const appSignInRedirectUri = `${appUrl}/aws-cognito/signin`; // redirect URI for signin
const appSignUpRedirectUri = `${appUrl}/aws-cognito/signup`; // redirect URI for signup
const appSignOutRedirectUri = `${appUrl}/aws-cognito/signout`; // redirect URI for signout

// storage keys to use when saving cookie
const awsStorageKeyIdToken = 'awidt';
const awsStorageKeyAccessToken = 'awat';
const awsStorageKeyRefreshToken = 'awrt';
const awsRefreshTokenExpireTime = 29 * 24 * 60 * 60 * 1000;   //AWS refresh token expire date

// URLs to use in the application
const awsSignInUrl = `${awsBaseURL}/login?response_type=code&client_id=${awsClientEndClientID}&redirect_uri=${appSignInRedirectUri}&state=STATE&scope=openid+profile+aws.cognito.signin.user.admin`;
const awsSignInAgentUrl = `${awsBaseURL}/login?response_type=code&client_id=${awsClientEndClientID}&redirect_uri=${appSignInRedirectUri}&state=B2B_AGENT&scope=openid+profile+aws.cognito.signin.user.admin`;
const awsSignUpUrl = `${awsBaseURL}/signup?response_type=token&client_id=${awsClientEndClientID}&redirect_uri=${appSignUpRedirectUri}&state=STATE&scope=openid+profile+aws.cognito.signin.user.admin`;
const awsSignUpAgentUrl = `${awsBaseURL}/signup?response_type=token&client_id=${awsClientEndClientID}&redirect_uri=${appSignUpRedirectUri}&state=B2B_AGENT&scope=openid+profile+aws.cognito.signin.user.admin`;
const awsSignOutUrl = `${awsBaseURL}/logout?client_id=${awsClientEndClientID}&logout_uri=${appSignOutRedirectUri}`;

// User group configurations
const userGroupConfig =
    [
        {
            groups: ['B2C'],
            displayName: 'User',
            loginSuccessRedirectUrl: `${appRootURL}`
        },
        {
            groups: ['B2B'],
            displayName: 'Agent',
            loginSuccessRedirectUrl: `${appRootURL}`
        },
        {
            groups: ['B2B', 'B2C'],
            displayName: 'Agent',
            loginSuccessRedirectUrl: `${appRootURL}`
        },
        {
            groups: ['administrator'],
            displayName: 'Admin User',
            loginSuccessRedirectUrl: '/admin'
        },
        {
            groups: ['administrator', 'B2C'],
            displayName: 'Admin User',
            loginSuccessRedirectUrl: '/admin'
        },
        {
            groups: ['administrator', 'B2B'],
            displayName: 'Admin User',
            loginSuccessRedirectUrl: '/admin'
        },
        {
            groups: ['administrator', 'B2C', 'B2B'],
            displayName: 'Admin User',
            loginSuccessRedirectUrl: '/admin'
        },
    ];

// unauthorized access page
const unauthorizedUrl = '/unauthorized';

export {
    baseURL,
    awsBaseURL,
    appRootURL,
    awsSignInUrl,
    awsSignUpUrl,
    awsSignInAgentUrl,
    awsSignUpAgentUrl,
    awsSignOutUrl,
    awsCognitoClientId,
    awsCognitoClientSecret,
    awsStorageKeyIdToken,
    awsStorageKeyAccessToken,
    awsStorageKeyRefreshToken,
    awsRefreshTokenExpireTime,
    appSignOutRedirectUri,
    appSignInRedirectUri,
    userGroupConfig,
    appUrl,
    awsClientEndClientID,
    awsClientEndClientSecret,
    unauthorizedUrl,
    cookieDomain,
}
