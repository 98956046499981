import React, { useEffect } from 'react';
import UiLoader from './ui-loader/ui-loader';
import { awsSignInAgentUrl } from '../aws-config';

const Signup = (props) => {

    // extract code from the aws response uri
    const urlParams = props.location ? props.location.hash.replace("#", "").split("&")[4].replace("state=", "") : 'STATE';

    useEffect(() => {
        if (urlParams === 'B2B_AGENT') {
            window.location = awsSignInAgentUrl;

        } else {
            props.history.push({
                pathname: '/'
            });
        }

    }, [urlParams, props])

    return (
        <div>
            <UiLoader />
        </div>
    )
}

export default Signup;
