import React, { Fragment } from 'react';
import { Button, Modal, Form, Row, Col, Spinner } from 'react-bootstrap';
import './section-update-model.scss';
import { Formik, Field, ErrorMessage, FieldArray, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { sectionCreate, sectionDelete } from '../../../api/cms.api';

//section initlize
const sectionsInit = {
    sectionName: '',
    travelPackage: [
        {
            packageTitle: "",
            packageThumbnailUrl: "",
            packagePageUrl: "",
            packageText: "",
            packagePrice: ""
        }
    ]
}

//section initlize
const travelPackageInit = {
    packageTitle: "",
    packageThumbnailUrl: "",
    packagePageUrl: "",
    packageText: "",
    packagePrice: ""
}

// validation schema
const schema = Yup.object().shape({
    packageName: Yup.string().required('Package name is required'),
    layoutType: Yup.string().required('Layout type is required'),
    sections: Yup.array().of(
        Yup.object().shape({
            sectionName: Yup.string().required('Section name is required'),
            travelPackage: Yup.array().of(
                Yup.object().shape({
                    packageTitle: Yup.string().required('Package title is required'),
                    packageThumbnailUrl: Yup.string().required('Package thumbnail url is required'),
                    packagePageUrl: Yup.string().required('Package page url is required'),
                    packageText: Yup.string().required('Package text is required'),
                    packagePrice: Yup.string().required('Package price is required'),
                })
            )
        })
    )
});


// travel package
const TravelPackage = ({ packageIndex, packageArrayHelper }) => {
    const { values } = useFormikContext();

    return (
        <>
            <div className="row bgVusColor">
                {values.sections[packageIndex].travelPackage.map((pack, index2) => (
                    <div key={index2}>
                        <div className="col-md-12">
                            <div>Item {index2 + 1}</div>
                            <div className="itemBackColor">
                                <Form.Group as={Row} >
                                    <Form.Label className="text-right" column sm={6} htmlFor={`sections.${packageIndex}.travelPackage.${index2}.packageTitle`} >Title</Form.Label>
                                    <Col sm={6}>
                                        <Field className="form-control" type="text" name={`sections.${packageIndex}.travelPackage.${index2}.packageTitle`} placeholder="Enter title" />
                                        <ErrorMessage name={`sections.${packageIndex}.travelPackage.${index2}.packageTitle`}>
                                            {msg => <div style={{ color: '#dc3545', fontSize: '80%' }}>{msg}</div>}
                                        </ErrorMessage>
                                    </Col>

                                </Form.Group>
                                <Form.Group as={Row} >
                                    <Form.Label className="text-right" column sm={6} htmlFor={`sections.${packageIndex}.travelPackage.${index2}.packageThumbnailUrl`}>Thumbnail Url</Form.Label>
                                    <Col sm={6}>
                                        <Field className="form-control" type="text" name={`sections.${packageIndex}.travelPackage.${index2}.packageThumbnailUrl`} placeholder="Enter thumbnail url" />
                                        <ErrorMessage name={`sections.${packageIndex}.travelPackage.${index2}.packageThumbnailUrl`}>
                                            {msg => <div style={{ color: '#dc3545', fontSize: '80%' }}>{msg}</div>}
                                        </ErrorMessage>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} >
                                    <Form.Label className="text-right" column sm={6} htmlFor={`sections.${packageIndex}.travelPackage.${index2}.packagePageUrl`}>Page Url</Form.Label>
                                    <Col sm={6}>
                                        <Field className="form-control" type="text" name={`sections.${packageIndex}.travelPackage.${index2}.packagePageUrl`} placeholder="Enter page url" />
                                        <ErrorMessage name={`sections.${packageIndex}.travelPackage.${index2}.packagePageUrl`}>
                                            {msg => <div style={{ color: '#dc3545', fontSize: '80%' }}>{msg}</div>}
                                        </ErrorMessage>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} >
                                    <Form.Label className="text-right" column sm={6} htmlFor={`sections.${packageIndex}.travelPackage.${index2}.packageText`}>Text</Form.Label>
                                    <Col sm={6}>
                                        <Field className="form-control" type="text" name={`sections.${packageIndex}.travelPackage.${index2}.packageText`} placeholder="Enter text" />
                                        <ErrorMessage name={`sections.${packageIndex}.travelPackage.${index2}.packageText`}>
                                            {msg => <div style={{ color: '#dc3545', fontSize: '80%' }}>{msg}</div>}
                                        </ErrorMessage>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} >
                                    <Form.Label className="text-right" column sm={6} htmlFor={`sections.${packageIndex}.travelPackage.${index2}.packagePrice`}>Price</Form.Label>
                                    <Col sm={6}>
                                        <Field className="form-control" type="text" name={`sections.${packageIndex}.travelPackage.${index2}.packagePrice`} placeholder="Enter price" />
                                        <ErrorMessage name={`sections.${packageIndex}.travelPackage.${index2}.packagePrice`}>
                                            {msg => <div style={{ color: '#dc3545', fontSize: '80%' }}>{msg}</div>}
                                        </ErrorMessage>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} >
                                    <Col sm={8}>
                                    </Col>
                                    <Col sm={4}>
                                        {
                                            index2 !== 0 ?
                                                <div className="row">
                                                    <Col sm={5}>
                                                        <Button variant="danger" type="button" onClick={() => packageArrayHelper.remove(index2, '')}> <i className="fa fa-minus-circle" aria-hidden="true"></i> </Button>
                                                    </Col>
                                                    <Col sm={5}>
                                                        <Button type="button" onClick={() => packageArrayHelper.push(travelPackageInit)}> <i className="fa fa-plus-circle" aria-hidden="true"></i> </Button>
                                                    </Col>
                                                    <Col sm={2}>

                                                    </Col>
                                                </div> :
                                                <div className="row" >
                                                    <Col sm={5}>
                                                    </Col>
                                                    <Col sm={5}>
                                                        <Button type="button" onClick={() => packageArrayHelper.push(travelPackageInit)}> <i className="fa fa-plus-circle" aria-hidden="true"></i> </Button>
                                                    </Col>
                                                    <Col sm={2}>

                                                    </Col>
                                                </div>
                                        }
                                    </Col>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

const Section = ({ sectionArrayHelpers }) => {
    const { values } = useFormikContext();
    return (
        <>
            {values.sections.map((section, index) => (
                <div key={index}>
                    <Form.Group as={Row} >
                        <Form.Label className="text-right" column sm={2} htmlFor={`sections.${index}.sectionName`}>Section {index + 1}</Form.Label>
                        <Col sm={4}>
                            <Field className="form-control" name={`sections.${index}.sectionName`} type="text" placeholder="Enter section name" />
                            <ErrorMessage name={`sections.${index}.sectionName`}>
                                {msg => <div style={{ color: '#dc3545', fontSize: '80%' }}>{msg}</div>}
                            </ErrorMessage>
                        </Col>
                        <Col sm={6}>
                            {
                                index !== 0 ?
                                    <div className="row">
                                        <Col sm={2}>
                                            <Button variant="danger" type="button" onClick={() => sectionArrayHelpers.remove(index, '')}>Remove</Button>
                                        </Col>
                                        <Col sm={2}>
                                            <Button type="button" onClick={() => sectionArrayHelpers.push(sectionsInit)}>Add</Button>
                                        </Col>
                                        <Col sm={8}></Col>
                                    </div> :
                                    <div className="row" >
                                        <Col sm={12}>
                                            <Button type="button" onClick={() => sectionArrayHelpers.push(sectionsInit)}>Add</Button>
                                        </Col>
                                    </div>
                            }
                        </Col>
                    </Form.Group>
                    <div className="container">
                        <FieldArray name={`sections[${index}].travelPackage`}>
                            {arrayPackageHelpers => (
                                <>
                                    <br />
                                    <TravelPackage
                                        packageIndex={index}
                                        packageArrayHelper={arrayPackageHelpers}
                                    />
                                </>
                            )}
                        </FieldArray>
                    </div>
                </div>
            ))}
        </>
    );
}

const SectionUpdateModal = ({ data, show, onHide, refresh }) => {

    const [showResults, setShowResults] = React.useState(false);

    return (
        <Fragment>
            {
                <Modal size="xl" show={show} onHide={() => onHide()}>
                    <Modal.Header closeButton>
                        <Modal.Title >
                            <span>Section Update</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <Formik
                            initialValues={data}
                            validationSchema={schema}
                            enableReinitialize={true}
                            onSubmit={(values, { setSubmitting }) => {
                                setShowResults(true);
                                setSubmitting(false);
                                setTimeout(() => {

                                    const SectionDelete = {
                                        id: data.id
                                    }
                                    sectionDelete(SectionDelete).then(() => {
                                        const Package = values

                                        sectionCreate(Package).then(() => {
                                            onHide();
                                            refresh();
                                            setSubmitting(false);
                                            setShowResults(false);
                                        }).catch((error) => {
                                            console.log(error);
                                        })
                                    }).catch((error) => {
                                        console.log(error);
                                    });

                                }, 400);
                            }
                            }
                            render={({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group as={Row} >
                                        <Form.Label className="text-right" column sm={2} htmlFor={`packageName`}>Package Name</Form.Label>
                                        <Col sm={4}>
                                            <Field className={'form-control'} name={`packageName`} type="text" placeholder="Enter package name" />
                                            <ErrorMessage name={`packageName`}>
                                                {msg => <div style={{ color: '#dc3545', fontSize: '80%' }}>{msg}</div>}
                                            </ErrorMessage>
                                        </Col>
                                        <Col sm={6}></Col>
                                    </Form.Group>
                                    <Form.Group as={Row} >
                                        <Form.Label className="text-right" column sm={2} htmlFor={`layoutType`}>Layout Type</Form.Label>
                                        <Col sm={4}>
                                            <Field as="select" className={'form-control'} name={`layoutType`}>
                                                <option value="">Select a layout</option>
                                                <option value="GRID">Grid Layout</option>
                                                <option value="LIST">List Layout</option>
                                                <option value="SLIDE">Slide Layout</option>
                                            </Field>
                                            <ErrorMessage name={`layoutType`}>
                                                {msg => <div style={{ color: '#dc3545', fontSize: '80%' }}>{msg}</div>}
                                            </ErrorMessage>
                                        </Col>
                                        <Col sm={6}></Col>
                                    </Form.Group>
                                    <FieldArray
                                        name="sections"
                                        render={arrayHelpers => {
                                            return (
                                                <>
                                                    <Section sectionArrayHelpers={arrayHelpers} />
                                                </>
                                            );
                                        }}
                                    />
                                    {showResults ?
                                        <Form.Group as={Row} className="text-right">
                                            <Col sm={10}>
                                            </Col>
                                            <Col sm={2}>
                                                <Button variant="primary" disabled>
                                                    <Spinner
                                                        as="span"
                                                        variant="warning"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        animation="grow" />
                                                    Loading...
                                                </Button>
                                            </Col>
                                        </Form.Group>
                                        :
                                        <Form.Group as={Row} className="text-right">
                                            <Col sm={10}>
                                            </Col>
                                            <Col sm={2}>
                                                <Button type="submit" disabled={isSubmitting}>Submit</Button>
                                            </Col>
                                        </Form.Group>
                                    }
                                </Form>
                            )}
                        />
                    </Modal.Body>
                </Modal>
            }
        </Fragment>
    );
}

export default SectionUpdateModal;