import React from 'react';
import { NavLink } from 'react-router-dom';
import logoImg from '../../../assets/images/logo.png';
import googlePlay from '../../../assets/images/google-play.png';
import appleStore from '../../../assets/images/apple-store.png';
import { awsSignInUrl, awsSignOutUrl, awsSignUpUrl } from '../../../aws-cognito/aws-config'
import { getCurrentUser } from '../../../aws-cognito/aws-functions';
import './booking-engine-header.scss';


const BookingEngineHeader = () => {
    const currentUser = getCurrentUser();

    const getYear = () => {
        var estDate = "2008-08-22";
        var now = new Date();
        var past = new Date(estDate);
        var nowYear = now.getFullYear();
        var pastYear = past.getFullYear();
        var age = nowYear - pastYear;
        return age;
    }

    return (
        <header className="booking-engine-header fixed-top">
            <div className="row m-0 background-color" >
                <div className="container position-relative">
                    <div className="header-content d-sm-block d-lg-flex justify-content-between align-items-center">

                        <a href="https://buddhatravel.com.au/" className="navbar-brand">
                            <img alt="logo" src={logoImg} className="navbar-logo" />
                        </a>

                        <div className="d-sm-block d-lg-flex text-center align-items-center">
                            <div className="contact d-none d-lg-block">
                                <a href="tel:1300116746" className="telephone"><i className="fa fa-phone" aria-hidden="true"></i> 1300 116 746
                                </a>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="align-items-center ml-0 ml-lg-4 app-link d-flex align-content-center">
                                    <a href="https://apps.apple.com/lk/app/buddha-travel-and-tours/id1599487916" target="_blank" rel="noopener noreferrer">
                                        <img alt="logo" className="d-block d-lg-inline-block" width="110" src={appleStore} />
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.buddhatravel12.codzbee" target="_blank" rel="noopener noreferrer">
                                        <img alt="logo" className="d-inline-block ml-2 second-item" width="110" src={googlePlay} />
                                    </a>
                                </div>
                                <div className="excellence ml-0 ml-lg-2 mt-0 mt-lg-2">
                                    <h6 className="text-uppercase mb-0" id="yearsExcellence"><span className="font-green">{getYear()}</span><sup>+</sup> Years of</h6>
                                    <h5 className="text-uppercase" id="font-16">Excellence</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <nav className="navbar navbar-expand-lg navbar-light p-0 primary_color">
                <div className="container">
                    <button className="navbar-toggler ml-auto hidden-sm-up mr-0 mr-sm-2 float-xs-right" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button >
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <button className="close-button d-lg-none d-xl-none m-3" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" >
                            <i className="fa fa-times fa-2x" aria-hidden="true"></i>
                        </button>
                        <ul className="navbar-nav mr-auto">
                            {/* <NavLink exact to="/" className="nav-link text-light">Home</NavLink> */}
                            <a href="https://buddhatravel.com.au/" className="nav-link text-light">Home</a>

                            <li>
                                <a href="https://buddhatravel.com.au/flight" className="nav-link text-light">Flights</a>
                            </li>

                            <li className="dropdown">
                                <a className="nav-link dropdown-toggle" href="https://buddhatravel.com.au/holiday-packages" data-target="#navbarDropdownMenuHolidayLink" id="navbarDropdownMenuHolidayLink" aria-controls="navbarDropdownMenuHolidayLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Holiday
                                </a>
                                <div className="dropdown-menu primary_color" aria-labelledby="navbarDropdownMenuHolidayLink" >
                                    <a href="https://buddhatravel.com.au/holiday-package/nepal" className="dropdown-item nav-link text-light">Nepal</a>
                                    <a href="https://buddhatravel.com.au/holiday-package/asia" className="dropdown-item nav-link text-light">Asia</a>
                                    <a href="https://buddhatravel.com.au/holiday-package/japan" className="dropdown-item nav-link text-light">Japan</a>
                                    <a href="https://buddhatravel.com.au/holiday-package/fiji" className="dropdown-item nav-link text-light">Fiji</a>
                                    <a href="https://buddhatravel.com.au/holiday-package/vietnam" className="dropdown-item nav-link text-light">Vietnam</a>
                                    {/* <a href="https://www.buddhatravel.com.au/IndiaTours/" className="dropdown-item nav-link text-light">India</a>
                                    <a href="https://buddhatravel.com.au/holiday-package/australia" className="dropdown-item nav-link text-light">Australia</a>
                                    <a href="https://buddhatravel.com.au/holiday-package/srilanka" className="dropdown-item nav-link text-light">Srilanka</a>
                                    <a href="https://buddhatravel.com.au/holiday-package/thailand" className="dropdown-item nav-link text-light">Thailand</a>
                                    <a href="https://buddhatravel.com.au/holiday-package/singapore" className="dropdown-item nav-link text-light">Singapore</a>
                                    <a href="https://buddhatravel.com.au/holiday-package/new-zealand" className="dropdown-item nav-link text-light">New Zealand</a>
                                    <a href="https://buddhatravel.com.au/holiday-package/malaysia" className="dropdown-item nav-link text-light">Malaysia</a>
                                    <a href="https://buddhatravel.com.au/holiday-package/hong-kong" className="dropdown-item nav-link text-light">Hong-Kong</a>
                                    <a href="https://buddhatravel.com.au/holiday-package/fiji" className="dropdown-item nav-link text-light">Fiji</a>
                                    <a href="https://buddhatravel.com.au/holiday-package/bali" className="dropdown-item nav-link text-light">Bali</a>
                                    <a href="https://buddhatravel.com.au/holiday-package/vietnam" className="dropdown-item nav-link text-light">Vietnam</a> */}
                                </div>
                            </li>

                            <li className="dropdown">
                                <a className="nav-link dropdown-toggle" href="true" data-target="#navbarDropdownMenuExtraLinks" id="navbarDropdownMenuExtraLinks" aria-controls="navbarDropdownMenuExtraLinks" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Extras
                                </a>
                                <div className="dropdown-menu primary_color" aria-labelledby="navbarDropdownMenuExtraLinks" >
                                    <a href="https://buddhatravel.com.au/car-hire" className="dropdown-item nav-link text-light">Car Hire</a>
                                    <a href="https://buddhatravel.com.au/insurance" className="dropdown-item nav-link text-light">Insurance</a>
                                    <a href="https://buddhatravel.com.au/tour" className="dropdown-item nav-link text-light">Tours</a>
                                    <a href="https://buddhatravel.com.au/blog" className="dropdown-item nav-link text-light">Blogs</a>
                                    <a href="https://buddhatravel.com.au/useful-pages/covid-19-update" className="dropdown-item nav-link text-light">COVID-19 Update</a>
                                    {/* <a href="https://buddhatravel.com.au/useful-pages/purchase-now-and-pay-later-with-up-to-12-months-interest-free-option" className="dropdown-item nav-link text-light">Travel Loan</a> */}
                                </div>
                            </li>

                            <li className="dropdown">
                                <a className="nav-link dropdown-toggle" href="true" data-target="#navbarDropdownMenuAboutUsLinks" id="navbarDropdownMenuAboutUsLinks" aria-controls="navbarDropdownMenuAboutUsLinks" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    About Us
                                </a>
                                <div className="dropdown-menu primary_color" aria-labelledby="navbarDropdownMenuAboutUsLinks" >
                                    <a href="https://buddhatravel.com.au/award" className="dropdown-item nav-link text-light">Awards and Recognition</a>
                                    <a href="https://buddhatravel.com.au/gallery" className="dropdown-item nav-link text-light">Gallery</a>
                                    <a href="https://buddhatravel.com.au/useful-pages/about-us" className="dropdown-item nav-link text-light">About Us</a>
                                    <a href="https://buddhatravel.com.au/useful-pages/privacy-policy" className="dropdown-item nav-link text-light">Privacy policy</a>
                                    <a href="https://buddhatravel.com.au/useful-pages/terms-and-conditions" className="dropdown-item nav-link text-light">Terms and Conditions</a>
                                    <a href="https://buddhatravel.com.au/useful-pages/our-customers" className="dropdown-item nav-link text-light">Our Customers</a>
                                    <a href="https://buddhatravel.com.au/useful-pages/community-support-partnership" className="dropdown-item nav-link text-light">Community Support &amp; Partnership</a>
                                    <a href="https://buddhatravel.com.au/useful-pages/events" className="dropdown-item nav-link text-light">Events</a>
                                </div>
                            </li>

                            <li >
                                <a className="nav-link" href="https://buddhatravel.com.au/online-check-in" role="button">
                                    Online Check In
                                </a>
                            </li>
                            <li >
                                <a className="nav-link" href="https://buddhatravel.com.au/refund">
                                    Refund
                                </a>
                            </li>
                            <li >
                                <NavLink exact to="/agent" className="nav-link text-light">Become an Agent</NavLink>
                            </li>

                        </ul>

                        <div className="d-flex mobile-navigation">
                            {/* <a href="true" className="btn nav-btn" data-toggle="modal" data-target="#quoteModal" >Get a quote</a> */}
                            <div className="d-lg-none d-xl-none p-0">
                                <div className="d-none">
                                    <br />
                                </div>
                            </div>
                            <a className="btn nav-btn" href="https://buddhatravel.com.au/contact-us">Contact Us</a>
                            <div className="dropdown">
                                <button className="btn nav-btn dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {
                                        (currentUser) ?
                                            <>{currentUser.name}</>
                                            :
                                            <>Account</>
                                    }
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {
                                        (currentUser) ?
                                            <div className="pl-2">
                                                {
                                                    currentUser.groupConfig.groups.includes('B2B') ?
                                                        <div>
                                                            <NavLink exact to="/agent/agent-profile" >Agent Profile</NavLink>
                                                        </div>
                                                        :
                                                        null
                                                }

                                                {
                                                    currentUser.groupConfig.groups.includes('administrator') ?
                                                        <div className="mt-1">
                                                            <NavLink exact to="/admin">Backend Dashboard</NavLink>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                {
                                                    currentUser.groupConfig.groups.includes('B2B') || currentUser.groupConfig.groups.includes('B2C')
                                                        ?
                                                        <div className="mt-1">
                                                            <NavLink exact to="/user">Booking Report</NavLink>
                                                        </div>
                                                        :
                                                        null
                                                }


                                                <div className="mt-1">
                                                    <a href={awsSignOutUrl} >Sign Out</a>
                                                </div>

                                            </div>
                                            :
                                            <div>
                                                <a className="dropdown-item" href={awsSignUpUrl}>Sign Up</a>
                                                <a className="dropdown-item" href={awsSignInUrl}>Login</a>
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className="dropdown ml-2">
                                <button className="btn nav-btn dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Country
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <div>
                                        <a className="dropdown-item" href="https://buddhatravel.co.nz/">New Zealand</a>
                                        <a className="dropdown-item" href="https://buddhatravelandtours.com/">Nepal</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

        </header>
    );
}

export default BookingEngineHeader;
