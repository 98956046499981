import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from './views/dashboard/dashboard';
import FareManage from './views/fare-manage';
import Agents from './views/agents';
import BookingReport from './views/booking-report';
import MenuItems from './views/menu-items';
import Sections from './views/sections';
import NotFound from '../common/views/not-found';

const Admin = ({ match }) => {

    return (
        <Switch>
            <Route exact path={`${match.url}/faremanage`} component={FareManage} />
            <Route exact path={`${match.url}/bookingreport`} component={BookingReport} />
            <Route exact path={`${match.url}/agents`} component={Agents} />
            <Route exact path={`${match.url}/menu-items`} component={MenuItems} />
            <Route exact path={`${match.url}/sections`} component={Sections} />
            <Route exact path={match.path} component={Dashboard} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
};

export default Admin;

