import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {

    const smoothScroll = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
          window.requestAnimationFrame(smoothScroll);
          window.scrollTo(0, c - c / 8);
        }
    };

    useEffect(() => {
        // const unlisten = history.listen(() => {
        //     // window.scrollTo(0, 0);
        //     smoothScroll();
        // });
        // return () => {
        //     unlisten();
        // }
        smoothScroll();
    });

  return (null);
}

export default withRouter(ScrollToTop);