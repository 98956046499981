import React from 'react';
import {Route} from 'react-router-dom';
import { SESSIONSTORE } from '../storage';
import NotFound from '../views/not-found';
import { handleException } from '../util';

const AppRoute = ({
    component: Component,
    requiredSessionStore,
    requiredLocalStore,
    ...rest}) => {
    try {
        // check storage
        let isValid = true;

        if (requiredSessionStore) {
            requiredSessionStore.forEach(storeKey => {
                if (!SESSIONSTORE.get(storeKey)) {
                    isValid = false;
                }
            });
        }

        if (requiredLocalStore) {
            requiredLocalStore.forEach(storeKey => {
                if (!SESSIONSTORE.get(storeKey)) {
                    isValid = false;
                }
            });
        }

        // load component if valid
        return(
            <Route {...rest} render={(props) => (
                isValid ? <Component {...props} />
                : <NotFound/>
            )} />
        );
        
    } catch (error) {
        handleException(error, 'Could not resolve storage for route');

        return(
            <Route {...rest} render={<NotFound/>} />
        );
    }

};

export default AppRoute;