import React from "react";
import TripItem from "./trip-item";
import moment from "moment";

const TripItemList = ({
    tripType,
    travelItineraries,
    showValidationErrors,
    onAddTripItem,
    onRemoveTripItem,
    onBoardPointChangeTripItem,
    onOffPointChangeTripItem,
    onDepartureDateChangeItem,
    onArrivalDateChangeItem
}) => {
    const uiTripItems = travelItineraries.map((tripItem, index) => {
        return (
            <TripItem
                key={index}
                index={index}
                boardPoint={tripItem.boardPoint}
                offPoint={tripItem.offPoint}
                departureDate={tripItem.departureDate}
                arrivalDate={tripItem.arrivalDate}
                showValidationErrors={showValidationErrors}
                enableArrivalDateSelect={
                    tripType === "return" &&
                    index === 0 &&
                    travelItineraries.length === 1
                }
                enableRemoveOption={
                    tripType === "multicity" &&
                    index + 1 === travelItineraries.length &&
                    index !== 1
                }
                enableAddOption={tripType === "multicity" && index >= 1}
                minDepartureDate={
                    index > 0
                        ? travelItineraries[index - 1].departureDate
                        : moment().format("YYYY-MM-DD")
                }
                onBoardPointChange={onBoardPointChangeTripItem}
                onOffPointChange={onOffPointChangeTripItem}
                onDepartureDateChange={onDepartureDateChangeItem}
                onArrivalDateChange={onArrivalDateChangeItem}
                onAddItem={onAddTripItem}
                onRemoveItem={onRemoveTripItem}
            />
        );
    });

    return (
        <>
            <div className="d-none">
                <div className="form-row">
                    <div className="form-group col-lg-3">
                        Flying from
                    </div>
                    <div className="form-group col-lg-3">
                        Flying to
                    </div>
                    <div className="form-group col-lg-3">
                        Departure
                    </div>
                    {tripType !== "oneway" ? (
                        <div className="form-group col-lg-3">
                            Return
                        </div>
                    ) : null}
                </div>
            </div>
            {uiTripItems}
        </>
    );
};

export default TripItemList;
