import React from "react";
import BookingEngineHeader from "../../../components/booking-engine-header/booking-engine-header";
import BookingEngineFooter from "../../../components/booking-engine-footer/booking-engine-footer";
import BookingEngineView from "../../../components/booking-engine-view";
import FlightSummaryBox from "../../components/flight-summary-box";
// import StripeComponent from '../../../payment-gateway/stripe/stripe-component';
// import PoliComponent from '../../../payment-gateway/poli/poli-component';

// import LaddaButton, { SLIDE_UP } from "react-ladda";
import { SESSIONSTORE } from "../../../../common/storage";
import { formatCurrency, handleException } from "../../../../common/util";
import { storeTst, pnrCommit, sendConfirmEmail } from "../../../../api/flight-booking.api";
import BookingTimer from "../../components/booking-timer/booking-timer";
import NotifyModal from '../../../../common/components/notify-modal';
import { removeBookingSession } from '../../../../common/util';
import './booking-payment.scss';
import FareRulesModal from "../../components/fare-rules/fare-rules-modal";
import AnzComponent from "../../../payment-gateway/anz-worldline/anz-component";

class BookingPayment extends React.Component {
    pricingSolutionData = SESSIONSTORE.get("ps");
    sSession = SESSIONSTORE.get("ss");
    confirmId = SESSIONSTORE.get("cid");

    constructor(props) {
        super(props);
        this.state = {
            paymentLoading: false,
            paymentFailed: false,
            notifyError: null,
            preprocessSuccess: false,
            showFareRules: false
        };
    }

    componentDidMount() {
        this.paymentPreProcess().then(() => {
            this.setState({
                preprocessSuccess: true
            })
        })
    }

    // payment pre process commits starts 
    paymentPreProcess = () => {
        return new Promise((resolve, reject) => {
            this.setState({ paymentLoading: true });

            if (SESSIONSTORE.get("pnrc")) {
                resolve();
            } else {
                // tst request
                storeTst(
                    this.pricingSolutionData.fareReferences,
                    this.sSession,
                    this.confirmId
                )
                    .then(res => {
                        // update sSession
                        SESSIONSTORE.save("ss", res.data.sSession);

                        // update confirm id
                        SESSIONSTORE.save("cid", res.data.confirmId);

                        // pnr commit final
                        pnrCommit(SESSIONSTORE.get("ss"), SESSIONSTORE.get("cid"))
                            .then(res => {

                                console.log(res);
                                SESSIONSTORE.save("pnrc", true);

                                this.setState({
                                    paymentLoading: false
                                }, () => {
                                    resolve(res);
                                });
                            })
                            .catch(err => {
                                handleException(err, "Could not commit PNR");
                                removeBookingSession();
                                this.setState({
                                    notifyError: {
                                        body: "We are unable to complete the booking process. Please try searching again."
                                    }
                                }, () => {
                                    reject();
                                });
                            });

                    })
                    .catch(err => {
                        handleException(err, "Could not store TST");
                        removeBookingSession();
                        this.setState({
                            notifyError: {
                                body: "We are unable to complete the booking process. Please try searching again."
                            }
                        }, () => {
                            reject();
                        });
                    });
            }

        });

    };

    // payment post process commits starts 
    paymentPostProcess = () => {
        return new Promise((resolve, reject) => {

            // send email
            // settimeout to varify the payment (check successed or not)
            setTimeout(() => {
                sendConfirmEmail(this.confirmId).then(() => {
                    resolve();
                }).catch(() => {
                    this.setState({
                        notifyError: {
                            body: "We are unable to send you the invoice. Please contact system administrator."
                        }
                    });
                    reject();
                });
            }, 5000);

        });
    };

    /**
     * For testing purpose only, simulate booking process without payment gateway
     */
    // simulatePaymentProcess() {
    //     this.paymentPreProcess()
    //         .then(() => {
    //             sendConfirmEmail(this.confirmId).then(() => {
    //                 this.props.history.push({
    //                     pathname: '/booking-engine/flight/booking-success'
    //                 });
    //             }).catch(() => {
    //                 this.setState({
    //                     notifyError: {
    //                         body: "We are unable to send you the invoice. Please contact system administrator."
    //                     }
    //                 });
    //             });
    //         })
    // }

    render() {
        return (
            <>
                <BookingEngineHeader />
                <BookingEngineView>
                    {
                        this.state.notifyError ? <NotifyModal
                            type="danger"
                            title="Oops! Something went wrong"
                            body={this.state.notifyError.body}
                            showSearchButton={true}
                            disableBackdrop={true}
                            disableClose={true}
                        /> : null
                    }
                    <div className="container mt-3">
                        <div className="row">
                            <div className="col-12 col-lg-3">
                                <FlightSummaryBox />
                                <BookingTimer />
                            </div>

                            <div className="col-12 col-lg-9">
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="container mb-3">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h3 className="card-title">
                                                        <i className="fa fa-dollar text-primary font-weight-bold mr-3"></i>
                                                        <span className="text-primary font-weight-bold">
                                                            Payment
                                                        </span>
                                                    </h3>
                                                    <p className="text-muted">
                                                        Your payment options and pricing breakdown for this booking
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container">
                                            <div className="row align-items-center">
                                                <div className="col-12 col-lg-5 text-center text-primary">
                                                    <h4 className="font-weight-bold">Final Amount</h4>
                                                    <h2 className="font-weight-bold">
                                                        {this.pricingSolutionData.currency}{" "}
                                                        {formatCurrency(this.pricingSolutionData.finalMarkedupOrDiscountedFare)}
                                                    </h2>
                                                </div>
                                                <div className="col-12 col-lg-7 card">
                                                    <div className="h4 my-2 text-muted">
                                                        Breakdown
                                                    </div>
                                                    <div className="row border-bottom py-2 font-weight-bold">
                                                        <div className="col-8">
                                                            Total Base Fare
                                                        </div>
                                                        <div className="col-4 text-right">
                                                            {formatCurrency(this.pricingSolutionData.markedupOrDiscountedFareExcludeTaxAndAdminFee)}
                                                        </div>
                                                        <div className="col-8">
                                                            Total Tax
                                                        </div>
                                                        <div className="col-4 text-right">
                                                            {formatCurrency(this.pricingSolutionData.totalTax)}
                                                        </div>
                                                        <div className="col-8">
                                                            Booking fee
                                                        </div>
                                                        <div className="col-4 text-right">
                                                            {formatCurrency(this.pricingSolutionData.adminFee)}
                                                        </div>
                                                    </div>
                                                    <div className="row py-2 font-weight-bold">
                                                        <div className="col-8">
                                                            Sub Total
                                                        </div>
                                                        <div className="col-4 text-right">
                                                            {formatCurrency(this.pricingSolutionData.finalMarkedupOrDiscountedFare)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    Please read the
                                    <a href="/" className="text-primary" onClick={e => {
                                        e.preventDefault();
                                        this.setState({ showFareRules: true });
                                    }}> Fare rules</a>.
                                </div>

                                <div className="card mb-3">
                                    <div className="card-body">
                                        {/* <div className="container">
                                            <div className="row">
                                                <div className="col-lg-2 col-sm-12">
                                                    <h4 className="text-uppercase"> Pay With</h4>
                                                </div>
                                                <div className="col-lg-4 col-sm-12">
                                                    {
                                                        this.state.preprocessSuccess ?
                                                            <PoliComponent confirmId={this.confirmId} />
                                                            :
                                                            <div>Poli payment is loading</div>
                                                    }
                                                </div>

                                                <div className="vertical-divider">or</div>

                                                <div className="col-lg-6 col-sm-12 ">
                                                    {
                                                        this.state.preprocessSuccess ?
                                                            <StripeComponent confirmId={this.confirmId} paymentPostProcess={this.paymentPostProcess} />
                                                            :
                                                            <div>Stripe is loading</div>
                                                    }
                                                </div>

                                            </div>
                                        </div> */}
                                        {
                                            this.state.preprocessSuccess ?
                                                <AnzComponent confirmId={this.confirmId} paymentPostProcess={this.paymentPostProcess} />
                                                :
                                                <div>Loading</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <FareRulesModal
                        show={this.state.showFareRules}
                        handleClose={() => this.setState({ showFareRules: false })} />
                </BookingEngineView>
                <BookingEngineFooter footerStyle="compact" />
            </>
        );
    }
}

export default BookingPayment;
